.resource-icon-component {
    --featured-content__content-padding: 48px 0 30px;
    --subhead: 24px;
    
    background-color: $colors__gray--f7;
    padding: var(--featured-content__content-padding);
    text-align: center;

    &__heading {
        @include fonts__sans-serif-bold;
        margin-bottom: 24px;
        font-size: px-to-em(22px);
        text-transform: uppercase;
    }
    
    &__list {
        list-style-type: none;

    }

    &__item {
        color: $colors__blue;
        text-align: center;

        & + & {
            margin-top: 34px;
        }
    }

    &__image {
        width: 116px;
        height: 116px;
        display: block;
        margin: 0 auto 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        &-container {
            border-radius: 100%;
            overflow: hidden;
            border: 4px solid transparent;
            margin: 0 auto 24px auto;
            height: 166px;
            width: 166px;
            position: relative;
            background-color: $colors__white;

            @include transition(border);

            &:hover {
                border: 4px solid $colors__teal;
            }
        }        
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: px-to-em(20px);
    }
    @include media('>=phone') {
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: stretch;
            align-items: stretch;

            &--item {
                width: 45%;
                margin-bottom: 1.5em;
                display: flex;
                flex-direction: column;
                align-items: stretch;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
        }
    }

    @include media('>=tablet-portrait') {
        --featured-content__content-padding: 64px 0 40px;

        &__heading {
            margin-bottom: 32px;
        }

        &__list {
            &--item {
                width: 33.3333%;
            }
        }        
    }
    @include media('>=tablet-landscape') {
        &__list {
            &--item {
                width: 20%;
            }
        }
    }
}