.site-search {
    .site-search-results {
        li {
            margin-bottom: 1.5em;
    
            a.ng-binding {
                @include fonts__sans-serif-bold;
                font-size: 1.25em;
            }
        }
    }
}