.hubspot-survey {
    background-color: $colors__gray--f7;
    position: relative;
    overflow: hidden;

    &__outer-container {
        display: flex;
        width: 200%;
    }

    &__jump-link {
        position: absolute;
        top: -113px;

        @include media('<tablet-portrait') {
            top: -72px;
        }
    }

    .featured-content__content {
        max-width: 1200px;
    }

    .section-body {
        margin-top: 30px;
        column-count: 2;
        gap: 100px;

        ul {
            margin-left: 25px;
            font-size: 22px;
        }

        p {
            font-size: 22px;
            margin-top: 0px;
        }

        @include media('<tablet-portrait') {
            font-size: 18px;
        }
    }  

    .section-body-page1 {
        margin-top: 30px;
        column-count: 1;
        font-size: 22px;
    }

    .section-checkboxes {
        text-align: left;
        padding: 32px 10px 0;

        &--single-column {
            @include media('>=tablet-portrait') {
                max-width: rem(400px);
                margin-left: auto;
                margin-right: auto;
            }
        }

        &--multiple-columns {
            .section-checkboxes__options {
                @include media('>=tablet-portrait') {
                    columns: 2;
                    margin-top: 12px;
                }

                @include media('>=tablet-landscape') {
                    columns: 3;
                }
            }
        }

        &__label {
            font-size: 14px;
            line-height: 1.8;
        }

        &__options {
            text-align: left;
            margin-top: 8px;

            .checkbox {
                --hubspot-survey__checkbox-size: 35px;
                display: inline-block;
                width: 100%;
                padding: 10px 0;
                font-size: rem(18px);

                @include media('>=tablet-portrait') {
                    font-size: rem(22px);
                }

                @include media('<tablet-portrait') {
                    font-size: 18px;
                }

                label {
                    display: flex;
                    align-items: center;
                    color: $colors__blue-alt;
                    @include fonts__sans-serif-regular;
                }

                input[type="checkbox"] {
                    width: var(--hubspot-survey__checkbox-size);
                    height: var(--hubspot-survey__checkbox-size);
                    background: #F6F8FA;
                    border: 1px solid #2D3C59;
                    border-radius: 0;
                }

                .hubspot-survey__label-text {
                    padding-left: 12px;
                    width: calc(100% - var(--hubspot-survey__checkbox-size));
                }
            }
        }
    }

    &__page-1-container,
    &__page-2-container {
        width: 50%;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.fade-in {
            opacity: 1;
            pointer-events: all;
            z-index: initial;
        }

        &.fade-out {
            opacity: 0;
            pointer-events: none;
            z-index: 2;
        }
    }

    &__page-2-container {
        opacity: 0;
        transform: translateX(-100%);
    }

    #page2 {
        .featured-content__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .section-body {
            text-align: left;
            margin-top: 15px;

            p {
                font-size: 18px;
                @include fonts__sans-serif-light();

                @include media('>=tablet-portrait') {
                    font-size: 20px;
                }

                @include media('>=tablet-landscape') {
                    font-size: 22px;
                }
            }

            @include media('<=tablet-portrait') {
                columns: 1;
            }

            @include media('>=tablet-portrait') {
                columns: 2;
                gap: 50px;
                margin-top: 30px;
            }
                     

            @include media('>=tablet-landscape') {
                gap: 75px;
                margin-top: 35px;
            }

            @include media('>=desktop') {
                gap: 100px;
                margin-top: 40px;
            }
        }

        .hubspot-survey__results-cta-section {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-top: 1rem;
        }

        .hubspot-survey__results-icon {
            width: 42px;
            height: 42px;

            @include media('>=tablet-portrait') {
                width: 55px;
                height: 55px;
            }
        }

        .hubspot-survey__results-cta {
            font-size: 18px;

            @include media('>=tablet-portrait') {
                font-size: 20px;
            }
        }
    }
}
