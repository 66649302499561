iframe {
    border: none;
    width: 100%;
    background: transparent !important;
    background-color: transparent !important;
}

iframe.subs-frame {
    height: 2550px;
}

iframe.event-registration {
    overflow-x: hidden;
    height: 600px;
    box-shadow: 3px 3px 3px rgba(68,68,68,0.6);
    margin-bottom: 1em;
}
