.callout {
    display: flex;
    flex-direction: row-reverse;
    color: $color-white;

    &__content {
        width: 50%;
        background: $color-green-olive;
        padding: 120px 70px 70px;
    }

    &__content-inner {
        max-width: px-to-em(564px);
        float: right;
    }

    &__eyebrow {
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__title {
        font-size: 2em;
        line-height: 38px;
        @include font-primary-medium;
        margin-bottom: 30px;
    }

    &__subtitle {
        font-size: 1.5em;
        line-height: 34px;
        margin-bottom: 30px;
    }

    &__button {
        display: inline-block;
        color: $color-white;
        border: 1px solid $color-white;
        background: transparent;
        font-size: 1.25em;
        @include font-primary-medium;
        padding: 10px 20px;
        margin-right: 20px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: $color-green-olive;
            background: $color-white;
            text-decoration: none;
        }
    }

    &__image {
        width: 50%;
        background-size: cover;
        background-position: center;
    }

    @include bp-max($bp-desktop) {
        &__content {
            padding: 60px 24px;
        }

        &__content-inner {
            max-width: none;
            float: none;
        }

        &__title {
            font-size: 1.75em;
        }

        &__subtitle {
            font-size: 1.125em;
        }
    }

    @include bp-max($bp-ipad) {
        flex-direction: column;

        &__content,
        &__image {
            width: 100%;
        }

        &__image {
            height: 280px;
        }

        &__button {
            display: block;
            text-align: center;
        }
    }
}
