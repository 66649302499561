.content-aside {
    ul li {
        line-height: 1.15;
        margin-bottom: .50em;

        time {
            margin-top: 0px !important;
        }
    }

    img {
        /*override inline height attribute on images to prevent skewing*/
        height: auto !important;
        max-width: 100%;
    }

    .twitter-feed, .events, .resources {
        margin-left: px-to-em(11.2px);
    }

    .aside-optional-content {
        .aside-container {
            background-color: $color-gray-white;
            padding: 1em .50em;
            color: $color-brown-spice;
            margin-bottom: 1em;
        }

        .twitter h3:before {
            @include font-fontawesome($fa-var-twitter);
            font-size: .650em;
            margin-right: .5em;
        }

        p {
            font-size: 1em !important;
        }

        ul li {
            font-size: .85em !important;
        }
    }
}

@include bp-max(990px) {
    .highlight {
        display: none;
    }
}

@media print {
    .content-aside {
        display: none !important;
        width: 0px !important;
    }
}
