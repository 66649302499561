.insights-section {
    --insights-section__padding: 32px 0 64px;
    --insights-section__header-font-size: 28px;
    --insights-section__border-bottom-height: 2px;

    background-color: $colors__gray--f7;
    padding: var(--insights-section__padding);

    &__header {
        @include fonts__sans-serif-medium;
        font-size: 32px;
        line-height: 1.2;
        color: $colors__blue;
        padding-bottom: 8px;
        border-bottom: var(--insights-section__border-bottom-height) solid $colors__gray--d8;
        margin-bottom: 24px;
    }

    @include media('>tablet-portrait') {
        --insights-section__padding: 64px 0;
        --insights-section__header-font-size: 32px;
        --insights-section__border-bottom-height: 3px;
    }

    @include media('>tablet-landscape') {
        --insights-section__padding: 72px 0;
        --insights-section__header-font-size: 36px;
        --insights-section__border-bottom-height: 4px;
    }
}
