.detail-page-accordions {
    background-color: $colors__gray--f7;
    padding: 40px 0;

    @include media('>=tablet-portrait') {
        padding: 56px 0;
    }

    &__accordion-wrapper {
        margin-bottom: 16px;
        width: 100%;
    }

    &__accordion {
    }

    &__content-wrapper {

    }

    &__content {
        padding: 20px 20px 0px 20px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__col {
        &--left, &--right {
            flex: 1 1 100%;
            @include media('>=tablet-portrait') {
                flex: 0 1 calc(50% - 15px);
            }
        }
    }

    &__button {
        border-radius: 4px;
        overflow: hidden;
        border-left: 6px solid $colors__blue;
        background-color: $colors__white;
        color: $colors__blue;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
        position: relative;

        @include transition(background-color, color);

        &.active {
            background-color: $colors__blue;

            span {
                color: $colors__white;
            }

            svg {
                color: $colors__white;
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &:hover,
        &:focus {
            background-color: $colors__blue;
            color: $colors__white;

            span {
                color: $colors__white;
            }

            svg {
                color: $colors__white;
            }
        }
    }

    &__button-link {
        padding: 16px 40px 16px 22px;
        display: inline-block;
        width: 100%;
        position: relative;

        &:focus {
            background-color: $colors__blue;
            span {
                color: $colors__white;
            }

            & + svg {
                color: $colors__white;
            }
        }

        @include media('>=tablet-portrait') {
            padding: 24px 54px 24px 32px;
        }
    }

    &__button-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        height: 13px;
        width: 24px;
        color: $colors__blue;

        @include transition(transform, color);


        @include media('>=tablet-portrait') {
            height: 16px;
            width: 30px;
            right: 24px;
        }
    }

    &__button-text {
        @include fonts__sans-serif-bold;
        font-size: 22px;
        line-height: 1.2;
        color: $colors__blue;
        display: inline-block;

        @include media('>=tablet-portrait') {
            font-size: 24px;
        }
    }

    &__list {
        &#{&} {
            list-style-type: none;
            margin: 0;
        }

    }

    &__list-item {
        margin-bottom: 16px;
        &:hover, &:focus {
            a {
                text-decoration: underline;
            }
        }
    }

    &__list-item-link {
        font-size: 20px;
        line-height: 1.9;
        text-decoration: none;
        color: $colors__blue;
    }

    + .insights-section {
        padding-top: 0;
    }
}
