.panel {
    @include container;

    .left {
        @include span(6);
    }

    .right {
        @include span(6 last);
    }
}

@include bp-max($bp-mobile) {
    .left, .right {
        display: block;
        width: 100%;
    }
}
