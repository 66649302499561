@import "../../../../node_modules/swiper/swiper";
@import "../../../../node_modules/swiper/components/a11y/a11y";

.swiper-carousel {
    --swiper-carousel__button-size: 38px;
    --swiper-carousel__icon-width: 12px;
    --swiper-carousel__icon-height: 22px;
    --swiper-carousel__pagination-margin-top: 0;
    --swiper-carousel__content-wrapper-padding: 0 48px;

    &__content-wrapper {
        position: relative;
        padding: var(--swiper-carousel__content-wrapper-padding);
    }

    .swiper-wrapper {
        font-size: 1em;
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    &__nav-button {
        width: var(--swiper-carousel__button-size);
        height: var(--swiper-carousel__button-size);
        border-radius: 50%;
        background-color: $colors__white;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &--disabled {
            visibility: hidden;
        }
    }

    &__icon {
        width: var(--swiper-carousel__icon-width);
        height: var(--swiper-carousel__icon-height);
        color: $colors__gray--33;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        &--left {
            left: 40%;
        }

        &--right {
            left: 60%;
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin-top: var(--swiper-carousel__pagination-margin-top);
    }

    &__page-bullet {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin: 0 8px;
        border: 1px solid $colors__gray--71;
        @include transition(background-color);

        &:hover,
        &--active {
            background-color: $colors__gray--71;
        }
    }


    &__play-control {
        width: 50px;
        height: 50px;
        background-color: $colors__white;
        box-shadow: 0 0 6px 0 rgba($colors__black, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 2em auto 0;
    }

    &__play-icon {
        display: block;
        width: 25px;
        height: 25px;

        use {
            width: 100%;
            height: 100%;
        }
    }



    @include media('>=tablet-landscape') {
        --swiper-carousel__button-size: 42px;
        --swiper-carousel__icon-width: 14px;
        --swiper-carousel__icon-height: 26px;
        --swiper-carousel__pagination-margin-top: 16px;
        --swiper-carousel__content-wrapper-padding: 0 64px;
    }
}

.insights-carousel {
    @media print {
        display: none;
    }
}