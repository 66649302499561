.one-column-layout {
    &--detail {
        padding-top: 48px;
        padding-bottom: 48px;
    
        @include media('>=tablet-landscape') {
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }
}