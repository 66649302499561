.articles-listing-with-featured {
    --articles-listing-with-featured__item-y-spacing: 32px;

    &__items {
        list-style-type: none;
    }

    &__item {
        padding-top: var(--articles-listing-with-featured__item-y-spacing);
        margin-top: var(--articles-listing-with-featured__item-y-spacing);
        border-top: 1px solid $colors__gray--d8;
    }

    @include media('>=tablet-portrait') {
        --articles-listing-with-featured__featured-width: 45%;
        --articles-listing-with-featured__items-width: 45%;

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__featured {
            width: var(--articles-listing-with-featured__featured-width);
        }

        &__items {
            width: var(--articles-listing-with-featured__items-width);
        }

        &__item {
            &:first-child {
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    @include media('>=tablet-landscape') {
        --articles-listing-with-featured__featured-width: 55%;
        --articles-listing-with-featured__items-width: 35%;
    }
}
