.industries-services-leadin {
    background-color: $color-blue-shakespear;
    padding: 1em;

    h1 {
        color: $color-white;
        margin-bottom: 0px;
        line-height: 1em;
    }

    p {
        color: $color-white;
        font-size: 1.25em;
        line-height: 1.25em;
        margin-top: 0;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &__HeroImage {
        Height: 298px;
    }

    &__Yellow {
        background-color: #FBC55A;
    }

    &__HeaderTitleSection {

        height:274px;
        
        &__title {
            font-size: 24px;
            font-family: MuseoSans-700;
            font-style: normal;
            color: #ffff;
        }
    }

    &__container {
        display: flex;
        margin-top: 25px;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 8em;
        padding: 1.5em 0;
        position: relative;
        align-items: center;
    }

    &__TitleSection {
        padding-left: 30px;
        padding-bottom: .5em;
        flex-flow: row wrap;
        flex: 3;
        padding-right: 1em;
        background-color: #F2FBFB;

        @include bp-max($bp-tablet) {
            & {
                flex: unset;
            }
        }

        &__title {
            font-size: 24px;
            font-family: MuseoSans-700;
            font-style: normal;
            color: #ffff;
        }
    }
}

@media print {
    .industries-services-leadin {
        padding: 0 !important;
    }
}
