.interior-header {
    // background-color: $color-blue-shakespear;
    background-color: $color-blue-riptide;
    padding: 1.75em 1em;
    margin-bottom: 1em;

    @include bp-max($bp-desktop) {
        h1 {
            // color: $color-white;
            @include font-primary-serif(52, 16);
            color: $color-dark-blue-footer;
            margin-bottom: .25em;
            line-height: 1em;
        }

        p {
            color: $color-white;
            font-size: 1.25em;
            line-height: 1.25em;
            margin-top: 0;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    @include bp-max($bp-mobile) {
        h1 {
            // color: $color-white;
            @include font-primary-serif(52, 16);
            color: $color-dark-blue-footer;
            margin-bottom: .25em;
            line-height: 1.25em;
            font-size: 1.75em;
        }

        p {
            color: $color-white;
            font-size: 1.25em;
            line-height: 1.25em;
            margin-top: 0;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
