.careers-content {
    @include container;
    padding: 15px 0 30px 0;

    .section-text {
        @include span(6);
    }

    .section-video {
        @include span(6 last);
    }

    @include bp-max($bp-ipad) {
        .section-text {
            @include span(100%);
        }

        .section-video {
            @include span(100%);
        }

        button, .button {
            @include span(100%);
        }
    }
}
