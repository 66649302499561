.people-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    &--search-result {
        max-width: 249px;
        margin-left: auto;
        margin-right: auto;

        & + & {
            margin-top: 30px;
        }
    }

    &--search-result & {
        &__top-content {
            flex-grow: 0;
        }
    }

    &__image {
        padding-bottom: 100%;
        background-size: cover;
        background-position: center top;
        display: block;
        border-radius: 200px;
        border: solid 5px $color-lighter-grey;

        &:hover {
            border: solid 5px $color-dark-blue-footer;
            border-radius: 200px;
            padding-bottom: 100%;
        }
    }

    &__content {
        flex-grow: 1;
        padding: 16px 8px;
        display: flex;
        flex-direction: column;

        &:after {
        }
    }

    &__top-content {
        flex-grow: 1;
        text-align: center;
    }

    &__name {
        @include fonts__sans-serif-medium;
        font-size: 18px;
        line-height: 1.25;
        color: $colors__blue;
    }

    div.people-card__name {
        color: $colors__gray-on-white;
    }

    &__title {
        @include fonts__sans-serif-light;
        font-size: 14px;
        line-height: 1.2;
        color: $colors__gray--33;
        margin-top: 8px;
    }

    &--search-result & {
        &__title {
            font-size: 16px
        }
    }

    &__meta {
        @include fonts__sans-serif-light;
        font-size: 14px;
        line-height: 1.2;
        color: $colors__gray-on-white;
        margin-top: 5px;
    }

    &__social-links {
        margin-top: 8px;
        margin-left: 58px;
        display: flex;
        list-style-type: none; // override legacy styles
    }

    &__social-links-lead {
        margin-top: 8px;
        margin-left: 100px;
        display: flex;
        list-style-type: none; // override legacy styles
    }

    &__social-link-container {
        margin-right: 8px;

        &:before {
            content: none; // override legacy styles
        }
    }

    &__social-link {
        $size: 28px;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 1px solid $colors__gray--33;
        position: relative;
        display: block;
        transition: border-color .2s ease-in;

        &--light {
            border-color: $colors__gray--84;

            .people-card__icon {
                color: $colors__gray--84;
                transition: color .2s ease-in;
            }
        }
    }

    &__icon {
        $size: 16px;
        width: $size;
        height: $size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: $colors__gray--33;
    }

    &__telephone {
        font-size: 14px;
    }

    &__profile {
        @include fonts__sans-serif-light;
        font-size: 14px;

        a {
            text-decoration: underline;
            font-weight: $fonts__font-weight--medium;
        }
    }

    @include media('>=phone') {

        &__social-link:hover {
            border-color: $colors__blue;

            .people-card__icon {
                color: $colors__blue;
            }
        }

        &--search-result {
            max-width: 200px;
            margin-bottom: 15px;
            // margin-left: 0;
            // margin-right: 0;
            & + & {
                margin-top: 0;
            }
        }

        &--search-result & {
            &__content {
                padding-bottom: 0;
            }
        }
    }
}
