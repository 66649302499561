.math {
    font-style: italic;
    border: 1px solid $color-gray-light-grey;
    color: $color-brown-spice;
    padding: .50em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;

    div {
        display: inline;
        font-weight: normal;
    }
}
