.triple-image-component {
    --featured-content__content-padding: 48px;
    --featured-content__line-padding: 24px; 
    
    margin-top: var(--featured-content__content-padding);

    padding: var(--featured-content__content-padding) 0;
    text-align: center;
    
    &__title {
        @include fonts__sans-serif-bold;
        margin-top: var(--featured-content__line-padding);
    }

    &__link {
        @include fonts__sans-serif-bold;
        margin-top: var(--featured-content__line-padding);
    }

    &__list {
        list-style-type: none;

        &--item {
            color: $colors__blue;
            text-align: center;

            h3 {
                color: $colors__blue;
            }

            & + & {
                margin-top: 34px;
            }
        }        
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding-bottom: 62.4%;

        &-container {
            position: relative;
        }

        &__play-button {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 5;
            color: $colors__white;
            width: 100px;
            height: 100px;
            transform: translate(-50%, -50%);
            pointer-events: none;

            svg {
                height: 100%;
                width: 100%;
            }
        }    
    }

    @include media('>=tablet-portrait') {
        --featured-content__content-padding: 64px;
        --featured-content__line-padding: 32px;

        &__title {
            margin-top: var(--featured-content__line-padding);
        }

        &__link {
            margin-top: var(--featured-content__line-padding);
        }

        &__list {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 0;

            &--item {
                width: 30%;
                margin-bottom: 0;

                & + & {
                    margin-top: 0;
                }
            }
        }    

        &__img {
            position: relative;
            z-index: 1;           
        }            
    }
}