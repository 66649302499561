.flex-video {
    position: relative;
    padding-top: 1.5625em;
    padding-bottom: 67.5%;
    height: 0;
    margin-bottom: 1em;
    overflow: hidden;

    .widescreen {
        padding-bottom: 57.25%;
    }

    .vimeo {
        padding-top: 0;
    }

    iframe, object, embed, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /*padding-top: 30px;*/
    margin-bottom: 1em;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.embeded-video {
    h1, h2, h3, h4, h5, h6, p {
        margin-left: 0px;
    }
}
