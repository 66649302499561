@media print {

    * {
        transition: none !important;
    }

    @page {

        margin: 1.5cm;
    }

    body {
        background-image: none !important;
        background-color: #fff !important;
        color: #000 !important;
        font-size: 85% !important;
    }

    .site-navigation,
    .recomended-container,
    .social-sidebar,
    .ad-placeholder,
    .small-social-bar,
    .mobile-search-toggle,
    .site-navigation-mobile,
    .site-search-mobile,
    .footer-subs,
    .footer-nav .know-us,
    .footer-nav .get-started,
    .footer-social .social-wrapper,
    iframe {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    .site-logo {
        display: block !important;
        float: none !important;
    }
    /*main img {display:none;}*/
    /*CAREERS MICROSITE*/

    .locations-image,
    .careers-building .section-image,
    .careers-promise img,
    .careers-impact img {
        display: none !important;
        width: 0px !important;
        height: 0px !important;
    }

    table {
        page-break-inside: avoid
    }

    .show-for-print {
        display: block;
    }

    .hide-for-print {
        display: none !important;
    }
}
