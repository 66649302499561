.navigation-tiles {
    --navigation-tiles__border-style: 6px solid #{$colors__teal};
    // --navigation-tiles__item-spacing: 8px;
    --navigation-tiles__label-font-size: 18px;

    $navigation-tiles__item-spacing-sm: 8px;
    $navigation-tiles__item-spacing-lg: 30px;

    position: relative;
    z-index: 1;

    &__items {
        list-style-type: none;
    }

    &__item {
        &:not(:first-child) {
            margin-top: $navigation-tiles__item-spacing-sm;
        }
    }

    &__link {
        border-left: var(--navigation-tiles__border-style);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        padding-left: 26px;
        box-shadow: 0 0 6px 0 rgba($colors__black, 0.2);
        background-color: $colors__white;

        @include transition(border-color);

        @include target-devices-with-hover {
            border-color: rgba($colors__teal, 0);
        }

        &:hover {
            text-decoration: none;  // Override legacy styles

            @include target-devices-with-hover {
                border-color: $colors__teal;
            }
        }

        &:focus {
            border-color: $colors__teal;
        }
    }

    &__navigation-icon {
        display: none;
    }

    &__label {
        @include fonts__sans-serif-bold;
        font-size: var(--navigation-tiles__label-font-size);
        line-height: 1.22;
        color: $colors__blue;
    }

    &__arrow {
        $navigation-tiles__arrow-width: 10px;

        width: $navigation-tiles__arrow-width;
        height: $navigation-tiles__arrow-width * 2;
        color: $colors__blue;
    }

    @include media('>=tablet-portrait') {
        // --navigation-tiles__item-spacing: 30px;
        --navigation-tiles__item-width: calc(50% - #{$navigation-tiles__item-spacing-lg} / 2);
        --navigation-tiles__label-font-size: 20px;
        --navigation-tiles__label-padding: 32px;

        &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            width: var(--navigation-tiles__item-width);

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        &__link {
            flex-direction: column;
            border-left: none;
            border-bottom: var(--navigation-tiles__border-style);
            padding: var(--navigation-tiles__label-padding);
            height: 100%;

            @include target-devices-with-hover {
                border-color: rgba($colors__teal, 0);
            }
        }

        &__label {
            text-align: center;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__arrow {
            display: none;
        }
    }

    @include media('>=tablet-landscape') {
        $side-padding: px-to-em(15px);
        --navigation-tiles__item-width: calc(25% - #{$side-padding * 2});

        &__items {
            margin-left: $side-padding * -1;
            margin-right: $side-padding * -1;
            justify-content: center;
        }

        &__item {
            margin-left: $side-padding;
            margin-right: $side-padding;

            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 0;
            }
        }
    }
}
