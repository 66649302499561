.resource-header--balance {

  .resource-header__content-inner {
    text-align: center;
  }

  .resource-header__abstract {
      text-transform: uppercase;
      font-size: 23px;
      line-height: 1.2;      

      > * {
        @include fonts__sans-serif-bold; // Museo-700
        color: $colors__blue;
      }
  }

  // page specific padding updates to keep copy on 3 lines
  &.balance-pb {

    .resource-header__content {
      padding: 40px 24px;
    }
  }

  &.balance-ps {
    padding: 40px 48px;
  }
}

@include media('<=tablet-portrait') {

  .resource-header--balance {

    .resource-header__content {
      padding: 30px 24px 46px;
    }

    .resource-header__content-inner {
      padding: 0;
    }

    .resource-header__abstract {

      > * {
        font-size: 20px;
      }
    }

    .resource-header__title {
      font-size: 42px;
    }

    .resource-header__background {
      min-height: 220px;
    }
  }
}
