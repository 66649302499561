.component-testimonials {
    padding: 110px 0;
    background: rgba(64, 64, 65, 0.1);

    &__quote-icon {
        width: 72px;
        height: 58px;
        background: url('#{$file-path-images}/quote-icon.svg');
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto -29px;
        position: relative;
        z-index: 1;

        @include bp-max($bp-desktop) {
            width: 58px;
            height: 46px;
            margin-bottom: -23px;
        }
    }

    &__citation {
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }

    &__imageless {
        text-align: center;

        .component-testimonials__image {
            display: none;
        }
    }

    &__image {
        background-size: cover;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        margin-right: 20px;
    }
    // Slick(carousel)
    .slick-testimonials {
        height: 100%;
        position: relative;
        padding: 0 80px;

        .slick-list {
            box-shadow: 2px 4px 6px 0px rgba(0,0,0,0.35);
        }
        // Slides
        .slides .slide {
            background: $color-white;
            position: relative;
            height: 390px;
            // Structure
            .wrapper {
                display: table;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 390px;

                > div {
                    display: table-cell;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    padding-right: 70px;
                    padding-left: 70px;
                }
            }
            // Content
            blockquote {
                @include font-primary;
                margin: 0;
                border: none;
                padding: 0;
                width: auto;
                margin: 0 auto;
                float: none;
                max-width: 656px;

                > p {
                    @include font-primary-medium;
                    color: $color-blue-blue;
                    font-style: italic;
                    text-align: center;
                    font-size: 32px;
                    margin-bottom: 30px;
                }

                cite {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    margin-top: 0;
                    color: $color-brown-spice;

                    &:before {
                        display: none;
                    }

                    .company-name {
                        display: block;
                        font-weight: normal;
                    }
                }
            }

            @include bp-max($bp-desktop) {
                blockquote {
                    > p {
                        font-size: 24px;
                    }
                }
            }

            @include bp-max(750px) {
                .wrapper {
                    > div {
                        padding: 60px 20px 40px;
                    }
                }

                blockquote {
                    > p {
                        font-size: 22px;
                        line-height: 1.3;
                    }

                    cite {
                        font-size: 16px;
                        line-height: 1.3;

                        .company-name {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
        // Buttons
        a.btn-slick {
            position: absolute;
            top: 0;
            left: 0;
            background: none;
            height: 100%;
            width: 50px;
            color: $color-brown-spice;
            opacity: 0.45;
            font-size: 4em;
            overflow: hidden;
            z-index: 1000;
            display: none; // hide initially (the overrides seem weird but it does prevent flickering)
            &.prev {
                margin-left: 20px;
            }

            &.next {
                margin-right: 20px;
                left: auto;
                right: 0;
            }

            &.slick-arrow {
                display: block;
            }
            // show when enabled
            &.slick-hidden {
                display: none;
            }
            // hide when only 1 slide exists
            &:hover, &:focus {
                color: $color-brown-cocao-brown;
            }

            > .fa {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .slick-dots {
            display: flex;
            list-style: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 26px;

            li {
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }

                &.slick-active {
                    button {
                        background: rgba(64,64,65,0.5);
                        height: 18px;
                        width: 18px;
                    }
                }
            }

            button {
                font-size: 0;
                line-height: 0;
                height: 14px;
                width: 14px;
                background: $color-white;
                border: 1px solid #979797;
                border-radius: 50%;
                padding: 0;

                &:after {
                    display: none;
                }
            }

            @include bp-max($bp-ipad) {
                visibility: hidden;
            }
        }
    }
    // Responsive
    @include bp-max(750px) {
        &__citation {
            flex-direction: column;
            text-align: center;
        }

        &__image {
            width: 77px;
            height: 77px;
            margin-right: 0;
            margin-bottom: 10px;
        }

        .slick-testimonials {
            padding: 0 45px;

            .slides .slides {
                height: 345px;

                .wrapper {
                    height: 345px;
                }
            }

            a.btn-slick {
                &.prev {
                    margin-left: 0;
                }

                &.next {
                    margin-right: 0;
                }

                > .fa {
                    font-size: 38px;
                }
            }
        }
    }
    // Tablet
    @include bp-max(550px) {
        // Slick(carousel)
        .slick-testimonials {
            // Slides
            .slides .slide {
                // Structure
                .wrapper {
                    > div {
                        padding: 0 30px;
                    }
                }
            }
        }
    }
    // Mobile
    @include bp-max($bp-mobile) {
        // Slick(carousel)
        .slick-testimonials {
            height: 480px;
            // Slides
            .slides .slide {
                height: 480px;
                // Structure
                .wrapper {
                    height: 480px;

                    > div {
                        padding: 0 20px;
                    }
                }
            }
        }
    }
}
