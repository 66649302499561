// button,
.button,
input[type="submit"],
.btn,
.btn-default {
    @include call-to-action-styles('.button');
}

.button {
    &--section-margin {
        margin-top: 32px;
    }

    @include media('>=tablet-landscape') {
        &--section-margin {
            margin-top: 40px;
        }
    }
}

form input[type="submit"] {
    color: var(--call-to-action__color);
    width: auto;
}

.button--tertiary.button--tertiary {
    @include font-primary-medium-bold;
    background-color: transparent;
    color: $colors__blue !important;
    padding: 0 1.5em 0 0;
    border: 0 none;
    font-size: px-to-em(16px);
    font-weight: normal;
    width: auto;

    &:hover {
        text-decoration: underline;
        color: $colors__blue;
        background-color: transparent;
    }

    &::after {
        content: '';
        display:inline-block;
        margin-left: 8px;
        height: 12px;
        width: 14px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px 12px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='13px' viewBox='0 0 16 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E8551E96D-B998-4A50-B1ED-71D6BA3696B2@3x%3C/title%3E%3Cg id='MVP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='D---Nav-Expanded---Industries' transform='translate(-1116.000000, -333.000000)' stroke='currentColor' stroke-width='2'%3E%3Cg id='Link-Box' transform='translate(1020.000000, 140.000000)'%3E%3Cg id='Button/Text-Only' transform='translate(0.263858, 190.000000)'%3E%3Cg id='Group' transform='translate(96.000000, 4.000000)'%3E%3Cpath d='M-9.14823772e-14,5.5305 L13.95,5.5305 L-9.14823772e-14,5.5305 Z' id='Stroke-1'%3E%3C/path%3E%3Cpolygon id='Stroke-3' points='8.0661 -6.39488462e-14 13.6041 5.538 8.0661 11.0775 13.6041 5.538'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }

}
// More specific to override .rte link styles
.button--small.button--small {
    @include media('>=tablet-portrait') {
        font-size: px-to-em(16px);
    }
}

.rte {
    .button {
        &:hover {
            color: var(--call-to-action__hover-color);
            background-color: var(--call-to-action__hover-bg-color);
            text-decoration: none !important;  // override existing button styles
        }
    }

    .button--tertiary {
        &:hover {
            text-decoration: underline !important;
        }
    }
}