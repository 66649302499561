.copy-plus-icons {
    --copy-plus-icons__content-padding: 48px 24px;
    --copy-plus-icons__background-padding: 104px;
    --copy-plus-icons__content-translateX: 0;
    --copy-plus-icons__content-translateY: -15%;
    --copy-plus-icons__tiles-translateY: 0;
    position: relative;

    &--text-align {
        &-right {
            .copy-plus-icons__content {
                text-align: right;
            }
        }

        &-left {
            .copy-plus-icons__content {
                text-align: left;
            }
        }

        &-center {
            .copy-plus-icons__content {
                text-align: center !important;
            }
        }
    }

    &__width-container {
        z-index: 2;
    }

    &__img-container {
        margin: 0 $spacing__gutter-width * -1;
        position: relative;
        z-index: 3;
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding-bottom: 62.4%;
    }

    &__content {
        padding: var(--copy-plus-icons__content-padding);
        text-align: center;
        background-color: $colors__white;
        z-index: 4;
        position: relative;
        transform: translate(var(--copy-plus-icons__content-translateX), var(--copy-plus-icons__content-translateY));
    }

    &__background {
        height: calc(100% - var(--copy-plus-icons__background-padding));
        width: 100%;
        position: absolute;
        left: 0;
        top: var(--copy-plus-icons__background-padding);
        background-color: var(--copy-plus-icons__background-color);
    }

    &--theme {
        &-home {
            --copy-plus-icons__background-color: #{$colors__teal};
            --copy-plus-icons__tile_hover-color: #{$colors__teal--dark};
        }
        /*saffron tint*/
        &-orange {
            --copy-plus-icons__background-color: #{$colors__saffrontint};
            --copy-plus-icons__tile_hover-color: #{$colors__orange};
        }
        /*riptide tint*/
        &-teal {
            --copy-plus-icons__background-color: #{$colors__riptidetint};
            --copy-plus-icons__tile_hover-color: #{$colors__teal};
        }
        /*celadon tint*/
        &-green {
            --copy-plus-icons__background-color: #{$colors__celadontint};
            --copy-plus-icons__tile_hover-color: #{$colors__green};
        }

        &-blue {
            --copy-plus-icons__background-color: #{$colors__blue--light};
            --copy-plus-icons__tile_hover-color: #{$colors__blue};
        }

        &-celadon {
            --copy-plus-icons__background-color: #{$colors__celadon};
            --copy-plus-icons__tile_hover-color: #{$colors__celadon};
        }

        &-navy {
            --copy-plus-icons__background-color: #{$colors__navy};
            --copy-plus-icons__tile_hover-color: #{$colors__navy};
        }

        &-riptide {
            --copy-plus-icons__background-color: #{$colors__riptide};
            --copy-plus-icons__tile_hover-color: #{$colors__riptide};
        }

        &-saffron {
            --copy-plus-icons__background-color: #{$colors__saffron};
            --copy-plus-icons__tile_hover-color: #{$colors__saffron};
        }

        &-cloud {
            --copy-plus-icons__background-color: #{$colors__cloud};
            --copy-plus-icons__tile_hover-color: #{$colors__cloud};
        }
    }

    @include media('>=tablet-portrait') {
        --copy-plus-icons__image-height: 364px;
        --copy-plus-icons__content-width: 40%;
        --copy-plus-icons__content-translateY: 32px;
        --copy-plus-icons__content-translateX: 100%;

        &--align-right {
            --copy-plus-icons__image-right: 0;
            --copy-plus-icons__content-translateX: 0;
        }

        &__width-container {
            position: relative;
        }

        &__img-container {
            right: var(--copy-plus-icons__image-right);
            position: absolute;
            width: 60%;
            margin: 0;
        }

        &__content {
            margin-bottom: 64px;
            min-height: 300px;
            width: 50%;
        }

        &__img {
            padding-bottom: var(--copy-plus-icons__image-height);
            position: relative;
            z-index: 1;
        }
    }

    &__tiles {
        --copy-plus-icons-tiles__border-style: 6px solid #{$colors__teal};
        --copy-plus-icons-tiles__label-font-size: 18px;
        --copy-plus-icons-tiles__label-padding: 22px;

        $copy-plus-icons-tiles__item-spacing-sm: 8px;
        $copy-plus-icons-tiles__item-spacing-lg: 30px;

        position: relative;
        z-index: 1;
        padding: 32px 0;

        &__items {
            list-style-type: none;
        }

        &__item {
            &:not(:first-child) {
                margin-top: $copy-plus-icons-tiles__item-spacing-sm;
            }
        }

        &__tile-item {
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: var(--copy-plus-icons-tiles__label-padding);
            border-bottom: var(--copy-plus-icons-tiles__border-style);
            box-shadow: 0 0 6px 0 rgba($colors__black, 0.2);
            background-color: $colors__white;
            height: 100%;

            @include transition(border-color);

            @include target-devices-with-hover {
                border-color: var(--copy-plus-icons__tile_hover-color);
            }

            &:hover {
                text-decoration: none; // Override legacy styles
                @include target-devices-with-hover {
                    border-color: var(--copy-plus-icons__tile_hover-color);
                }
            }

            &:focus {
                border-color: var(--copy-plus-icons__tile_hover-color);
            }
        }

        &__label,
        &__label-link {
            @include fonts__sans-serif-bold;
            font-size: var(--copy-plus-icons-tiles__label-font-size);
            line-height: 1.22;
            color: $colors__blue;
            text-align: center;
            display: block;
        }

        &__label {
            color: $colors__gray--33;
        }

        &__text {
            text-align: center;
        }

        @include media('>=tablet-portrait') {
            // --copy-plus-icons-tiles__item-spacing: 30px;
            --copy-plus-icons-tiles__item-width: calc(50% - #{$copy-plus-icons-tiles__item-spacing-lg});
            --copy-plus-icons-tiles__label-font-size: 20px;
            --copy-plus-icons-tiles__label-padding: 32px;
            padding: 32px;

            &__items {
                display: grid;
                grid-gap: 24px;
                grid-template-columns: repeat(2,minmax(calc(50% - 24px),1fr));

                &--3 {
                    grid-template-columns: repeat(3,minmax(calc(33.3333% - 24px),1fr));
                }
            }

            &__item {
                margin-bottom: 0;

                &:not(:first-child) {
                    margin-top: 0;
                }
            }
        }
    }
}
