.featured-content {
    --featured-content__content-padding: 24px 0 48px;
    --featured-content__accent-width: 100%;
    --featured-content__accent-height: 72px;

    position: relative;
    overflow: hidden;

    &__img-container {
        margin: 0 $spacing__gutter-width * -1;
        position: relative;
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding-bottom: 62.4%;
    }

    &:not(.featured-content__media_placement--inset) {
        .featured-content {
            &__accent {
                position: absolute;
                bottom: 0;
                left: var(--featured-content__accent-left, 0);
                width: var(--featured-content__accent-width);
                height: var(--featured-content__accent-height);
                mix-blend-mode: multiply;
            }        
        }
    }

    &.featured-content__media_placement--inset {
        .featured-content {
            &__img-container {
                padding: var(--featured-content__content-padding);
            }

            &__accent {
                width: var(--featured-content__accent-width);
                height: var(--featured-content__accent-height);
            }
        }
    }  

    &__content {
        text-align: center;
        padding: var(--featured-content__content-padding);
        overflow: hidden;
    }

    &--text-align {
        &-right {
            .section-body {
                text-align: right;
            }

            .call-to-action {
                float: right;
                clear: both;
            }
        }

        &-left {
            .section-body {
                text-align: left;
            }

            .call-to-action {
                float: left;
                clear: both;
            }
        }

        &-center {
            .featured-content__content {
                text-align: center!important;
            }
        }
    }

    @include media('>=tablet-portrait') {
        $featured-content__accent-extension: 24px;
        --featured-content__content-width: 45%;
        --featured-content__accent-height: 96px;
        --featured-content__content-padding: 56px 0;

        &--align-center {
            --featured-content__flex-justify: center;
        }

        &--align-right {
            --featured-content__flex-order: 1;
        }

        &__width-container {
            display: flex;
        }

        &__img-container {
            width: 50%;
            margin: 0;
            order: var(--featured-content__flex-order);

            + .featured-content__content {
                width: var(--featured-content__content-width);
                text-align: left;
            }
        }

        &:not(.featured-content__media_placement--inset) {            
            --featured-content__accent-width: calc(50% + #{$featured-content__accent-extension});

            &.featured-content {
                &--align-right {
                    --featured-content__img-left: 50%;
                    --featured-content__accent-left: calc(50% - #{$featured-content__accent-extension});
                }
            }

            .featured-content {
                &__width-container {
                    justify-content: var(--featured-content__flex-justify, space-between);
                }      

                &__img-container {
                    position: static;
                }    

                &__img {
                    position: absolute;
                    top: 0;
                    left: var(--featured-content__img-left, 0);
                    width: 50%;
                    height: 100%;
                    padding-bottom: 0;
                }                                      
            }            
        }

        &.featured-content__media_placement--inset {
            --featured-content__accent-width: calc(50vw + #{$featured-content__accent-extension});
            --featured-content__accent-left: auto;
            --featured-content__accent-right: #{$featured-content__accent-extension * -1};

            &.featured-content {
                &--align-right {
                    --featured-content__accent-left: #{$featured-content__accent-extension * -1};
                    --featured-content__accent-right: auto;
                }  
            }

            .featured-content {
                &__width-container {
                    justify-content: space-between;
                    align-items: flex-start;
                }       

                &__img-container {
                    position: relative;
                }       

                &__img {
                    position: relative;
                    z-index: 1;
                }        

                &__accent {
                    position: absolute;
                    height: var(--featured-content__accent-height);
                    left: var(--featured-content__accent-left);
                    right: var(--featured-content__accent-right);
                    bottom: $featured-content__accent-extension * 1.5;
                }                                                 
            }                        
        }  

        &__content {
            max-width: 730px;
        }

        .campaign-landing.campaign-landing & {
            --spacing__component-y-margin: 56px;
            --featured-content__accent-width: calc(50vw + #{$featured-content__accent-extension});
            --featured-content__img-left: -24px;

            &--background-light {
                margin-top: var(--spacing__component-y-margin);
                margin-bottom: var(--spacing__component-y-margin);

                .featured-content__content {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            &:not(.featured-content--background-light) {
                .featured-content__img-container {
                    margin: var(--featured-content__content-padding);
                }
            }

            &__width-container {
                align-items: center;
            }

            &__img-container {
                position: relative;
            }

            &__img {                
                position: static;
                width: auto;
                height: auto;
                padding-bottom: 65.5%;
                left: 0;
            }

            &__accent {
                right: var(--featured-content__img-left);
                bottom: var(--featured-content__img-left);
                left: auto;
            }

            &--align-right .featured-content__accent {
                right: auto;
                left: var(--featured-content__img-left);
            }
        }
    }

    @include media('>=tablet-landscape') {
        --featured-content__content-padding: 64px 0;
        --featured-content__content-width: 40%;
        --featured-content__accent-height: 116px;

        .campaign-landing & {
            --spacing__component-y-margin: 64px;
        }
    }
}
