.careers-building {
    // background-color: $color-violet-strikemaster;
    background-color: $color-dark-blue-footer;
    padding-bottom: px-to-em(50px);
    @include clearfix;

    .section-image {
        max-width: 100%;
    }

    .section-header {
        font-size: 1em;
        text-align: center;
        margin-bottom: 2em;
    }

    .header-style {
        @include font-primary-bold;
        color: $color-white;
        font-size: 5.25em;
        line-height: 1;
        font-weight: normal;

        &:first-of-type {
        }

        &:nth-of-type(2) {
        }

        &:nth-of-type(3) {
        }
    }

    .button {
        float: right;
        // inverted button style
        border: 2px solid $color-white;
    }

    .call-to-action,
    .call-action--outline,
    .call-action--outline-white {
        float: right;
    }

    @include bp-max($bp-tablet) {
        .header-style {
            font-size: px-to-em(75px);
        }
    }
}
