.hubspot-form-with-copy {
    --hubspot-form-with-copy__icon-container-width: 75px;

    &__jump-link {
        margin-top: -150px;
        position: absolute;
    }

    &__top-content {
        display: flex;
        flex-wrap: wrap;
    }

    &__icon-container {
        @include media('<tablet-portrait') {
            width: 100%;
            text-align: center;
        }

        @include media('>=tablet-portrait') {
            width: var(--hubspot-form-with-copy__icon-container-width);
            order: 1;
            margin-top: 1.5rem;
        }

        @include media('>=tablet-landscape') {
            margin-top: 2rem;
        }
    }

    &__icon {
        width: 55px;
        height: 55px;
    }

    .section-headline {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
    }

    .section-body,
    .rte {

        @include bp-max(768px) {
            font-size: 18px;
        }
    }

    .section-body {
        @include media('>=tablet-portrait') {
            order: 2;
            width: calc(100% - var(--hubspot-form-with-copy__icon-container-width));
        }
    }

    .rte {
        margin: 1.5rem 0;
    }
}
