.search-container {
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#d4d4d4',GradientType=0 );
    height: 34px;
    width: 100%;
    margin-bottom: 1em;
    background-color: $color-white;

    input {
        &:focus {
            background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.46) 46%, rgba(212,212,212,1) 100%);
            box-shadow: none;
        }
    }

    input[type="text"] {
        background: transparent;
        padding: 10px 5px 5px 5px;
        color: $color-brown-spice;
        border: 1px solid $color-brown-tide !important;
        border-right: none !important;
        font-size: 1em;
        line-height: 1;
        width: 90% !important;
    }

    button {
        transform: rotateY(-180deg);
        float: right;
        border-radius: 0;
        height: 32px;
        width: 10% !important;
        padding: 0px !important;
        font-size: 1.05em;
        line-height: 0;
        color: $color-white;
        background-color: $color-dark-blue-footer;

        &:after {
            width: 0px;
            display: none;
        }
    }
}
