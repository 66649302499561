.landing-page {
    .content-main.detail.lp {
        width: 100%;
        @include container;

        header {
            width: 80%;
            margin: 0 10%;
        }

        .content-well .main-container {
            margin-left: 10%;
            padding-top: 1em;
        }

        article {
            @include span(5.25);
        }

        .form {
            @include span(5.25);
            margin-left: 3px;
            margin-bottom: 1em;

            header {
                width: 100%;
                margin: 0;
                padding: .50em;
                background-color: $color-orange-rich-gold;
            }

            .button {
                margin-top: 1em;
            }
        }

        .right-side-bar {
            $spacing: 1em;
            padding: 1em;
            background-color: $color-gray-white;
            color: $color-brown-spice;
            line-height: 1;
        }

        aside {
            @include span(1.5 last);
            margin-left: -3px;
        }
    }

    &.resource-request {
        .social-sidebar {
            margin-left: 0 !important;
        }

        .aside-container {
            .form-group input.form-control {
                width: 100% !important;
            }

            .form-submit-border .btn {
                width: 100%;
                font-size: 1em;
                margin: 1em 0 !important;
                padding: 0 30px;
            }
        }
    }
}

.resource-request {
    .social-sidebar {
        li {
            margin-left: 0;
            text-align: center !important;
        }
    }
}

@include bp-max($bp-desktop) {

    .content-well .main-container {
        margin-left: 0 !important;
        padding-top: 1em;
    }

    article, .form {
        width: 100% !important;
        display: block !important;
    }

    .form {
        margin-left: 0px;
    }
}

@include bp-max(990px) {
    .content-well .main-container {
        width: 100%;
        display: block;
    }
}

@media print {
    .landing-page {
        .content-main.detail.lp article {
            width: 100% !important;
        }

        .form {
            width: 0px !important;
            height: 0px !important;
            float: none !important;
            display: none !important;
        }
    }
}
