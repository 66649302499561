.interior-header {
    // background-color: $color-blue-shakespear;
    background-color: $color-blue-riptide;
    margin-bottom: 0;
    color: $color-blue-darkest;
    padding: 1em;

    h1, p, a {
        color: $color-blue-darkest;
    }

    h1 {
        @include font-primary-serif(52, 16);
        color: $color-dark-blue-footer;
        margin-bottom: .25em;
        font-weight: bold;
    }

    p {
        font-size: 1.25em;
    }

    p a, li a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        li {
            list-style: none;
        }
    }

    ul.menu {
        margin-left: 0px;
        margin-bottom: 0px;

        li {
            list-style: none;

            &:before {
                color: $color-blue-darkest;
            }

            a, span {
                cursor: pointer;
                padding: .35em;
                font-size: 15px;
                text-decoration: none;
                color: $color-blue-darkest;
                transition: color $transition-timing-primary, background-color $transition-timing-primary;

                &:hover {
                    text-decoration: none;
                    background-color: $color-blue-darkest;
                    color: $color-white;
                }

                &.active {
                    text-decoration: none;
                    background-color: $color-blue-darkest;
                    color: $color-white;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
    }

    &.blue {
        background-color: $color-blue-shakespear;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-blue-darkest !important;
                color: $color-white;
            }
        }
    }

    &.purple {
        background-color: $color-violet-jagger;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-violet-lilac-bush !important;
            }
        }
    }

    &.orange {
        background-color: $color-orange-rich-gold;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-orange-santa-fe !important;
            }
        }
    }

    &.brown {
        background-color: $color-brown-spice;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-brown-sand-dune !important;
            }
        }
    }

    &.green {
        background-color: $color-green-olive;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-green-wild-willow !important;
            }
        }
    }

    &.red {
        background-color: $color-brown-maroon;

        ul.menu li span {
            &:hover, &.active {
                background-color: $color-brown-au-chico !important;
            }
        }
    }

    @include bp-max($bp-ipad) {
        ul.menu {
            display: block;
            margin: 0;
            padding: 0.875em 0;
            list-style-type: none;
            list-style-position: inside;

            & > *:before {
                content: '';
            }

            & li {
                margin: 0 0 0 0;
                padding: 0;
                font-size: 0.875em;
                display: block !important;
                float: none;
                line-height: 1;
            }

            & li a, span {
                display: block !important;
                margin-bottom: -0.50em;
            }
        }
    }
}

@include bp-max(999999px) {
    .interior-header {
        max-width: 99.9%;
    }
}

@include bp-max(990px) {
    .interior-header {
        max-width: 100%;
    }
}

@media print {
    .interior-header {
        padding: 0px !important;
    }

    .menu {
        display: none !important;
    }
}
