.directory-detail {
    .two-column-layout {
        &__container {
            padding-top: 0;
        }

        @include media('>=tablet-portrait') {
            &__container {
                flex-direction: row-reverse;
            }
        }
    }

    background-color: $colors__white;

    &-hero {
        width: 100%;
        background-color: $colors__blue;

        &__content {
            padding-top: 45px;
            padding-bottom: 45px;

            &--title {
                @include fonts__sans-serif-medium;
                --page-hero__title-size: 38px;
                font-size: var(--page-hero__title-size);
                text-align: left;
                color: $color-white;

                @include media('>=tablet-portrait') {
                    --page-hero__title-size: 48px;
                }
            }

            ul {
                margin: px-to-em(10px) 0;
            }

            &--item, &--item a {
                @include fonts__sans-serif-light;
                --page-hero__cotent-size: 20px;
                font-size: var(--page-hero__cotent-size);
                text-align: left;
                color: $color-white;

                @include media('>=tablet-portrait') {
                    --page-hero__cotent-size: 24px;
                }
            }
        }
    }

    &__aside {
        &--block {
            background-color: $colors__gray--f7;
            padding: px-to-em(20px);
            margin-bottom: px-to-em(20px);

            &_link {
                margin-bottom: 0px !important;
            }

            &_image {
                img {
                    min-width: 300px;
                    height: 300px;
                    display: block;
                    border-radius: 200px;
                    border: 7px solid $colors__white;
                    object-fit: cover;
                    object-position: top;
                }
            }

            &__title {
                font-family: $fonts__sans-serif, 'arial', sans-serif;
                font-style: normal;
                font-weight: $fonts__font-weight--light;
                font-size: 15px;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 1em;
                text-align: center;
            }

            &__icons {
                text-align: center;

                ul {
                    margin-bottom: 0;
                }

                &--roundlarge {
                    line-height: px-to-em(50px);
                    border-radius: 50%;
                    border: 1px solid $colors__gray--84;
                    display: inline-block;
                    height: 48px;
                    width: 48px;

                    svg {
                        color: $colors__gray--84;
                        width: 28px;
                        height: 28px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%,-50%,0);

                        &:hover {
                            color: $colors__blue;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    &:not(:last-child) {
                        margin-right: px-to-em(3px);
                    }

                    &:first-child {
                        &:hover {
                            span {
                                left: auto;
                                right: 100%;

                                &:after {
                                    right: auto;
                                    left: 100%;
                                    border-right: 0px solid transparent;
                                    border-left: 8px solid $color-brown-spice;
                                }
                            }
                        }
                    }
                }

                &--text {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            &__list {

                &--item {
                    & + & {
                        margin-top: 25px;
                    }

                    font-size: 15px;
                    line-height: 20px;

                    &-label {
                        font-size: 14px;
                    }

                    &-meta {
                        font-size: 12px;
                        padding-left: px-to-em(10px);
                    }

                    &-link {
                        text-align: left;
                        padding-left: px-to-em(10px);
                        padding-top: px-to-em(10px);

                        .button {
                            width: auto;
                        }
                    }

                    @include media('>=tablet-portrait') {
                        &-label {
                            font-size: 16px;
                        }

                        &-meta {
                            font-size: 14px;
                            padding-left: px-to-em(10px);
                        }
                    }
                }
            }
        }

        @include media('>=tablet-portrait') {
            transform: translateY(px-to-em(-280px));
        }
    }

    &__icon-phone {
        display: none;
        width: px-to-em(28px);
        height: px-to-em(28px);
        vertical-align: bottom;
        margin-right: 0.5em;
    }

    .breadcrumbs {
        padding-top: 16px;
        margin-bottom: .5em;
        background-color: transparent;
        max-width: 73%;
        width: 100%;

        a {
            @include fonts__sans-serif-medium;
        }

        &__wrapper {
            padding-top: 0;
        }

        @include media('>=tablet-portrait') {
            padding-top: 32px;
            margin-bottom: 32px;
        }
    }

    .component-block + .component-block {
        margin-top: 40px;
    }


    @include media('>=tablet-portrait') {
        .component-block + .component-block {
            margin-top: 48px;
        }

        &__icon-phone {
            display: inline-block;
        }
    }
}
