//------------------------------------------
//              Variables
//------------------------------------------

$font-family-foundation-icons: 'Foundation Icons';
$font-family-franklin-gothic: 'Franklin Gothic';
$font-family-jenna-sue: 'Jenna Sue', Arial;
$font-name-jenna-sue: 'Jenna Sue';
$font-family-league-gothic: 'League Gothic';
$font-family-fontawesome: 'FontAwesome';
$font-family-ionicons: 'Ionicons';
$font-family-stateface-regular: 'statefaceregular';
$font-family-primary-light: 'MuseoSans-300';
$font-family-primary: 'MuseoSans-500';
$font-family-primary-medium: 'MuseoSans-700';
$font-family-primary-bold: 'MuseoSans-900';
$font-family-serif: 'Georgia';

//------------------------------------------
//              Font Size
//------------------------------------------

$base-font-size: 16px;

//------------------------------------------
//              Mixins
//------------------------------------------

@mixin font-face($font-family, $file-path, $font-style) {
    @font-face {
        font-family: $font-family;
        src: url('#{$file-path-fonts}/#{$file-path}.eot');
        src: url('#{$file-path-fonts}/#{$file-path}.eot?#iefix') format('embedded-opentype'),
            url('#{$file-path-fonts}/#{$file-path}.woff2') format('woff2'),
            url('#{$file-path-fonts}/#{$file-path}.woff') format('woff'),
            url('#{$file-path-fonts}/#{$file-path}.ttf') format('truetype'),
            url('#{$file-path-fonts}/#{$file-path}.svg##{$font-family}') format('svg');
        font-style: $font-style;
    }
}

/*@mixin font-franklin ($font-size: null, $base-size: null){
    font-family: $font-family-franklin-gothic;
    font-style: normal;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-jenna ($font-size: null, $base-size: null){
    font-family: $font-family-jenna-sue;
    font-style: normal;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-league ($font-size: null, $base-size: null){
    font-family: $font-family-league-gothic;
    font-style: normal;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}

@mixin font-foundation ($font-size: null, $base-size: null){
    font-family: $font-family-foundation-icons;
    font-style: normal;

    @if ($font-size != null){
        @include font-size($font-size, $base-size);
    }
}*/

@mixin font-fontawesome ($icon-var, $font-size: null, $base-size: null) {
    content: $icon-var;
    font-family: $font-family-fontawesome;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-ionicons ($icon-var, $font-size: null, $base-size: null) {
    content: $icon-var;
    font-family: $font-family-ionicons;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-stateface-regular ($font-size: null, $base-size: null) {
    font-family: $font-family-stateface-regular;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-size($font-size, $base-size) {
    @if ($base-size == null) {
        font-size: px-to-em($font-size);
    }

    @else {
        font-size: px-to-em($font-size, $base-size);
    }
}

@mixin font-primary-light ($font-size: null, $base-size: null) { // MuseoSans 300
    font-family: $font-family-primary-light, 'arial', sans-serif;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-primary ($font-size: null, $base-size: null) { // MuseoSans 500
    font-family: $font-family-primary, 'arial', sans-serif;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-primary-medium ($font-size: null, $base-size: null) { // MuseoSans 500
    font-family: $font-family-primary-medium, 'arial', sans-serif;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-primary-medium-bold ($font-size: null, $base-size: null) { // MuseoSans 700
    font-family: $font-family-primary-medium, 'arial', sans-serif;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-primary-bold ($font-size: null, $base-size: null) { // MuseoSans 900
    font-family: $font-family-primary-bold, 'arial', sans-serif;
    font-style: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}

@mixin font-primary-serif ($font-size: null, $base-size: null) { // Georgia 400
    font-family: $font-family-serif, 'times new roman', serif;
    font-style: normal;
    font-weight: normal;

    @if ($font-size != null) {
        @include font-size($font-size, $base-size);
    }
}



//------------------------------------------
//              Font Load
//------------------------------------------

/*@include font-face($font-family-foundation-icons, 'foundation-icons', normal);
@include font-face($font-family-franklin-gothic, 'FranklinGothic-DemiCond_gdi', normal);
@include font-face($font-name-jenna-sue, 'jennasue-webfont', normal);
@include font-face($font-family-league-gothic, 'leaguegothic-regular-webfont', normal);
@include font-face($font-family-fontawesome, 'fontawesome-webfont', normal);*/
@include font-face($font-family-ionicons, 'ionicons', normal);
@include font-face($font-family-stateface-regular, 'StateFace-Regular-webfont', normal);
@include font-face($font-family-primary-light, 'MuseoSans-300', normal);
@include font-face($font-family-primary, 'MuseoSans-500', normal);
@include font-face($font-family-primary-medium, 'MuseoSans-700', normal);
@include font-face($font-family-primary-bold, 'MuseoSans-900', normal);

