.error {
    background-color: #78aabe;

    header {
        background-color: #5695ae;
        padding-top: 75px;
        text-align: center;

        .row {
            max-width: 75rem;
            margin-left: auto;
            margin-right: auto;

            &:before, &:after {
                content: ' ';
                display: table;
            }

            &:after {
                clear: both;
            }

            @media screen and (max-width: 768px) {
                .large-2 {
                    display: none;
                    height: 0px;
                    width: 0px;
                }

                .large-8 {
                    img {
                        vertical-align: bottom;
                    }
                }

                .large-8 .content-well .row {
                    .large-12 {
                        width: 100%;
                    }

                    .large-2 {
                        display: none;
                        height: 0px;
                        width: 0px;
                    }

                    .large-8 {
                        width: 100%;
                    }
                }
            }

            @media screen and (min-width: $bp-desktop) {
                .large-4 {
                    width: 33.33333%;
                }

                .large-2 {
                    width: 16.666667%;
                }

                .large-8 {
                    width: 66.666667%;

                    img {
                        vertical-align: bottom;
                    }
                }

                .column, .columns {
                    padding-left: 0.9375rem;
                    padding-right: 0.9375rem;
                    float: left;
                }
            }

            @media screen and (min-width: 768px) {
                .column, .columns {
                    padding-left: 0.9375rem;
                    padding-right: 0.9375rem;
                }
            }
        }

        @media screen and (max-width: $bp-mobile) {
            .top-hands {
                display: none;
                height: 0px;
                width: 0px;
            }
        }
    }

    section {
        padding-top: 0;
        text-align: center;

        .row {
            max-width: 75rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0px !important;

            &:before, &:after {
                content: ' ';
                display: table;
            }

            &:after {
                clear: both;
            }

            @media screen and (max-width: 768px) {
                .large-2 {
                    display: none;
                    height: 0px;
                    width: 0px;
                }

                .large-8 {
                    width: 100%;

                    .content-well .row {
                        .large-12 {
                            width: 100%;
                        }

                        .large-2 {
                            display: none;
                            height: 0px;
                            width: 0px;
                        }

                        .large-8 .row {
                            .error-search .site-search {
                                .site-search-container {
                                    width: 75%;
                                    float: left;
                                }

                                .button-search-submit {
                                    width: 20%;
                                    height: 32px;
                                    float: left;
                                    padding: 0;

                                    &:before {
                                        @include font-ionicons($ionicon-var-android-search);
                                        display: inline-block;
                                        transform: rotateY(-180deg);
                                        font-size: 1.87rem !important;
                                        line-height: 0;
                                    }

                                    &:after {
                                        display: none !important;
                                        width: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media screen and (min-width: $bp-desktop) {
                .large-4 {
                    width: 33.33333%;

                    a {
                        line-height: inherit;
                        cursor: pointer;
                    }
                }

                .large-2 {
                    width: 16.666667%;
                }

                .large-8 {
                    width: 66.666667%;

                    .content-well {
                        padding: 1em;

                        .row {
                            .large-12 {
                                width: 100%;
                            }
                        }
                    }

                    .error-search {
                        top: 50%;
                        left: 50%;
                        margin: 0 auto;

                        .home-link {
                            text-align: center;
                            margin: 1em;
                        }

                        .site-search {
                            vertical-align: top;
                            display: inline-block;
                            position: relative;
                            transition: padding-top $transition-timing-primary $transition-timing-primary;
                        }

                        .site-search-container {
                            right: calc( 100% + #{3em});
                            display: inline-block;
                            vertical-align: top;
                        }

                        .site-search-input-container {
                            display: inline-block;
                        }

                        .site-search-input {
                            width: 100%;
                            display: inline-block;
                            padding: .5em 1em;
                            height: 35px;
                            width: 25em;
                            background: $color-white !important;

                            &:focus {
                                background: $color-white !important;
                            }
                        }

                        .button-search-submit {
                            display: inline-block;
                            height: 35px;
                            width: 3em;
                            margin-bottom: 0px;
                            margin-left: -4px;
                            font-size: .850em;
                            padding: .25em 0;

                            &:before {
                                @include font-ionicons($ionicon-var-android-search);
                                display: inline-block;
                                transform: rotateY(-180deg);
                                font-size: 1.87rem !important; /*px-to-em(30px)!important;*/
                                line-height: 0;
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .column, .columns {
                    padding-left: 0.9375rem;
                    padding-right: 0.9375rem;
                    float: left;
                }
            }

            .column, .columns {
                padding-left: 0.9375rem;
                padding-right: 0.9375rem;
                float: left;
            }
        }

        .content-well {
            background-color: #fff;
            padding: 1em;
        }

        @media screen and (max-width: $bp-mobile) {
            .cla-guy {
                display: none;
                height: 0px;
                width: 0px;
            }
        }
    }
}

.directory.error {
    margin-top: 1em;
    border: 1px solid $color-red-fire-engine-red;
    background-color: $color-white;
    color: $color-red-fire-engine-red;
    padding: 1em;
    font-weight: bold;

    &::before {
        content: 'Errors:';
        font-weight: bold !important;
        display: block;
    }
}
