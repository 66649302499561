.cta {
    font-size: 1.40em;
    @include font-primary-medium-bold;
    text-align: center;
}

.cta-img {
    position: relative;
    margin-bottom: 1em;
    width: 100%;

    div {
        position: absolute;
        top: 25%;
        width: 40%;
        padding-left: 5%;

        p {
            color: $color-white;
            @include font-primary-medium-bold;
            font-size: 1.063em;
        }

        .button {
            font-size: .9em;
            padding: 0 65px 0 15px;

            &:after {
                width: 40px;
            }
        }
    }
}

@include bp-min(552px) {
    .cta-img-sm {
        position: relative;
        margin-bottom: 1em;
        width: 100%;

        div {
            position: absolute;
            top: 20%;
            width: 60%;
            padding-left: 8%;

            p {
                color: $color-white;
                @include font-primary-medium-bold;
                font-size: .9em;
            }

            .button {
                font-size: .75em;
                height: 30px;
                line-height: 30px;
                padding: 0 15px;

                &:after {
                    display: none;
                }
            }
        }
    }
}

@include bp-max(768px) {
    .cta-img-sm {
        position: relative;
        margin-bottom: 1em;
        width: 100%;

        div {
            position: absolute;
            top: 25%;
            width: 30%;
            padding-left: 4%;

            p {
                color: $color-white;
                font-weight: bold;
                font-size: 1em;
            }

            .button {
            }
        }
    }
}

@include bp-max(769px) {
    .cta-img-sm div {
        width: 40%;
        padding-left: 6%;
    }
}

@include bp-max($bp-tablet) {
    .cta-img, .cta-img-sm {
        margin-bottom: 1em;
        max-width: 400px;
        height: 258px;
        background-color: $color-green-olive;

        img {
            display: none;
        }

        div {
            width: 100%;
            padding: 1.25em;

            p {
                color: $color-white;
                font-weight: bold;
                font-size: 1.25em;
            }
        }
    }
}
