.mega-menu {
    --mega-menu__header-link-font-size: 20px;
    --mega-menu__link-font-size: 16px;

    &__header-link {
        @include fonts__sans-serif-medium;
        font-size: var(--mega-menu__header-link-font-size);
        line-height: 1.2;
        color: $colors__blue;

        &:hover {
            text-decoration: underline;
        }
    }

    &__link-container {
        &:before {
            content: none;  // override legacy styles
        }
    }

    &__links {
        display: block; // override legacy styles
        width: 100%;    // override legacy styles
    }

    &__link-container {
        display: block; // override legacy styles
        margin-bottom: 0; // override legacy styles
    }

    &__link {
        @include fonts__sans-serif-medium;
        font-size: var(--mega-menu__link-font-size);
        line-height: 1.2;
        color: $colors__blue;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media('<global-header__desktop') {
        &__grid {
            padding: 0;
        }

        &__tab-controls,
        &__featured {
            display: none;
        }

        &__tab {
            display: block !important; // override v-show in mobile
        }

        &__header-link,
        &__link {
            display: block;
            border-bottom: var(--global-header__border-style);
        }

        &__header-link {
            padding: 11px $spacing__gutter-width;
        }

        &__link {
            padding: 14px $spacing__gutter-width;
        }
    }

    @include media('>=global-header__desktop') {
        --mega-menu__header-link-font-size: 22px;
        --mega-menu__link-font-size: 18px;

        background-color: $colors__gray--f7;
        overflow: hidden;

        &__grid {
            display: flex;
            justify-content: space-between;
        }

        &__tab-controls {
            width: calc(25% - #{$spacing__gutter-width});
            padding-top: 24px;
        }

        &__tab-control-container {
            &:before {
                content: none;  // override legacy styles
            }
        }

        &__tab-control {
            width: 100%;
            text-align: left;
            padding: 10px;
            @include fonts__sans-serif-medium;
            font-size: 18px;
            line-height: 1.25;
            color: $colors__blue;
            border-left: 6px solid rgba($colors__teal, 0);
            @include transition(border-color);
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + #{$spacing__gutter-width * 2});
                height: 100%;
                background-color: $colors__gray--ee;
                z-index: -1;
            }

            &:hover,
            &--active {
                border-color: $colors__teal;
                cursor: default;
            }
        }

        &__tabs {
            width: calc(75% - #{$spacing__gutter-width});
        }

        &__tab {
            display: flex;
            justify-content: space-between;
            background-color: $colors__gray--ee;
            position: relative;
            padding: 24px 0 24px 16px;

            &:after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                height: 100%;
                width: 50vw;
                background-color: $colors__gray--ee;
            }
        }

        &__header-link {
            @include fonts__sans-serif-bold;
        }

        &__links-container {
            width: calc(66.66% - #{$spacing__gutter-width});
            text-align: left;
        }

        &__links {
            margin-top: 24px;

            &--columns {
                columns: 2;
            }
        }

        &__link-container {
            margin: 12px 0;
            display: inline-block;
            width: 100%;
        }

        &__featured {
            width: calc(33.33% - #{$spacing__gutter-width});
            text-align: left;
        }

        &__featured-image {
            width: 100%;
        }

        &__featured-description {
            @include fonts__sans-serif-light;
            font-size: 16px;
            line-height: 1.5;
            color: $colors__gray--33;
            margin-top: 12px;
        }

        &__featured-cta {
            margin-top: 8px;
        }
    }
}
