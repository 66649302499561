table {
    // background: $color-gray-white;
    background-color: $color-light-grey;
    margin-bottom: 1.25em;
    width: 100%;

    thead, tfoot {
        @include font-primary-medium;
        // background: $color-orange-rich-gold;
        background-color: $color-orange-light;
        // font-weight: bold;
        font-size: 1.125em;
    }

    thead tr th,
    thead tr td,
    tfoot tr th,
    tfoot tr td {
        letter-spacing: 1px;
        // padding: 0.5em 0.625em 0.625em;
        padding: .875em 1.5em;
        // color: $color-white;
        color: $color-very-dark-grey;
        text-align: left;
        border: 2px solid $color-white;
    }

    tbody tr {
        border-left: 2px solid $color-white;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
    }

    thead tr th.color-two {
        background-color: $color-whiskey-sour-orange;
    }

    thead tr th.color-three {
        background-color: $color-christine-orange;
    }

    thead tr th.color-four {
        background-color: $color-burnt-umber-brown;
    }

    tr th, tr td {
        padding: 0.5625em 0.625em;
        color: $color-brown-spice;
    }

    tr td {
        font-size: 16px;
    }

    tr th {
        font-size: .90em;
    }

    tr td p, tr td ul li, tr td ol li, tr td a {
        font-size: 16px;
    }

    tr.even, tr.alt, tr:nth-of-type(even) {
        // background: $color-grey-very-light-grey;
        background-color: $color-lighter-grey;
    }

    thead tr th,
    tfoot tr th,
    tbody tr td,
    tr td,
    tfoot tr td {
        display: table-cell;
        line-height: 1.125em;
    }
    /*thead tr th {text-transform:capitalize; }*/
}

tbody tr td {
    text-align: left;
}
