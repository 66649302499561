.badge-component {
    background-color: $colors__gray--f7;
    padding-top: 48px;
    text-align: center;

    &--dark {
        background-color: $colors__gray--de;
    }

    &--carousel {
        @include spacing__component-y-margin;
        padding: 48px 0;
    }

    &__intro {
        color: $colors__gray--33;
        margin-bottom: 24px;

        > ul {
            display: inline-block;
            text-align: left;
        }

        &__align-left {
            text-align: left;
        }

        &__align-right {
            text-align: right;
        }

        &__align-center {
            text-align: center;
        }
    }

    &__image {
        width: 80%;
        display: block;
        margin: 0 auto 16px;
        max-width: 162px;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        justify-content: center;
        padding-left: 0px;
    }

    &__grid-item {
        flex: 1 1 100%;
        list-style-type: none;
        margin: 15px;
    }

    &__grid-image {
        width: 80%;
        display: block;
        margin: 0 auto;
        background: white;
        padding: 46.5px;
    }

    @include media('>=phone') {
        &__grid--three-column & {
            &__grid-item {
                flex: 0 1 calc(50% - 30px);
            }
        }
    }

    @include media('>=tablet-portrait') {
        padding-top: 64px;

        &--carousel {
            padding: 64px 0;
        }

        &__intro {
            font-size: px-to-em(18px);
        }

        &__grid--three-column & {
            &__grid-item {
                flex: 0 1 calc((100%/3) - 30px);
                margin: 40px auto;
            }
        }
    }
}
