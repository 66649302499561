.alert-banner {
    background-color: $colors__orange--saffron;
    color: $colors__blue;

    &__inner {
        position: relative;
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-right: 36px;
    }

    &__text {
        font-size: 1.125em;
    }

    &__link {
        color: $color-white;
        text-decoration: underline;

        &:hover {
            color: $color-white;
        }
    }

    &.hide-banner {
        display: none;
    }

    &__cta {
        @include fonts__sans-serif-bold;
        margin: 0 24px;
        display: block;
    }

    &__cta-icon {
        height: 12px;
        width: 14px;
        display: inline-block;
        margin-left: 8px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @include bp-min($bp-ipad) {
        &__cta {
            display: inline-block;
        }
    }
}

.alert-banner__close-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 16px;
    height: 16px;
    z-index: 10;
    display: block;

    svg {
        width: 100%;
        height: 100%;
    }
    
    button {
        display: block;
        width: 100%;
        height: 100%;
        &:focus {
            outline: thin dotted;
        }
    }
    
    @include bp-max($bp-ipad) {
        top: 18px;
        transform: translateY(0);
    }
}