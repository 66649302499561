.aside-header {
    background-color: $color-green-olive;
    padding: .5em;
    line-height: 1;

    h3 {
        color: $color-white;
        font-size: 1.05em;
        @include font-primary-light;
        margin: 0;
        line-height: 1.05;
    }

    div {
        float: right;
        padding-right: .1em;

        &:after {
            @include font-ionicons($ionicon-var-plus-round,15px);
        }
    }

    &.header-color-blue {
        background-color: $color-green-olive;
    }
    /*1/17/18 JAIME PULA NO LONGER WANTS BLUE TO BE AN OPTION*/
}

@media print {
    .aside-header {
        display: none !important;
    }
}
