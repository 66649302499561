﻿.checkbox-list {
    $spacing: 1em;
    padding: .5em;
    margin-bottom: $spacing;
    color: $color-brown-spice;
    border: 1px solid $color-brown-tide;
    border-radius: 7px;
    background: rgb(255,255,255); /* Old browsers */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 50%,rgba(212,212,212,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d4d4d4',GradientType=0 ); /* IE6-9 */
    .filter-label {
        line-height: $spacing;
        @include font-primary(16px);

        @include bp-max($bp-desktop) {
            @include font-primary(24px);
        }
    }

    label:after {
        content: "";
    }

    .filters {
        .filter-label {
            font-weight: normal !important;

            &.label-offset {
                &:after {
                    position: relative;
                    /*right: -65px;*/
                    @include bp-max($bp-desktop) {
                        position: absolute;
                        right: 21px;
                    }
                }
            }
        }

        .filter-label,
        .subfilter-label {
            cursor: pointer;

            &:after {
                font-size: .8em;
                @include font-ionicons($ionicon-var-plus-round);
                float: right;
            }
        }

        .subfilter-label {
            &:after {
                font-size: .6em;
                margin-right: 2px;
            }
        }
    }

    .keyword-field {
        margin-top: $spacing;
        display: table;
    }

    .keyword-input-container {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
    }

    .keyword-input {
        font-size: px-to-em(14px);
        padding: 1em .5em;
        width: 100%;
        height: 45px;
    }

    .btn-container {
        display: table-cell;
        vertical-align: middle;
        color: $color-white;
        transform: rotateY(-180deg);
    }

    .filters {
        /*margin-top: $spacing;*/
        &.show-filters {
            .filter-label {
                &:after {
                    @include font-ionicons($ionicon-var-minus-round);
                }
            }
            /*.filter-selects{
                display: block;
            }*/
            ul {
                margin-top: 1em;
            }
        }
        /*&:not(.show-filters){
            .filter-selects{
                display: none;
            }
        }*/
    }

    .subfilter {
        margin-top: .5em;

        .subfilter-selects {
            display: none;

            @include bp-max($bp-desktop) {
                padding: .5em;
            }
        }

        &.show-subfilters {
            .subfilter-label {
                &:after {
                    @include font-ionicons($ionicon-var-minus-round);
                }
            }
        }
    }

    .filter-selects {
        margin-top: $spacing/2;
        display: none;
        font-size: .9em;
        list-style: none;

        li {
            margin-bottom: 0;
            margin-left: 0px;

            @include bp-max($bp-desktop) {
                padding: .5em;
                //transform: scale(1.5);
                font-size: 1.50em;
                padding-left: 0em;
                margin-left: 10px;
            }
        }
    }

    display: block;

    @include bp-max($bp-desktop) {
        //display:none;
        .active {
            display: block;
        }
    }
}

@media screen and (max-width: 61.875em) {
    .checkbox-list .filter-label {
        font-size: 1em !important;
    }
}
