::-moz-selection {
    background: #2180BC;
    color: #fff
}

::selection {
    background: #2180BC;
    color: #fff
}

html {
    font-size: 100%;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    outline: none;
}

body {
    line-height: 1.5;
    @include font-primary-light;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    background-color: #fff;
}

.body {
    background: #fff;
}

.careers .body {
    background-color: #fff;
}

embed {
    height: 100%;
    max-width: 100%;
    height: auto;
}

input {
    border: none;
    padding: 0;
}

/*improve select arrow styling on IE10 +*/
select::-ms-expand {
    background: none;
    background-color: none;
    border: 0px;
}

button {
    background: none;
    border: none;
    padding: 0;

    // &:focus {
    //     outline: none;
    // }
}

b, strong {
    font-weight: bold;
}

figure {
    margin: 0;
}

em {
    font-style: italic;
}

img, audio {
    max-width: 100%;
}

audio {
    width: 100%;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    margin-bottom: 1em;
}

.hide {
    display: none !important;
}

/*footer  {margin-top:60px;}*/

@media print {
    * {
        background: transparent !important;
        color: black !important;
        /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    .content-main.home, .content-main {
        width: 100% !important;
    }
}

/* remove blue highlight in IE */
select {
    &:focus::-ms-value {
        background-color: transparent;
        color: #404041;
    }
}

.select-arrow {
    &:after {
        @include font-ionicons($ionicon-var-android-arrow-dropdown);
        position: relative;
        bottom: 30px;
        right: 6px;
        font-size: 1.25rem;
        display: block;
        text-align: right;
    }
}

.default__sprite {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
