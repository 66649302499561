﻿.section-nav {
    $triangle-size: px-to-em(8px);

    &.personalization-bar {
        margin-left: $triangle-size * $sqrt-2;
        padding: 0;

        .filters {
            padding: 0;
            margin-top: 0;
            width: 100%;
            background-color: $color-yellow-apricot-white;

            .filter-label {
                margin-bottom: 10px;

                &.no-children:after {
                    content: "";
                }

                a {
                    width: 90%;
                    display: inline-block;
                }
            }

            .filter-selects {
                margin-left: 0.5em;

                .filter-links {
                    color: $color-blue-denim;
                }
            }
        }
    }
}
