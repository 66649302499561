.insight-groups {
    --insight-groups__padding: 20px 24px;
    --insight-groups__header-size: 20px;
    --insight-groups__column-margin-top: #{$spacing__gutter-width};
    --insight-groups__item-margin-top: 24px;

    &__column {
        background-color: $colors__white;
        padding: var(--insight-groups__padding);
        border-radius: 4px;

        &:not(:first-child) {
            margin-top: var(--insight-groups__column-margin-top);
        }
    }

    &__header {
        @include fonts__sans-serif-bold;
        font-size: var(--insight-groups__header-size);
        line-height: 1.2;
        margin-bottom: 32px;
        text-transform: uppercase;
        color: $colors__blue;
    }

    &__items {
        list-style-type: none;
        margin-bottom: 0;   //override existing styles
    }

    &__item {
        &:not(:first-child) {
            margin-top: var(--insight-groups__item-margin-top);
        }
    }

    &__cta {
        margin-top: 32px;
    }

    @include media('>=tablet-portrait') {
        --insight-groups__column-width: calc(50% - #{$spacing__gutter-width / 2});

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
            content: '';
            width: var(--insight-groups__column-width);
        }

        &__column {
            width: var(--insight-groups__column-width);

            &:nth-child(2) {
                margin-top: 0;
            }

        }
    }

    @include media('>=tablet-landscape') {
        --insight-groups__column-margin-top: 0;
        --insight-groups__padding: 24px 32px;
        --insight-groups__header-size: 22px;
        --insight-groups__item-margin-top: 32px;
        --insight-groups__column-width: calc(33.33% - #{$spacing__gutter-width * 2 / 3});
    }
}
