.social-carousel {
    --featured-content__content-padding: 48px 0;
    --subhead: 24px;
    
    background-color: $colors__white;
    padding: var(--featured-content__content-padding);
    text-align: center;

    &--on-detail {
        background-color: transparent;
        padding-top: 0;
    }

    &__heading {
        margin-bottom: 24px;
    }
    &__title {
        @include fonts__sans-serif-medium;
        font-size: var(--subhead);
        line-height: 1.15;
        color: $colors__blue;
    }

    &__item {
        color: $colors__blue;
        text-align: center;

        & + & {
            margin-top: 34px;
        }
    }

    &__image {
        width: 126px;
        height: 126px;
        display: block;
        margin: 0 auto 16px;

        &-container {
            border-radius: 100%;
            overflow: hidden;
            border: 4px solid transparent;
            margin: 0 auto 24px auto;
            height: 176px;
            width: 176px;
            position: relative;
            background-color: $colors__white;

            @include transition(border);

            &:hover {
                border: 4px solid $colors__teal;
            }

            .icon-copy-block__image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }        
    }

    @include media('>=tablet-portrait') {
        --featured-content__content-padding: 64px 0;

        &--on-detail {
            background-color: transparent;
            padding-top: 0;
        }

        &__heading {
            margin-bottom: 32px;
        }

        // $grid-gutter: 16px;
        // &__list {
        //     display: flex;
        //     align-items: flex-start;
        //     margin-left: $grid-gutter * -0.5;
        //     margin-right: $grid-gutter * -0.5;
        // }

        // &__item {
        //     width: calc(33.333% - #{$grid-gutter});
        //     margin: 0 $grid-gutter/2 $grid-gutter;

        //     & + & {
        //         margin-top: 0
        //     }
        // }
    }
}