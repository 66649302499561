.circle {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-bottom: 1.5em;
    text-align: center;
    padding-top: 27%;
    font-weight: bold;
    color: $color-white;

    &.bg-blue {
        background-color: $color-blue-prusian-blue;
    }

    &.bg-purple {
        background-color: $color-violet-jagger;
    }

    &.bg-orange {
        background-color: $color-orange-rich-gold;
    }

    &.bg-brown {
        background-color: $color-brown-spice;
    }

    &.bg-green {
        background-color: $color-green-olive;
    }

    &.bg-red {
        background-color: $color-brown-maroon;
    }
}

@include bp-max(768px) {
    .circle {
        color: $color-white;
        height: 50px;
        width: 50px;
        border: none;
        text-align: left;
        padding-left: 21px;
        padding-top: 12px;
    }
}
