.clienthq-sms_help, .clienthq-auth_help {
    /*text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 60px 0 0 60px;*/
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    background: linear-gradient(0deg, rgba(38, 42, 64, 0.65) 0%, rgba(38, 42, 64, 0.65) 100%), url(/-/media/clienthq/images/loginbg.png) lightgray -328.195px -113.517px / 165.641% no-repeat;
    /*.container {
        display: flex;
        justify-content: center;
    }

    iframe {
        aspect-ratio: 16 / 9;
    }*/

    .modal__container {
        max-width: none;
        margin: 0 5%;
        /*  iframe {
            aspect-ratio: 16 / 9;
        }
         @include media('>desktop-xlarge') {
            max-width: 1920px;
        }

        @include media('>tablet-portrait','<=tablet-landscape') {
            max-width: 768px;
        }

        @include media('<=phone') {
            max-width: 320px;
        }*/
    }

    .modal__close {

        svg {
            position: relative;
            height: 50%;
            width: 50%;
            color: #fff;
            top: 5%;
            align-self: center;
            justify-self: center;
            vertical-align: unset;
        }
    }

    &__content {
        height: 100vh;
        padding-top: 15%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-left: 30%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
    }

    &__form {
        display: flex;
        justify-content: center;

        &-info-btn {
            display: flex;
            width: 301px;
            padding: 20px;
            align-items: center;
            gap: 40px;
            border-radius: 4px;
            border: 0.5px solid #FFF;
            background: #FFF;

            &:hover,
            &:focus {
                border-radius: 4px;
                border: 1.5px solid white !important;
                cursor: pointer;
            }
        }

        &-info-btn.pdf-btn {
            gap: 73px;
        }

        &-info-text {
            color: #2E334E;
            font-family: MuseoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: #2E334E;
            }
        }

        &-btn-icon {
            height: 13px;
            width: 24px;
            color: $colors__white;
            align-self: center;
        }
    }

    & ~ * > * {
        //login sits in header, remove padding and height from content
        padding: 0 !important;
        min-height: 0 !important;
    }

    &__body-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        flex: 1 0 0;

        &_page-title-h1 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            /* H1 */
            font-family: MuseoSans;
            font-size: 46px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 23px;
            margin-bottom: 0px;
        }

        &_page-title-h4 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans-300;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0px;
            padding-bottom: 40px;
            width: 75%;
        }

        &_page-title-h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off; /* H3 */
            font-family: MuseoSans;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &_help-text {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 460px;
        }
    }

    @include media('>desktop-xlarge') {
        .clienthq-sms_help, .clienthq-auth_help {

            &__content {
                max-width: 1920px;
                padding-left: 800px;
            }

            &__body-content {

                &_page-title-h1 {
                    font-size: 75px;
                    padding-bottom: 43px;
                }

                &_page-title-h4 {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 0px;
                    padding-bottom: 80px;
                    width: 60%;
                }

                &_page-title-h3 {
                    font-size: 36px;
                    line-height: 56px;
                }

                &_help-text {
                    font-size: 24px;
                    padding-left: 460px;
                }
            }

            &__form {

                &-info-btn {
                    display: flex;
                    width: 601px;
                    padding: 40px;
                    gap: 72px;
                    border-radius: 8px;
                    margin-bottom: 20px;
                }

                &-info-btn.pdf-btn {
                    gap: 140px;
                }

                &-info-text {
                    font-size: 32px;

                    &:hover {
                        text-decoration: none;
                        color: #2E334E;
                    }
                }

                &-btn-icon {
                    height: 26px;
                    width: 48px;
                }
            }
        }
    }

    @include media('>=desktop', '<=desktop-xlarge') {
        background: linear-gradient(0deg, rgba(38, 42, 64, .65), rgba(38, 42, 64, .65)), url(/-/media/clienthq/images/loginbg.png) #d3d3d3 -328.195px -113.517px / 220.641% no-repeat;

        &__content {
        }
    }

    @include media('>tablet-landscape','<desktop') {
        background: linear-gradient(0deg, rgba(38, 42, 64, .65), rgba(38, 42, 64, .65)), url(/-/media/clienthq/images/loginbg.png) #d3d3d3 -295.195px 0px / 200% no-repeat;
        background-color: #2E334E !important;
        height: auto !important;

        &__content {
        }
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        background: linear-gradient(0deg,rgba(38,42,64,.65),rgba(38,42,64,.65)),url(/-/media/clienthq/images/loginbg.png) #d3d3d3 -325.195px 0/260% no-repeat;
        background-color: #2E334E !important;

        &__content {
            height: 80vh;
        }
    }

    @include media('>phone','<=tablet-portrait') {
        background: linear-gradient(0deg, rgba(38, 42, 64, .65), rgba(38, 42, 64, .65)), url(/-/media/clienthq/images/loginbg.png) #d3d3d3 -365.195px 0 / 355% no-repeat;
        background-color: #2E334E !important;

        &__content {
        }

        & .clienthq-footer {
        }
    }

    @include media('<=phone') {
        background: none !important;
        background-color: #2E334E !important;

        &__content {
            padding-left: 0px;
        }

        &__body-content_page-title-h1 {
            max-width: 250px;
            Font-size: 32px;
        }

        &__body-content_page-title-h4 {
            max-width: 290px;
            width: 100%;
        }

        &-info-btn {
            gap: 20px;
        }

        &-info-btn.pdf-btn {
            gap: 53px;
        }
    }

    @include media('<=phone-small') {
        height: 600px;

        &__content {
            padding-top: 50px;
            min-height: 650px;
        }

        &__body-content {
            max-width: 350px;
        }

        &__body-content_page-title-h1 {
            max-width: 250px;
            Font-size: 32px;
        }

        &__body-content_page-title-h4 {
            max-width: 250px;
            width: 100%;
        }

        &-info-btn {
            width: 260px;
            gap: 19px;
        }

        &-info-btn.pdf-btn {
            width: 2600px;
            gap: 53px;
        }
    }
}

@media print {
    html:has(.clienthq-login) {
        height: auto;
        width: auto;

        @page {
            size: A4 landscape;
        }
    }

    .clienthq-login {
        visibility: hidden;
        background: none;
        height: auto;


        & .show-for-print {
            visibility: visible;
            left: 0;
            top: 0;
        }

        &__infographic {
            height: auto;

            &-switch {
                display: none;
            }

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-print {
                width: 1440px;
                position: absolute;
            }
        }
    }

    .ecEmbedWrapper {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    .proactive-animation-tooltip {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    #ecLauncherFloatingBubble {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}
// chat overrides
#ecLauncherFloatingBubble {
    background-color: #76D2D3 !important;
}

#ecLauncherFloatingBubble:focus {
    outline: 2px solid #fff !important;
    background-color: #76D2D3 !important;
}

.mini-chat-footer-btn {
    background: #76D2D3 !important;
}

.header-svg-wave {
    background-color: #76D2D3 !important;
}

.header-rectangle.home-page {
    background-color: #76D2D3 !important;
}
