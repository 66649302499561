.twitter-feed {
    //taken from $triangle-size defined in other file
    /*margin-left:  px-to-em(11.2px);*/
    margin-top: 1em;

    @media print {
        display: none !important;
        width: 0px !important;
    }

    @include bp-max($bp-desktop) {
        // display: none;
    }

    .twitter-widget {
        .twitter-timeline {
            max-height: 250px;
        }
    }
}
