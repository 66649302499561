.careers-testimonials {
    $flag-size: 12px;

    .section-header {
        background-color: $color-blue-riptide;
        color: $color-dark-blue-footer;
        @include font-primary-medium-bold(18px);
        padding-top: .25em;
        padding-left: .5em;
        position: relative;
    }

    .testimonials-carousel {
        $radius: 5px;
        background-color: $color-lighter-grey;
        color: $color-dark-blue-footer;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        padding: .75em;
        position: relative;
    }

    .testimonial-image-caption,
    .testimonial-content {
        color: $color-dark-blue-footer !important;
        @include font-primary(14px);
        line-height: 1.25;

        ul {
            color: $color-dark-blue-footer !important;

            li {
                color: $color-dark-blue-footer !important;
            }
        }
    }

    .testimonial-author {
        margin-top: .5em;
        @include font-primary-bold;
    }

    .testimonial-content {
        margin-top: 1.5em;
    }

    .slick-dots {
        margin-top: .5em;

        li {
            display: inline-block;

            &:last-of-type {
                button {
                    margin-right: 0;
                }
            }

            &.slick-active {

                button {
                    background-color: $color-dark-blue-footer;
                    border: $color-dark-blue-footer;
                }
            }
        }

        button {
            $size: 6px;
            text-indent: -9999px;
            padding: 0px;
            width: $size;
            height: $size;
            border-radius: $size;
            margin-right: .75em;
            background-color: $color-grey-border-hq;
            border-color: $color-grey-border-hq;
            transition: all transition-timing-primary;
            box-shadow: none;

            &:after {
                width: 0px !important;
                display: none;
            }
        }
    }

    .slick-next {
        @include font-primary-medium;
        position: absolute;
        top: 1em;
        right: 1em;
        padding: .25em 1em;
        background-color: $color-blue-riptide;
        border-color: $color-blue-riptide;
        color: $color-dark-blue-footer;
        width: inherit;

        &:hover {
            background-color: $color-teal-riptide !important;
        }

        &:after {
            display: none;
            width: 0px;
        }
    }

    @include bp-max($bp-ipad) {
        .section-header {
            &:after {
                content: none;
            }
        }

        .testimonials-carousel {
            margin-left: 0;
        }

        .testimonial-image-caption,
        .testimonial-content {
            font-size: 1em;
        }
    }
}

@media print {
    .careers-testimonials, .intro-testimonials {
        display: block !important;
        width: 0px !important;
        height: 0px !important;

        button {
            display: none !important;
        }

        .section-header {
            display: none !important;
        }
    }

    .careers-testimonials .section-header:after {
        border: none !important;
        display: none !important;
    }
}
