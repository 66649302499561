.inside-content {
    $spacing: 1em;

    @include container;

    .content-aside {
        @include span(2.25 of 8);
        margin-top: 2em;
    }

    .content-main {
        @include span(5.75 of 8 last);
    }

    .personalization-bar-home,
    .personalization-bar-mobile,
    .aside-optional-content,
    .ad-placeholder {
        margin-top: $spacing
    }
}

@include bp-max($bp-desktop) {
    .inside-content .content-main {
        display: block;
        width: 100%;
    }

    .inside-content .content-aside {
        display: block;
        width: 100%;

        .aside-header, .aside-optional-content,
        .ad-placeholder {
            display: none;
        }
    }
}
