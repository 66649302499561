.office-container {
    border-bottom: 1px solid $color-gray-light-grey;
    padding-bottom: 1em;
    margin-bottom: 1em;
    @include container;

    .office-information {
        @include span(4);
    }

    .map {
        @include span(8 last);
        overflow: hidden;

        iframe {
            width: 100% !important;
        }
    }
}

@include bp-max($bp-tablet) {
    .office-container .office-information, .office-container .map {
        width: 100%;
        display: block;

        iframe {
            width: 100% !important;
            height: 100%;
        }
    }

    .office-container .office-information {
    }

    .office-container .map {
    }

    .button {
        width: 100%;
    }
}

@media print {
    .office-container img {
        display: none;
    }
}
