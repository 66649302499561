.clientHq {
    background-color: #F4F4F4;

    &-content {
        margin: 0 auto;
        max-width: 1100px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: none;

        @include media('>global-header__desktop')  {
            padding: 0 .8em;
        }
        &__main-wrapper {
            flex: 1 1 100%;
            padding: 15px;
            
            @include media('>global-header__desktop') {
                flex: 1 1 75%;
                padding: 0 30px 0 0;
            }
        }

        &__main {
            min-width: 300px;
            margin: 0;
            width: 100%;
            // padding-top: 4em;

            @include media('>global-header__desktop') {
                width: 100%;
            }
            
        }

        &__bottom {
            min-height: none;
            min-width: 300px;
            margin: 0;
            padding: 1rem 0 2em 0;
            width: 100%;
            // display: flex;
            // flex-direction: column;
        }

        &__sidebar {
            flex: 1 1 100%;
            min-height: none;
            // margin-left: 0.5rem;
            // margin-right: 0.5rem;
            padding: 0 15px 2em;
            // width: 25%;
            @include media('>global-header__desktop') {
                flex: 0 1 25%;
                padding: 0;
            }

        }
    }

    
    @include media('>global-header__desktop') {
        &-content {
            flex-direction: row;
            max-height: unset;

            &__main, &__bottom {
                width: 100%;
            }

            &__main {
                order: 1;
                // padding-right: 1em;
            }

            &__bottom {
                order: 3;
                // flex-direction: column-reverse;
            }

            &__sidebar {
                width: 100%;
                padding: 0;
                order: 2;
            }
        }
    }

    @include bp-max($bp-ipad) {
        &-content {

            & > * {
                margin: 0;
            }

            &__main, &__sidebar {
                // padding: 1rem 0;
            }
        }
    }
}
