.mobile-personalization-control {
    display: none;
    height: 40px;

    @include bp-max($bp-desktop) {
        display: block;
        background-color: $color-orange-rich-gold;
        width: 100%;
        z-index: 1;
    }

    .filter-page-label {
        padding: .32em 1em;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        font-size: 1.2em;
    }

    .filter-page-expand-control, .filter-page-expand-control-mobile {
        float: right;
        background-color: $color-orange-santa-fe;

        &:after {
            font-size: 1em;
            color: #fff;
            @include font-ionicons($ionicon-var-plus-round);
            padding: .5em 1em;
            content: "";
            font-family: "Ionicons";
            display: inline-block;
            cursor: pointer;
        }

        &.expanded:after {
            @include font-ionicons($ionicon-var-minus-round);
        }
    }

    @include bp-max($bp-mobile) {
        .filter-page-label {
        }
    }
}

.mobile-personalization-control.fixed {
    box-shadow: 0 8px 6px -6px $color-brown-cocao-brown;
}

@include bp-min(991px) {
    .personalization-placeholder {
        display: none !important;
    }
}

@include bp-max(990px) {
    .personalization-placeholder {
        height: 40px !important;
    }
}
