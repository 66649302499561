//page container is 71% of 62.5 max-width
// $desktop-tile-width : 62.5rem * 0.71 * 0.5;
$original-desktop-tile-height: 221px;
$desktop-tile-width: $original-desktop-tile-height;
// $desktop-tile-height: $desktop-tile-width * $image-ratio;
$desktop-tile-height: $original-desktop-tile-height;

@mixin featured-item-with-image {
    $meta-padding-top: .25em;
    $meta-padding-left: 1em;

    .resource-meta {
        background: linear-gradient(to bottom, rgba(25,19,15,0.9) 0%,rgba(49,38,29,0.6) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e619130f', endColorstr='#9931261d',GradientType=0 );
        padding: $meta-padding-top $meta-padding-left $meta-padding-top*3;
        width: 100%;
    }

    .resource-category,
    .resource-tag {
        color: $color-white;
    }

    .resource-title h1 {
        @include font-primary-bold(18px);
        line-height: 1;
        color: $color-white;
        padding-bottom: 0px;
        margin-bottom: 0px;

        &:hover {
            text-decoration: underline
        }
    }
}

@mixin featured-item-no-image {
    $meta-padding-top: .25em;
    $meta-padding-left: 1em;

    color: $color-white;
    position: relative;

    .resource-item-link {
        color: $color-white;
        display: flex;
        align-items: center;
        height: 100%
    }

    .resource-title {
        display: flex;
        align-self: center;
    }

    .resource-title h1 {
        $font-size: 28px;
        margin-bottom: 0px;
        padding: 0px px-to-em(16px, $font-size) 0px px-to-em(16px, $font-size);
        @include font-primary-bold($font-size);
        line-height: 1;
        color: $color-white;
        width: 100%;

        a {
            color: $color-white;
        }
    }

    .resource-info {
        position: absolute;
        top: 0;
        left: 0;
        padding: .75em $meta-padding-left;
        width: 100%;
        line-height: 1;
    }

    @include bp-max(900px) {
        .resource-title h1 {
            margin: 0;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.featured-resources {
    //improves initial page load behavior but still flickers all on 'view more'
    .resource-row {
        animation: fadein 1.5s;
    }

    .resource-row {
        display: table;
        table-layout: fixed;
        width: 100%;
        //IE-only width issue
        .resource-meta {
            width: 100%;
        }
        // For every odd row
        //
        // On the first item,  position the resource meta data
        // over the image
        //
        // For the last item, hide the image
        &:nth-of-type(odd) {
            .resource-item {
                &:first-of-type {
                    position: relative;

                    .resource-meta {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    @include featured-item-with-image;
                }

                &:last-of-type {
                    background-color: $color-orange-rich-gold;
                    // used to move the background image out of the viewing box so it is
                    // not visible and allow the background-color to shine through
                    background-position: 1000px 1000px !important;

                    .resource-image-container {
                        display: none;
                    }

                    @include featured-item-no-image;
                }

                &:only-child {
                    background-color: transparent;
                    background-position: 0;
                    @include featured-item-with-image;

                    .resource-info {
                        position: static;
                        padding: 0;
                        line-height: inherit;
                    }

                    .resource-title h1 {
                        padding-left: 0;
                        line-height: 1;

                        @include bp-max(900px) {
                            padding-top: 0;
                            margin-bottom: 0px;
                        }
                    }

                    .resource-image-container {
                        display: block;
                    }
                }
            }
        }
        // For every even row
        //
        // For the first item, hide the image
        //
        // On the last item,  position the resource meta data
        // over the image
        &:nth-of-type(even) {
            .resource-item {
                &:first-of-type {
                    background-color: $color-violet-jagger;
                    // used to move the background image out of the viewing box so it is
                    // not visible and allow the background-color to shine through
                    background-position: 1000px 1000px;

                    .resource-image-container {
                        display: none;
                    }

                    @include featured-item-no-image;
                }

                &:last-of-type {
                    position: relative;

                    .resource-meta {
                        background: none;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    .resource-image-container {
                        display: none;
                    }

                    @include featured-item-with-image;
                }

                &:only-child {
                    //we want these special odd @ the end cases to display over the whole color, not hang out at the bottom
                    @include featured-item-no-image;

                    .resource-meta {
                        position: static;
                        background-color: transparent;
                        background: none;

                        .resource-tag, .resource-category {
                            color: $color-white;

                            a {
                                color: $color-white;
                            }
                        }
                    }

                    .resource-title h1 {
                        padding-left: 0;
                    }

                    .resource-image-container {
                        display: none;
                    }
                }
            }
        }
        // Last Item Of Grid - show image and position content
        // above image
        &:last-of-type {
            .resource-item {
                &:last-of-type {
                    position: relative;
                    background-size: cover;
                    background-position: 50% 50%;


                    .resource-content {
                        position: absolute;
                        top: 50%;
                        left: 2em;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    .resource-item {
        display: table-cell;
        width: 50%;
        min-height: $desktop-tile-width;
        overflow: hidden;
        height: $desktop-tile-height;
        vertical-align: middle;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-bottom: 1em;
        // >desktop, fixed size
        @media (min-width: $bp-desktop) {
            .resource-image-container, .resource-item-richtext, .resource-item-richtext {
                height: $desktop-tile-height;
            }
        }
        //two columns of fixed-ratio tiles, full width
        @media (max-width: $bp-desktop) {
            height: 100vw * ( $image-ratio / 2 ) - 1vw;

            .resource-image-container, .resource-item-richtext {
                height: ( 100vw * ( $image-ratio / 2 ) ) - 1vw;
            }
        }
        //one column
        @media (max-width: 575px) {
            height: ( 100vw * $image-ratio ) - 3vw;
            min-height: 0;
            display: block;
            width: 100%;

            .resource-image-container, .resource-item-richtext {
                height: ( 100vw * $image-ratio ) - 3vw;
                min-height: 0;
                width: 100%;
            }
        }
        //force inline styles out of rich text highlight images... except for those given 'unformatted'
        .resource-item-richtext {
            box-sizing: border-box;

            img:not(.unformatted) {
                max-width: 100% !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }

    .resource-category,
    .resource-tag {
        display: inline-block;
        text-transform: uppercase;
        font-size: px-to-em(12px);
    }

    .resource-category:not(:last-child) {
        &:after {
            content: '|';
            margin: 0 .5em;
        }
    }

    .resource-tag {
        margin-left: -.25em;
    }

    .resource-content {
        .prompt-line {
            display: block;
            line-height: .75;
            @include font-primary-bold(25px);

            &:nth-of-type(2) {
                color: $color-violet-jagger;
                /*@include font-jenna(60px);*/
                font-weight: normal;
            }
        }

        .button {
            font-size: 1em;
            margin: 0;
            margin-top: 1.5em;
        }
    }
}

@media print {
    .featured-resources {

        img {
            display: none !important;
            height: 0px !important;
            width: 0px !important;
        }

        figure {
            display: none !important;
            height: 0px !important;
            width: 0px !important;
        }

        .resource-item {
            display: block !important;
            width: 100% !important;
            height: 50px !important;
            min-height: 0px !important;
            max-height: 25px !important;
            background-image: none !important;
            background-position: 0 0 !important;
            float: none !important;
            vertical-align: baseline !important;
            margin-bottom: 16px !important;
            padding: 0px !important;
            line-height: 1 !important;

            .resource-content {
                position: static !important;
                top: 0 !important;
                left: 0 !important;
                transform: none !important;
            }

            h1 {
                font-size: 1.125em !important;
                line-height: 1 !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }

            .resource-meta {
                background-color: none !important;
                padding: 0px !important;

                .resource-info {
                    display: none !important;
                    height: 0px !important;
                    width: 0px !important;
                    padding: 0px !important;
                }

                .resource-title h1 {
                    padding: 0px !important;
                    margin: 0px !important;
                }
            }
        }

        .resource-image-container, .resource-info,
        .button {
            display: none !important;
            width: 0px !important;
            height: 0px !important;
        }
    }

    .featured-resources .resource-row:last-of-type .resource-item:last-of-type {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    .featured-resources .resource-row:nth-of-type(even) .resource-item:last-of-type .resource-meta,
    .featured-resources .resource-row:nth-of-type(odd) .resource-item:first-of-type .resource-meta {
        padding: 0px !important;
        margin: 0px !important;
        position: initial !important;
    }

    .resource-row:nth-of-type(odd) .resource-item:last-of-type .resource-title h1, .resource-row:nth-of-type(even) .resource-item:last-of-type .resource-title h1 {
        font-size: 1.125em !important;
        line-height: 1 !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.featured-resources .resource-row:last-of-type .resource-item:only-child {
    background-position: 1000px 1000px !important;
    //we don't want the last one to span full width after all
    display: block;
}
