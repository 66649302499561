.breadcrumbs {
    background-color: $colors__gray--f7;

    &__wrapper {
        padding-top: 16px;
    }

    &__list {
        margin-bottom: 0;

        &#{&} {
            list-style-type: none;
        }
    }

    &__list-item {
        display: inline-block;

        &::after {
            content: '/';
            margin: 0 7px;
            font-size: 12px;
            line-height: 1.1;
        }

        &:last-child::after {
            display: none;
        }
    }

    &__list-item-link {
        font-size: 12px;
        line-height: 1.1;
        color: $colors__blue;
        font-weight: 500;
    }
    /*Zendesk 39277 */
    @include media('<tablet-portrait') {
        display: none;
    }
}
