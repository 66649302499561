.clienthq-preferences {
    &__container {
        &--headline {
            font-weight: 700 !important;
            font-size: 24px;
            color: $colors__blue;
            @include fonts__serif-regular;
        }

        &--message {
            padding: 15px 0;
            font-weight: 500;
            font-size: 18px;
        }

        &--save-error {
            color: orangered;
        }

        &--success {
            color: #39A5A7;
        }

        &__header {
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $colors__gray--c5;
            font-weight: 600;
            font-size: 18px;
            color: #565656;
            @include fonts__sans-serif-bold;
        }

        &__checkboxlist {
            padding-top: 10px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 280px;

            &-topics {
                height: 200px;
            }

            & li {
                list-style-type: none;
                display: flex;
                align-items: center;
                font-size: 14px;
                width: 50%;

                @include fonts__sans-serif-medium;

                & input[type="checkbox"] {
                    appearance: none;
                    -webkit-appearance: none;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    margin: 5px;
                    border: 0.2rem solid $colors__blue-alt;

                    &::before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        border-radius: 0;
                        clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
                        transition: 0.4s cubic-bezier(0.8, 0, 0.2, 1);
                        transition-property: background-color, clip-path, border-color;
                        transition-delay: 0.1s, 0s;
                    }

                    &:checked {
                        border: 0.2rem solid $colors__gray--ac;
                    }

                    &:checked::before {
                        background-color: green;
                        border-color: $colors__blue-alt;
                        transition-delay: 0s;
                        clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
                    }
                }

                & label {
                    color: $colors__blue-alt;
                    padding-left: 5px;
                }
            }
        }
    }

    & a {
        text-decoration: none;
    }
}
