.filter-area {
    --gutter: 15px;
    background-color: $colors__white;

    &__flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        // margin: 0 calc(var(--gutter) * -1);
        padding-top: 1em;
        padding-bottom: 1em;
    }

    &__title-trigger {
        @include fonts__sans-serif-bold;
        font-size: 18px;
    }

    &__filter-toggles {
        margin: var(--gutter) 0;
        order: 3;
    }

    
    &__heading {
        flex-shrink: 0;
        max-width: 25%;
        padding-right: 10px;
        margin: var(--gutter) 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // padding-bottom: 28px;
        order: 1;

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;
        }
    }

    &__filter-dropdowns {
        width: 100%;
        order: 4;
    }

    &__filter-item {
        margin-bottom: 30px; 

        label {
            @include fonts__sans-serif-medium;
            font-size: 28px;
            color: $colors__blue;
        }
    }

    &__icon {
        width: 16px;
        height: 10px;
        transition: transform 0.25s;
        
        &.active {
            transform: rotateX(180deg);
        }
    }

    &__search {
        flex-grow: 1;
        max-width: 74.5%;
        width: 100%;
        margin: var(--gutter) 0;
        order: 2;
    }

    &__search-container {
        display: flex;
    }

    & &__search-input {
        height: 100%;
    }

    &__search-input {
        height: 37px;
        &#{&} {
            height: 37px;
        }
    }

    &__filter-toggle {
        & + & {
            margin-top: 1em;
        }
    }

    &__submit {
        background-color: $colors__blue;
        color: $colors__white;
        padding: 10px;
        height: 37px;

        &:focus {
            outline: thin dotted $colors__blue;
        }
    }

    @include media('>=tablet-portrait') {
        &__heading,
        &__search,
        &__filter-toggles,
        &__filter-dropdowns {
            order: initial;
        }
        &__heading {
            max-width: calc(((4/12) * 100%) - var(--gutter));
        }
        &__title {
            a {
                @include fonts__sans-serif-bold;
                font-size: 24px;
            }
        }
        &__search {
            max-width: calc(((4/12) * 100%) - var(--gutter));
            // order: initial;
            float: right;
        }

        &__filter-toggles {
            display: flex;
            flex-grow: 1;
            padding-left: 2em;
        }

        &__filter-toggle {
            & + & {
                margin-top: 0;
                margin-left: 1em;
            }
        }

        $drawer-gutter: 16px;
        &__drawer {
            display: flex;
            margin-left: $drawer-gutter * -0.5;
            margin-right: $drawer-gutter * -0.5;
        }

        &__filter-item {
            width: calc(25% - #{$drawer-gutter});
            margin: 0 $drawer-gutter/2 $drawer-gutter; 
        }

    }
}