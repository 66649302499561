ul.searching-filter {
    color: $color-brown-spice;
    margin-bottom: 1em;

    &:before {
        content: "Filter: ";
        font-weight: bold;
        margin-right: 1em;
    }

    li {
        list-style: none;
        display: inline;
        margin-right: .50em;
        margin-left: 0px !important;

        &:after {
            content: " | ";
            color: $color-brown-spice;
            margin-left: .20em;
        }

        &:last-of-type:after {
            content: "";
        }

        a {
            color: $color-blue-shakespear;
        }
    }
}
