.anchor-links {
    display: none;

    @include media('>=tablet-portrait') {
        position: sticky;
        display: block;
    }

    &__title {
        @include fonts__sans-serif-light;
        font-size: 18px;
        line-height: 1.2;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 16px;
        color: $colors__blue;
    }

    &__list {
        &#{&} {
            list-style-type: none;
        }
    }

    &__list-item {
        margin-bottom: 8px;
    }

    &__list-item-link {
        @include fonts__sans-serif-medium;
        color: $colors__blue;
        font-size: 16px;
        line-height: 1.3;
    }

    @media print {
        display: none;
    }
}