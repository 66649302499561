.featured-media {
    --featured-media__content-padding: 24px 0 0;
    --featured-media__accent-width: 100%;
    --featured-media__accent-height: 16px;

    position: relative;

    @include spacing__component-y-margin;

    &__img-container {
        margin: 0 $spacing__gutter-width * -1;
        position: relative;
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding-bottom: 62.4%;
    }

    &__accent {
        width: var(--featured-media__accent-width);
        height: var(--featured-media__accent-height);
    }

    &__content {
        text-align: center;
        padding: var(--featured-media__content-padding);
    }

    &__play-button {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 3;
        color: $colors__white;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        pointer-events: none;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    @include media('>=tablet-portrait') {
        $featured-media__accent-extension: 24px;

        --featured-media__content-padding: 0;
        --featured-media__content-width: 45%;
        --featured-media__accent-width: calc(50vw + #{$featured-media__accent-extension});
        --featured-media__accent-height: 96px;
        --featured-media__accent-left: auto;
        --featured-media__accent-right: #{$featured-media__accent-extension * -1};

        &--align-right {
            --featured-media__flex-order: 1;
            --featured-media__accent-left: #{$featured-media__accent-extension * -1};
            --featured-media__accent-right: auto;
        }

        &__width-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__img-container {
            width: 50%;
            margin: 0;
            order: var(--featured-media__flex-order);
            position: relative;

            + .featured-media__content {
                width: var(--featured-media__content-width);
                text-align: left;
            }
        }

        &__img {
            position: relative;
            z-index: 1;
        }

        &__accent {
            position: absolute;
            height: var(--featured-media__accent-height);
            left: var(--featured-media__accent-left);
            right: var(--featured-media__accent-right);
            bottom: $featured-media__accent-extension * -1;
        }
    }

    @include media('>=tablet-landscape') {
        --featured-media__content-width: 40%;
        --featured-media__accent-height: 116px;
    }
}
