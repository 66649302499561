ul.vcard {
    li {
        list-style: none;
        margin-left: 0px;
        padding-left: 0px;
    }

    li.fn {
        font-weight: bold;
    }
}
