.latest-news {
    color: $color-brown-cocao-brown;
    margin-bottom: 50px;

    &__inner {
        display: flex;
        margin-bottom: 40px;
    }

    &__item {
        padding: 0 70px;
        flex: 1 1 0px;
        border-right: 1px solid $color-brown-cocao-brown;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border-right: none;
        }
    }

    &__category {
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__link {
        &:hover {
            text-decoration: none;
        }
    }

    &__title {
        font-size: 2em;
        color: $color-brown-cocao-brown;
        line-height: 38px;
        margin-bottom: 30px;
        @include font-primary-medium;

        &:hover {
            color: $color-blue-blue;
        }
    }

    &__abstract {
        font-size: 1.5em;
        line-height: 34px;
    }

    &__get-more-link {
        font-size: 1.25em;
        text-decoration: underline;
        color: $color-brown-cocao-brown;
        position: relative;
        padding-right: 35px;
        @include font-primary;

        &:after {
            font-family: $font-family-fontawesome;
            font-size: 22px;
            position: absolute;
            right: 0;
            content: '\f061';
            font-weight: normal !important;
        }

        &:hover {
            color: $color-blue-blue;
        }
    }

    @include bp-max($bp-desktop) {
        &__item {
            padding: 0 28px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__title {
            font-size: 1.375em;
            line-height: 27px;
            margin-bottom: 20px;
            color: $color-blue-blue;
        }

        &__abstract {
            font-size: 1.125em;
            line-height: 24px;
        }

        &__get-more-link {
            font-size: 1.125em;
            color: $color-blue-blue;
        }
    }

    @include bp-max($bp-ipad) {

        &__inner {
            flex-direction: column;
            margin-bottom: 0;
        }

        &__item {
            padding: 0 0 30px;
            margin-bottom: 30px;
            border-right: none;
            border-bottom: 1px solid $color-brown-cocao-brown;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }
        }

        &__title {
            font-size: 1.5em;
            line-height: 29px;
        }
    }
}
