.icon-ctas {
    --gutter-size: 30px;
    overflow: hidden;
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: $colors__gray--f7;

    &__container {
        display: flex;
        margin: calc((var(--gutter-size)/2) * -1);
        flex-wrap: wrap;
        list-style-type: none;

        

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;
        }
    }

    &__item {
        flex: 1 1 100%;
        margin: calc((var(--gutter-size)/2));
        text-align: center;
        
        @include media('>=tablet-portrait') {
            flex: 1 1 calc(100%/3);
        }
    }

    &__image-container {
        border-radius: 100%;
        overflow: hidden;
        border: 4px solid transparent;
        margin: 0 auto 24px auto;
        height: 126px;
        width: 126px;
        position: relative;
        background-color: $colors__white;

        @include transition(border);

        &:hover {
            border: 4px solid $colors__teal;
        }
    }

    &__image-link {
    }

    &__image {
        max-width: 126px;
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        max-width: 50px;
        transform: translate(-50%, -50%);
    }

    &__text {
        text-align: center;
    }

    &__text-link {
        @include fonts__sans-serif-bold;
        font-size: 16px;
        line-height: 1.2;
        color: $colors__blue;
    }

    &__text-icon {
        width: 14px;
        height: 11px;
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 22px;
        line-height: 1.23;
        text-transform: uppercase;
        margin: auto;
        text-align: center;
        margin-bottom: 32px;
    }
}