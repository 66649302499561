@import 'clienthq-app-container';
@import 'clienthq-app';
@import 'clienthq-call-to-action';
@import 'clienthq-header';
@import 'clienthq-login';
@import 'clienthq-logout';
@import 'clienthq-rail-promotion';
@import 'clienthq-resources';
@import 'clienthq-your-team';
@import 'clienthq-tabs';
@import 'clienthq-card';
@import 'client-hq-legacy';
@import 'clienthq-seamless-nav';
@import 'clienthq-footer';
@import 'clienthq-todos';
@import 'clienthq-infographic';
@import 'clienthq-preferences';
@import 'clienthq-be-help';
@import 'clienthq-help-options';

.clientHq {
    background-color: $colors__gray--f7;

    &__imagestyle {
        background-repeat: no-repeat;
        min-height: 950px;
        background-size: cover;
        background-attachment: fixed;
        background-color: #0a3f4e!important;
        background-position: -250px;
        background-position-y: 0px;
    }  
}
@include media('<tablet-middle','>=tablet-portrait') {
    .clientHq {
        &__imagestyle {
            background-position: -1000px;
        }
    }
}

@include media('<tablet-portrait') {
    .clientHq {
        &__imagestyle {
            background-position: -1000px;
        }
    }
}

@include media('>=tablet-landscape','<desktop')   {
    .clientHq {
        &__imagestyle {
            background-position: -500px;
        }
    }
}
