.search-input-container {
    position: relative;
}

.search-filters {
    margin-bottom: 1em;
}

.full-width {
    width: 100%;
}

.page-tab,
.personalization-bar-wrapper {
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
    transition: opacity .5s ease-in-out;

    &.active {
        opacity: 100;
        position: relative;
        top: 0;
        left: 0;
    }
}

.personalization-bar-wrapper.active {
    display: none!important; //temp fix for hiding legacy component
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide  {
    display: none !important;
}

.show-important {
    display: block !important;
}

.three-by-one-grid .left:last-child {
    float: right;
    margin-right: 0;
}

.full-width {
    width: 100% !important;
}

.full-width .interior-header {
    max-width: 100% !important;
}

.iframe-registration {
    width: 100%;
    overflow: hidden;
}

.iframe-registration iframe {
    width: 100%;
    border: none;
}

.global-header .button-search-submit {
    padding: 1px 0 !important;
}

.featured-resources img.resource-image {
    width: 100%;
}
