.cta-component {
    color: $colors__blue;

    &:not(:first-child) {
        @include spacing__component-y-margin;
    }

    &__container {
        background-color: $colors__teal--light;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        flex-wrap: wrap;

        @include media('>=tablet-portrait') {
            padding: 40px;
            flex-wrap: nowrap;
        }
    }

    &__two-col-area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .cta-component__title {
            flex: 1 1 100%;
        }
    }

    &__text-area--two-col {
        flex: 1 1 100%;
        padding-right: 0;
        a {
            display: block;
        }
        
        @include media('>tablet-portrait') {
            flex: 1 1 50%;
            padding-right: 1em;
        }
    }

    &__text-area {
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 16px;

        @include media('>=tablet-portrait') {
            flex: 1 1 auto;
            text-align: left;
            margin-bottom: 0;
            padding-right: 1em;
        }
    }

    &__button-area {
        flex: 0 0 100%;
        text-align: center;

        @include media('>=tablet-portrait') {
            flex: 0 0 auto;
            text-align: right;
            max-width: 330px;
        }
    }

    &__button {
        @include fonts__sans-serif-bold;
        @include transition(background-color, color);

        display: inline-block;
        border: 2px solid $colors__blue;
        font-size: 20px;
        line-height: 1.5;
        min-height: 30px;
        border-radius: 4px;
        color: $colors__blue;
        padding: 9px 24px;
        text-align: center;

        &:hover {
            background-color: $colors__blue;
            color: $colors__white;
            text-decoration: none;
        }
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 8px;
    }

    &__text {
        @include fonts__sans-serif-light;
        font-size: 18px;
        line-height: 1.5;
    }
}
