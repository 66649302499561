.row {
    @include container;
    margin-bottom: 1.25em; /*ADDED 3/3/17*/
    .one-columns, .one-column {
        @include span(1);
    }

    .two-columns {
        @include span(2);
    }

    .three-columns {
        @include span(3);
    }

    .four-columns {
        @include span(4);
    }

    .five-columns {
        @include span(5);
    }

    .six-columns {
        @include span(6);
    }

    .seven-columns {
        @include span(7);
    }

    .eight-columns {
        @include span(8);
    }

    .nine-columns {
        @include span(9);
    }

    .ten-columns {
        @include span(10);
    }

    .eleven-columns {
        @include span(11);
    }

    .twelve-columns {
        @include span(12);
    }

    div:last-child {
        margin-right: 0px !important;
    }
}

.row:last-of-type {
    margin-bottom: 0px;
}
/*ADDED 3/3/17*/
.three-by-one-grid {
    @include container;

    .left {
        @include span(4);
    }

    .middle {
        @include span(4);
    }

    .right {
        @include span(4 last);
    }
}

@include bp-max(768px) {
    .one-columns, .one-column {
        width: 100% !important;
        display: block !important;
    }

    .two-columns {
        width: 100% !important;
        display: block !important;
    }

    .three-columns {
        width: 100% !important;
        display: block !important;
    }

    .four-columns {
        width: 100% !important;
        display: block !important;
    }

    .five-columns {
        width: 100% !important;
        display: block !important;
    }

    .six-columns {
        width: 100% !important;
        display: block !important;
    }

    .seven-columns {
        width: 100% !important;
        display: block !important;
    }

    .eight-columns {
        width: 100% !important;
        display: block !important;
    }

    .nine-columns {
        width: 100% !important;
        display: block !important;
    }

    .ten-columns {
        width: 100% !important;
        display: block !important;
    }

    .eleven-columns {
        width: 100% !important;
        display: block !important;
    }

    .twelve-columns {
        width: 100% !important;
        display: block !important;
    }
}
