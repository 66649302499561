.text-cta {
    --text-cta__text-decoration: none;
    --text-cta__color: #{$colors__blue};

    display: inline-flex;
    align-items: center;
    color: var(--text-cta__color);

    &--white {
        --text-cta__color: #{$colors__white};
    }

    &:hover {
        color: var(--text-cta__color);  // override legacy styles
        --text-cta__text-decoration: underline;
    }

    &__label {
        @include fonts__sans-serif-bold;
        font-size: 16px;
        line-height: 1.2;
        text-decoration: var(--text-cta__text-decoration);
    }

    &__icon {
        width: 14px;
        height: 12px;
        margin-left: 8px;
    }
}
