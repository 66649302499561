.careers-impact {
    // background-color: $color-blue-shakespear !important;
    background-color: $color-blue-riptide !important;
    // color: $color-white;
    color: $color-dark-blue-footer;
    padding: 3em 0;
    margin-top: -8px;

    .section-intro {
        @include container;
    }

    .section-header {
        margin: 0;
        font-size: 1em;
        // color: $color-white;
        color: $color-dark-blue-footer;
        
    }

    .header-style {
        line-height: 1;
        @include font-primary-bold;
        font-weight: normal;
        width: 100%; 
        font-size: 45px;
        
        @include bp-min($bp-ipad) {
            @include span(5);
            font-size: 4.25em;
        }

        /*&:first-of-type {@include font-jenna(96px);}
		&:nth-of-type(2){@include font-franklin(88px);}*/
    }

    .section-copy {
        padding-top: .65em;
        @include span(7 last);

        p {
            // color: $color-white;
            color: $color-dark-blue-footer;
        }
    }

    .section-content {
        margin-top: 3em;
        @include container;
    }

    .impact-group {
        width: calc(97% / 3);
        margin-right: calc(3% / 3);

        &:last-of-type {
            @include span(4 last);
        }

        p {
            // color: $color-white;
            color: $color-dark-blue-footer;
        }

        h2 {
            color: $color-dark-blue-footer!important; //override inline class
        }
    }

    .group-points {
        list-style-type: disc;
        margin-left: 1em;
        margin-top: 1.5em;

        .group-point {
            // color: $color-white;
            color: $color-dark-blue-footer;
        }
    }

    @include bp-max($bp-ipad) {
        .section-header {
            @include span(100%);
        }

        .section-copy {
            @include span(100%);
            padding-top: 2em;
        }

        .section-content {
            margin-top: 0;
        }

        .impact-group {
            @include span(100%);
            margin-top: 3em;

            &:last-of-type {
                @include span(100%);
            }
        }
    }
}

.careers-impact .section-content {
    text-align: center;
}

.careers-impact .impact-group-wrapper {
    text-align: left;
    display: inline;
}

.careers-impact .impact-group {
    float: none !important;
    vertical-align: top;
    display: inline-block;
}
