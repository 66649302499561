.cta-buttons {
    --gutter-size: 30px;
    overflow: hidden;
    text-align: center;
    margin-top: 64px;
    margin-bottom: 64px;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 calc((var(--gutter-size)/2) * -1);
        flex-wrap: wrap;
        align-items: stretch;
        list-style-type: none;
        margin-top: 25px;

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;            
        }
    }

    &__button {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin: calc((var(--gutter-size)/2));
        flex: 1 1 100%;
        margin-bottom: 16px;
        
        @include media('>=tablet-portrait') {
            flex: 1 1 calc(100%/3);
            margin-bottom: 0;
        }
    }
    
    &__button-link {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        line-height: 1.5;
        padding: 9px 24px;
        box-shadow: 2px 2px 8px 0 rgba(0,0,0,0.3);
        background-color:  $colors__blue;
        border-radius: 4px;
        color: $colors__white;
        width: 100%;
        
        &:hover {
            background-color: $colors__blue--dark-alt;
            color: $colors__white;
            text-decoration: none;
        }

        @include media('>=tablet-portrait') {
            font-size: 24px;
            line-height: 1.2;
            padding: 24px;
        }
    }

    .section-name, .section-headline {
        text-align: center;
    }
}