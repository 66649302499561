.brief-intro {
    text-align: center;

    .intro-copy {
        font-size: px-to-em(18px);
        color: $color-brown-spice;
    }

    .focus-area {
        line-height: 1.25;
        text-transform: uppercase;
        @include font-primary;
        list-style-type: none;
        font-size: .75em;
    }

    @include bp-max($bp-desktop) {
        .intro-copy,
        .intro-icons-image {
            display: none;
        }

        .focus-area {
            display: inline-block;
            font-size: px-to-em(14px);

            &:after {
                content: '|';
                margin: 0 .25em;
            }

            &:last-of-type {
                &:after {
                    content: none;
                }
            }
        }
    }
}
