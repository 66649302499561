.quotes-carousel {
    --quotes-carouse__quote-size: 20px;
    --quotes-carouse__source-font-size: 16px;
    --quotes-carouse__source-margin-top: 16px;
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
    background-color: $colors__gray--f7;

    &.teal-light {
        background-color: $colors__teal--lightest;
    }



    &.green {
        background-color: $color-blue-riptide;
    }

    &.celadon {
        background-color: $colors__celadon !important;
    }

    &.navy {
        background-color: $colors__navy !important;
    }

    &.riptide {
        background-color: $colors__riptide !important;
    }

    &.saffron {
        background-color: $colors__saffron !important;
    }

    &.orange {
        background-color: $colors__saffron !important;
    }

    &.white {
        background-color: $colors__white !important;
    }

    &.cloud {
        background-color: $colors__cloud !important;
    }

    &.riptidetint {
        background-color: $colors__riptidetint !important;
    }

    &.celadontint {
        background-color: $colors__celadontint !important;
    }

    &.saffrontint {
        background-color: $colors__saffrontint !important;
    }


    .swiper-wrapper {
        align-items: center;
    }

    &.swiper-carousel {
        padding: 48px 0 0 0;
    }

    &__content {
        text-align: center;
        max-width: 730px;
        margin: 0 auto;
        padding: 0;
        border: 0;
        float: none;
    }

    &__quote {
        @include fonts__sans-serif-medium;
        font-size: var(--quotes-carouse__quote-size);
        line-height: 1.4;
        color: $colors__blue;
    }

    &__source {
        @include fonts__sans-serif-light;
        font-size: var(--quotes-carouse__source-font-size);
        margin-top: var(--quotes-carouse__source-margin-top);
        line-height: 1.5;

        &::before {
            display: none;
        }
    }

    @include media('>=tablet-portrait') {
        --quotes-carouse__quote-size: 22px;
        --quotes-carouse__source-font-size: 18px;
        --quotes-carouse__source-margin-top: 20px;
    }

    @include media('>=tablet-landscape') {
        --quotes-carouse__quote-size: 24px;
        --quotes-carouse__source-margin-top: 24px;
    }
}
