blockquote {
    @include font-primary-medium;
    font-size: 1.25em;
    float: right;
    margin: 0em 0em 1em 1em;
    padding: .75em;
    max-width: 45%;
    min-width: 35%;
    border-top: 5px solid $color-blue-riptide;
    border-bottom: 5px solid $color-blue-riptide;
    font-style: italic;
    color: $color-brown-spice;

    h1, h2, h3, h4, h5, h6 {
        font-style: italic;
    }

    p {
        line-height: 1.4;
        margin-bottom: .25em;
    }

    cite {
        color: $color-brown-spice;
        display: block;
        font-size: inherit;
        margin-top: .5em;
        font-style: normal;

        &:before {
            content: '\2014  ';
        }
    }

    .button {
        font-size: .75em !important;
        padding: 0 40px !important;
        width: 100%;
    }

    &.right {
        float: right;
        margin: 0em 0em 1em 1em !important;
        max-width: 45%;
        min-width: 35%;
        border-top: none !important;
        border-bottom: none !important;
        border-left: 5px solid $color-green-olive;
        padding: 0px 0px 0px 1.1em !important;
        margin: 0px 0px 1.25em 0px;
        font-size: 1.2em !important;
        font-style: italic;
        color: $color-brown-spice;
        line-height: 1.4;
    }

    &.left {
        float: left;
        margin: 0em 1em 1em 0em !important;
        max-width: 45%;
        min-width: 35%;
        border-top: none !important;
        border-bottom: none !important;
        border-left: 5px solid $color-green-olive;
        padding: 0px 0px 0px 1.1em !important;
        margin: 0px 0px 1.25em 0px;
        font-size: 1.2em !important;
        font-style: italic;
        color: $color-brown-spice;
        line-height: 1.4;
    }

    &.full {
        @include font-primary-light;
        float: none !important;
        max-width: 100% !important;
        min-width: 100% !important;
        border-top: none !important;
        border-bottom: none !important;
        border-left: 5px solid $color-green-olive;
        padding: 0px 0px 0px 1.1em !important;
        margin: 0px 0px 1.25em 0px;
        font-size: 1.2em !important;
        font-style: italic;
        color: $color-brown-spice;
        line-height: 1.4;
    }
}

@include bp-max(550px) {
    blockquote {
        float: none;
        margin-left: 0px;
        max-width: 100%;
        width: 100%;

        &.right, &.left {
            float: none;
            margin-left: 0px;
            max-width: 100%;
            width: 100%;
        }
    }
}
