.location-detail {
    background-color: $colors__white;

    .page-hero {
        --page-hero__text-size: 14px;

        @include media('>=tablet-portrait') {
            --page-hero__text-size: 16px;
        }
    }

    &__section-block {
        & + & {
            margin-top: px-to-em(30px);
        }
    }

    &__content {
        &__top {
            background-color: $colors__gray--f7;

            &--info {
                padding: 48px 0 16px 0;

                &_wrapper {
                    &-item {
                        padding: 16px 0 16px 0;

                        &__header {
                            font-size: 20px;
                            line-height: 1.2;
                            padding-bottom: 8px;
                            border-bottom: 4px solid $colors__gray--d8;
                        }
                    }

                    @include media('>=tablet-portrait') {
                        display: flex;
                        justify-content: space-between;

                        &-item {
                            width: 32%;
                        }
                    }
                }
            }
        }

        &__bottom {
            padding: 48px 0 48px 0;

            .disclaimer {
                font-size: .95em;
                margin-top: var(--rte__y-spacing);
            }
        }

        &__badge-carousel {
            background-color: #eef2b2;
            padding: 1%;
            text-align: center;
            padding: 48px 0;

            &__list {

                &__content {
                    display: flex;
                    flex-direction: column;

                    @include media('>=tablet-portrait') {
                        flex-direction: row;
                        margin: 0 4em;
                    }

                    @include media('>tablet-portrait') {
                        margin: 0 6em;
                    }

                    &__image {
                        width: 80%;
                        margin: 16px auto;
                        max-width: 162px;

                        @include media('>=tablet-portrait') {
                            width: 30%;
                            max-width: 200px;
                            align-items: center;
                            display: flex;
                        }
                    }

                    &__details {
                        width: 100%;
                        padding: 20px 0;
                        text-align: center;

                        .section-headline {
                            width: 100%;
                        }

                        .call-to-action {
                            width: 80%;
                            color: var(--call-to-action__hover-color);
                            background-color: var(--call-to-action__hover-bg-color);

                            &:hover {
                                color: var(--call-to-action__color);
                                background-color: var(--call-to-action__bg-color);
                            }
                        }

                        @include media('>=tablet-portrait') {
                            width: 70%;
                            padding: 40px 0 40px 40px;
                            text-align: left;
                            display: flex;
                            flex-wrap: wrap;
                            align-content: center;

                            .call-to-action {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    &--location-card {
        padding-left: 10px;

        &__info {
            padding-top: 20px;
        }

        &__links {
            padding: 20px 0;
            line-height: 2;
        }
    }

    &--contact-card {
        &--photo {
            padding: 20px 5px 5px 5px;
            max-width: 190px;
        }

        &--photo-image {
            width: 100%;
            padding-bottom: 100%;
            background-size: cover;
            background-position: center top;
            display: block;
            border-radius: 200px;
            border: solid 5px $color-lighter-grey;

            &:hover {
                border: solid 5px $color-dark-blue-footer;
            }
        }

        &--info {
            padding: 20px 5px 5px 5px;
        }

        .call-to-action {
            margin-top: 20px;
        }

        @include media('>=tablet-portrait') {
            display: flex;
            justify-content: space-between;

            &--photo {
                width: 50%;
            }

            &--info {
                width: 50%;
            }
        }
    }

    &__icon-wrapper {
        display: inline-block;
        width: px-to-em(48px);
        vertical-align: middle;
    }

    &__icon-phone {
        width: px-to-em(28px);
        height: px-to-em(28px);
    }

    &__icon-fax {
        width: px-to-em(32px);
        height: px-to-em(33px);
    }

    &__icon-link.location-detail__icon-link {
        font-weight: normal;
    }

   
    &__icon-wrapper-detail-page {
        display: inline-block;
        width: px-to-em(47px);
        vertical-align: middle;
    }

    @include media('>=tablet-portrait') {
        &__section-block {
            & + & {
                margin-top: px-to-em(50px);
            }
        }

        &__icon-wrapper {
            width: px-to-em(52px);
        }

        &__icon-wrapper-detail-page {
            width: px-to-em(45px);
        }

        &__icon-phone {
            width: px-to-em(30px);
            height: px-to-em(30px);
        }

        &__icon-fax {
            width: px-to-em(35px);
            width: px-to-em(36px);
        }

       
    }
}
