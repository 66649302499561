﻿.site-search {

    &__display-label {
        font-size: 19px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .site-search-results {
        li {
            margin-bottom: 1em;
            padding-left: 0px;
            margin-left: 0px !important;
            list-style: none;

            .abstract {
                font-size: 18px !important;
                line-height: 1.25;

                p {
                    font-size: 18px !important;
                    line-height: 1.25;
                }
            }
        }

        ul.search-results-meta {
            display: block;

            li:first-of-type:before {
                display: none !important;
            }

            li:before {
                padding-left: 0px;
            }
        }

        .highlight {
            font-weight: bold;
        }
    }
}
