.aside-optional-content {
    .aside-container {
        background-color: $color-gray-white;
        padding: 1em .50em;
        color: $color-brown-spice;
        margin-bottom: 1em;
    }

    .twitter h3:before {
        @include font-fontawesome($fa-var-twitter);
        font-size: .650em;
        margin-right: .5em;
    }

    p, li {
        font-size: 1em;
    }
}
