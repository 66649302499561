
.badge-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,.4);

    > div {
        padding: 2em 1em;
    }

    &__content {
        background-color: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        padding: 3em;
        text-align: left;

        & .section-headline {
            font-size: var(--section-headline__font-size);
            line-height: 1.1;
            font-weight: 400;
            color: #2d3c59;
        }
    }

    &__container {
        max-width: 950px;
        border-radius: 5px;
        margin: auto;
        padding: 1.5em 2.25em 1.5em 1.5em;
    }

    &__description {
        & ul li {
            list-style: inherit;
        }
    }

    &__close {
        height: 30px;
        width: 30px;
        display: block;
        position: absolute;
        right: 0;
        top: 1.5em;
        border: 2px solid #FFFFFF;
        border-radius: 100%;
        text-align: center;

        svg {
            position: relative;
            height: 50%;
            width: 50%;
            color: #FFFFFF;
        }
    }

    @include bp-max($bp-mobile) {
        &__content {
            padding: 2em;
        }
    }
}

.override-swiper-wrapper {
    transform: none !important;
    z-index: auto !important;
}

.override-swiper-container {
    position: static !important;
}

.section-image {
    display: flex;
    align-items: center;
    margin-top: 3em;
    margin-bottom: 1.25em;

    &__img {
        display: inherit;
        flex: 0 0 auto;
        width: 33.33%;
        justify-content: center;
    }
}

.section-text {
    padding: 16px 0 16px 28px;
    width: 66.66%;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.5;
    font-family: MuseoSans,arial,sans-serif;
    color: #404041;
}

.cta-text {
    margin-top: 0;
}

.cta-buttonlink {
    margin: 0 !important;
}

@media only screen and (max-width: 767px) {
    .section-image {
        flex-direction: column;
    }

    .section-text {
        width: 100%;
        padding: 0px;
    }

    .section-image__img {
        width: 100%;
        max-width: 162px;
        margin-bottom: 1.5em;
    }
}

.cta-button {
    border: 2px solid;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

.badge-modal.is-open {
    display: block;
}

[data-micromodal-trigger] {
    cursor: pointer;
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .badge-modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .badge-modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .badge-modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .badge-modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .badge-modal__container,
.micromodal-slide .badge-modal__overlay {
    will-change: transform;
}
