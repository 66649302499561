.careers-section-header {
    background-color: $color-gray-header-section;
    padding: 15px 0;

    h1 {
        @include font-primary-light;
        font-size: 1.375em;
        color: $color-brown-cocao-brown;
        ;
        text-transform: uppercase;
        margin-bottom: .25em;
    }

    h2 {
        font-size: 2em;
        color: $color-green-olive;
    }

    p {
        font-size: 1.250em;
        color: $color-brown-cocao-brown;
    }

    a {
        color: $color-white;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    .secondaryheadline {
        @include font-primary-medium-bold(20px);
        font-weight: normal;
        line-height: .75em;
    }
}
