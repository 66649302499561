.resource-detail {
    background-color: $colors__white;
    --underlay-color: #{$colors__white};

    .breadcrumbs {
        margin-bottom: .5em;
        background-color: transparent;
        /* max-width: 73%; Zendesk 39277 */
        width: 100%;

        a {
            @include fonts__sans-serif-medium;
        }

        &__wrapper {
            padding-top: 0;
        }

        @include media('>=tablet-portrait') {
            margin-bottom: 32px;
        }
    }

    &__date {
        text-transform: uppercase;
        letter-spacing: 2.5px;
    }

    &__topic {
        @include fonts__sans-serif-bold;
        text-transform: uppercase;
        letter-spacing: 2.5px;
    }

    &__meta {
        margin-top: 16px;
        list-style-type: none;
        font-size: 16px;

        li {
            display: inline-block;

            &:last-child::after {
                display: none;
            }

            &::after {
                content: '|';
                margin: 16px;
            }
        }
    }

    .share-tools {
        margin-bottom: 0;
    }

    &__aside {
        background-color: $colors__gray--f7;
        padding: 32px 16px;

        > div:last-child {
            margin-bottom: 0;
        }
    }

    &__aside-section {
        border-bottom: 2px solid $colors__gray--d8;
        padding: 32px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &--share,
        &--speakers {
            display: none;

            @include media('>=tablet-portrait') {
                display: block;
            }
        }
    }

    &__subscribe {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;

        &__call-to-action {
            flex: 0 0 40%;
            padding: 5px 18px;
            font-size: 16px;

            @include media('>=tablet-portrait') {
                flex: 0 0 auto;
            }
        }
    }

    &__aside-section-title {
        font-family: $fonts__sans-serif, 'arial', sans-serif;
        font-style: normal;
        font-weight: $fonts__font-weight--light;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 1em;
    }

    &__header {
        margin-bottom: 30px;

        &--two-col {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: var(--underlay-color);

            @include media('>=tablet-landscape') {
                flex-wrap: nowrap;
                background: none;
            }

            figure {
                flex: 1 1 100%;
                z-index: 1;

                @include media('>=tablet-landscape') {
                    flex: 1 1 calc((4/9) * 100%);
                }
            }

            .resource-detail__abstract {
                padding: 16px 0 16px 10px;
                flex: 1 1 100%;
                z-index: 1;

                @include media('>=tablet-landscape') {
                    flex: 1 1 calc((5/9) * 100%);
                    padding: 16px 0 16px 28px;
                }

                p {
                    @include fonts__sans-serif-medium();
                    line-height: 1.5;
                    font-size: 18px;

                    @include media('>=tablet-landscape') {
                        font-size: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        figure {
            img {
                width: 100%;
                z-index: 1;
            }
        }
    }

    &__underlay {
        display: none;

        @include media('>=tablet-landscape') {
            position: absolute;
            display: flex;
            background-color: var(--underlay-color);
            width: 75%;
            height: 150px;
            left: 0;
        }

        &--theme {
            &-celadon {
                --underlay-color: #{$colors__celadon};
            }

            &-celadontint {
                --underlay-color: #{$colors__celadontint};
            }

            &-cloud {
                --underlay-color: #{$colors__cloud};
            }

            &-home {
                --underlay-color: #{$colors__teal};
            }

            &-navy {
                --underlay-color: #{$colors__navy};
            }

            &-riptide {
                --underlay-color: #{$colors__riptide};
            }

            &-riptidetint {
                --underlay-color: #{$colors__riptidetint};
            }

            &-saffron {
                --underlay-color: #{$colors__saffron};
            }

            &-saffrontint {
                --underlay-color: #{$colors__saffrontint};
            }
        }
    }

    .email-subscribe {
        background-color: $colors__teal--light;
        padding: 32px 0;
        margin: 2em 0;
    }

    &__key-insights {
        background-color: $colors__teal--lightest !important;
        padding: 32px 24px;
        margin-bottom: 40px;

        h2 {
            font-family: $fonts__serif-regular;
            font-size: 38px;
            margin-bottom: 24px;
        }

        ul {
            &:last-child {
                margin-bottom: 0;
            }

            li {
            }
        }
    }

    &__cta {
        width: 100% !important;
        background-color: $colors__green--alt !important;
        padding: 32px 48px;
        margin: 40px 0;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;
        }
    }

    &__cta-info {
        h4 {
            @include fonts__sans-serif-bold;
            font-size: 24px;
            text-transform: none;
            letter-spacing: 0;
        }

        margin-bottom: 1em;
        text-align: center;
        flex: 1 1 100%;

        @include media('>=tablet-portrait') {
            margin-bottom: 0;
            text-align: left;
            flex: 1 1 75%;
        }
    }

    &__cta-button {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        background-color: $colors__blue;
        color: $colors__white !important;
        border-radius: 4px;
        padding: 10px 24px;
        flex: 1 1 100%;
        text-align: center;
        margin-left: 0;

        @include media('>=tablet-portrait') {
            text-align: center;
            flex: 0 1 25%;
            margin-left: 1em;
        }

        &:hover {
            text-decoration: none;
            color: $colors__white;
        }
    }
    // OVERWRITE LEGACY STYLES
    .recomended-container {
        float: none !important;
    }

    .panel.dutch-white {
        width: 100% !important;
    }

    .mobile-speakers .swiper-wrapper {
        padding-left: 0;
    }

    &__boilerplate {
        border-bottom: 2px solid $colors__gray--d8;
        border-top: 2px solid $colors__gray--d8;
        padding: 20px 0px;
        margin-top: 40px;
        font-style: italic;
        font-size: 14px;
    }

    .two-column-layout__aside {
        z-index: 1;
    }

    @include media('>=tablet-portrait') {
        &.two-column-layout {
            margin-bottom: 32px;
            padding-bottom: 0;
        }

        .people-card__social-links {
            margin-bottom: 0;
        }
    }

    @include media('>=tablet-landscape') {
        &.two-column-layout {
            margin-bottom: 48px;
            padding-bottom: 0;
        }
    }
}

.more-insights {
    // margin: 32px 0;
    // border-bottom: 2px solid $colors__gray--d8;
    // &__title {
    //     @include fonts__sans-serif-light;
    //     font-size: 16px;
    //     letter-spacing: 2px;
    //     margin-bottom: 24px;
    //     text-transform: uppercase;
    //     color: $colors__blue;
    // }

    &__item {
        margin: 24px 0;

        &:last-child {
            margin-bottom: -0;
        }
    }

    &__link {
        @include fonts__serif-regular;
        font-size: 20px;
    }

    &__eyebrow {
        @include fonts__sans-serif-light;
        font-size: 14px;
        letter-spacing: 2px;
        display: block;
        text-transform: uppercase;
    }
}

.detail-tags {
    // margin: 32px 0;
    // &__title {
    //     @include fonts__sans-serif-light;
    //     font-size: 16px;
    //     letter-spacing: 2px;
    //     margin-bottom: 24px;
    //     text-transform: uppercase;
    //     color: $colors__blue;
    // }

    &__list {
        margin-bottom: 0;

        li {
            list-style-type: none;
        }
    }

    &__link {
        @include fonts__sans-serif-medium;
        font-size: 16px;
    }
}
