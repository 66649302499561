.disclaimer {
    p {
        font-size: 0.75em;
        // font-style: italic;
        font-family: $font-family-primary-light;
    }
}

.disclaimer {
    font-size: 0.95em;
    // font-style: italic;
    font-family: $font-family-primary-light;
}

.content-aside {
    .disclaimer p {
        font-size: 0.6875em;
        font-style: normal;
        text-align: center;
        margin-left: px-to-em(11.2px);
    }
}

@include bp-max(990px) {
    .content-aside .disclaimer {
        display: none;
    }
}
