.cla-at-a-glance {
    $section-header-base-font: 20px;
    padding: 2em 0;
    // background-color: $color-blue-shakespear;
    background-color: $color-blue-riptide;

    @include container;

    .section-header {
        margin-left: 0;
        float: left;
        font-size: 26px;
    }

    a {
        // color: $color-white;
        color: $color-dark-blue-footer;
        text-decoration: underline;
    }

    .header-style-primary {
        // color: $color-white;
        color: $color-dark-blue-footer;
        font-weight: normal;
        line-height: 1;
        @include font-primary-medium-bold(100px, $section-header-base-font);
        
        @include bp-min($bp-ipad) {
            @include font-primary-medium-bold(144px, $section-header-base-font);
        }
    }

    .header-style-secondary {
        display: block;
        @include font-primary-medium-bold;
        // color: $color-white;
        color: $color-dark-blue-footer;
        line-height: 1;
        position: relative;
        top: -12px;
        font-size: 1.75em;
        
        @include bp-min($bp-ipad) {
            font-size: 2.75em;
        }
    }

    .details-list {
        margin-top: 1.5em;
        list-style-type: disc;
        @include font-primary;
        overflow: hidden;
        padding-left: 5em;

        .details-list-item {
            // color: $color-white;
            color: $color-dark-blue-footer;
        }
    }

    @include bp-max($bp-ipad) {
        .section-header {
            @include span(100%);
            padding-left: 1em;
        }

        .details-list {
            padding-left: 2em;
            @include span(100%);
        }
    }
}

@media print {
    .splash-bar .splash-bar-image {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}
