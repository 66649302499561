.careers-promise {
    $border-radius: 3px;
    $border-style: 1px solid $color-white-gainsboro;
    padding: 3em 0 4em;

    .section-intro {
        @include container;
    }

    .section-image-container {
        @include span(5);
    }

    .section-intro-content {
        @include span(7 last);
    }

    .section-header {
        margin: 0;
        font-size: 1em;
        color: $color-violet-jagger;
    }

    .header-style {
        line-height: 1;
        @include font-primary-bold;
        font-size: 4.25em;
        font-weight: normal;

        &:first-of-type {
        }

        &:nth-of-type(2) {
            display: block;
            position: relative;
            font-weight: normal;
            top: -15px;
        }
    }

    .section-intro-copy {
        color: $color-brown-spice;
        margin-bottom: 1em;
    }

    .section-content {
        margin-top: 2em;
        @include container;
    }

    .promise-group {
        display: table;
        width: 100%;
        border-spacing: .5em;

        .col {
            padding: 1em;
            width: 33.3%;
            display: table-cell;
            background-color: $color-green-olive;
            border-radius: $border-radius;
        }
    }

    .group-header {
        color: $color-violet-jagger;
        margin: 0;
    }

    .group-subheader {
        font-weight: bold;
        color: $color-white;
    }

    .group-copy {
        margin: 1.5em 0;
        color: $color-white;
    }

    .social-media-contact {
        background-color: $color-green-olive;
        padding: 1.25em;
        border-radius: $border-radius;
        border-style: $border-style;

        @include span(4 last);
    }

    .social-media-link {
        color: $color-white;
        display: block;

        &:before {
            font-size: px-to-em(30px);
            margin-right: .25em;
            vertical-align: middle;
        }

        &.social-media-linkedin {
            &:before {
                @include font-fontawesome($fa-var-linkedin-square);
            }
        }

        &.social-media-facebook {
            &:before {
                @include font-fontawesome($fa-var-facebook-square);
            }
        }
    }

    @include bp-max($bp-ipad) {
        .section-image-container,
        .section-intro-content,
        .promise-group,
        .social-media-contact {
            @include span(100%);
        }

        .promise-group,
        .social-media-contact {
            margin-top: 2em;
        }

        .section-intro-content {
            margin-top: 2em;
        }

        .section-content {
            margin-top: 0;
        }
    }
}

.careers-promise .company-insider {
    text-align: center;
}

@media screen and (max-width: 720px) {
    .careers-promise {
        .promise-group {
            display: block;

            .col {
                display: block;
                width: 100%;
                margin-bottom: 1em;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .negative-shim-careers {
        margin-top: -2em !important;
    }

    .careers-promise.linkedin-hidden-mobile {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
        margin: 0 !important;
        padding-top: -2em !important;

        .linkedin-module, .intro-content-left {
            display: none !important;
            height: 0px !important;
            width: 0px !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}
