.stats-list {
    --gutter-size: 30px;
    $gutter-size: 30px;

    background-color: $colors__gray--f7;
    padding: 48px 0;

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 40px 0 0;

        @include media('>=tablet-landscape') {
            flex-wrap: nowrap;
        }
    }

    &__title-container {
        text-align: center;
    }

    &__title {
        text-align: center;
        margin: auto;
        margin-bottom: 24px;
    }

    &__subtitle {
        text-align: center;
        margin: auto;
        margin-bottom: 32px;
    }

    &__desc {
        @include fonts__sans-serif-light;
        text-align: center;
        max-width: none;
        font-size: 16px;
        line-height: 1.5;

        @include media('>=tablet-landscape') {
            max-width: 80%;
            font-size: 22px;
            line-height: 1.27;
            margin: auto;
        }
    }

    &__item {
        background-color: $colors__teal--light;
        color: $colors__blue;
        flex: 1 1 calc((100%/3) - #{$gutter-size}/2);
        margin: calc(#{$gutter-size}/2);
        text-align: center;
        padding: 32px 42px;
    }

    &__item-stat {
        @include fonts__sans-serif-black;
        font-size: 70px;
        line-height: 1;

        @include media('>=tablet-portrait') {
            font-size: 90px;
        }
    }

    &__item-desc {
        font-size: 20px;
        line-height: 1.2;
    }

    @include media('>=tablet-portrait') {
        padding: 56px 0;
    }


    @include media('>=tablet-landscape') {
        padding: 64px 0;
    }
}
