.clienthq-aie_help {
    /*text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 60px 0 0 60px;*/
    display: flex;
    height: 909px;
    align-items: flex-start;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    background: linear-gradient(0deg, rgba(38, 42, 64, 0.65) 0%, rgba(38, 42, 64, 0.65) 100%), url(/-/media/clienthq/images/loginbg.png) lightgray -375.195px -100.483px / 200% no-repeat;

    & p {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 13px;
        font-style: normal;
        line-height: 18px;
        padding-left: 12px;
        font-weight: 300;
        padding-left: 0px;
        padding-top: 10px;
    }


    & ol {
        color: #FFF;
        margin-bottom: 0px !important;
    }

    & li {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 13px;
        font-style: normal;
        line-height: 18px;
        padding-left: 12px;
        font-weight: 300;
        padding-left: 0px;
        padding-top: 10px;

        & .clienthq-aie_help-span {
            display: flex;
            flex-grow: 1;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            max-width: fit-content;
        }
    }

    & form {
        padding-bottom: 20px;
    }

    &__listContent {
        height: 25px;
        display: flex;
        gap: 10px;
        line-height: 20px;
        align-items: baseline;
    }

    &__container {
        width: 720px;
        color: #FFF;
        padding-bottom: 10px;
    }

    &__drawer {
        display: flex;
    }

    &__drawerContent {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 13px;
        font-style: normal;
        line-height: 18px;
        padding-left: 12px;
        font-weight: 300;
    }

    &__drawerTitle {
        display: flex;
        margin: 0 0 0 10px;
        flex-grow: 1;
        align-items: center;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 143.75% */
    }

    & .expandBtn {
        width: 19px;
        height: 19px;
        background-color: #FFF;
        border-radius: 50%;
        cursor: pointer;
        padding-bottom: 7px;
        padding-left: 2px;
        display: flex;
    }

    & a[aria-expanded=false] .expandBtn {
        background-image: url("/Content/images/plus-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 19px;
    }

    & a[aria-expanded=true] .expandBtn {
        background-image: url("/Content/images/dash-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 19px;
    }

    &-h3 {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        padding-bottom: 10px;
    }

    &-pg {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }

    &-body {
        display: flex;
        min-height: 800px;
        padding-bottom: 240px;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        align-self: stretch;

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            flex: 1 0 0;
            padding-left: 45%;
        }
    }

    &-header {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__call-to-action {
        &:hover {
            background-color: #fff;
            --call-to-action__color: #2180bc;
        }
    }

    &__focus {
        background-color: #fff !important;
        color: #2e334e !important
    }

    &__heading {
        color: #fff;
        font-family: 'MuseoSans-300';
        font-size: 4em;
        padding: .5em;
    }

    &__contact a {
        padding-top: 25px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
    }

    &__form {
        display: flex;
        justify-content: center;

        &-btn {
            color: #2180bc;
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            border-radius: 4px;
            background: #7DD2D3;
            margin: 20px 0;
            padding: 25px;
            justify-content: center;
            align-items: center;

            &:after {
                color: #fff;
            }

            &:hover {
                background: #fff;
            }
        }

        &-info-btn {
            display: flex;
            padding: 20px;
            text-align: center;
            align-self: stretch;
            border-radius: 4px;
            border: .2px solid white !important;
            background: #2E334E;
            width: 489px;

            &:hover,
            &:focus {
                border-radius: 4px;
                border: 1.5px solid white !important;
                cursor: pointer;
            }
        }

        &-info-text {
            color: #FFF;
            font-family: MuseoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: #FFF;
            }
        }

        &-btn-icon {
            height: 13px;
            width: 24px;
            color: $colors__white;
            align-self: center;
        }

        & &-btn {
            //increse specificity
            width: inherit;
        }
    }

    & ~ * > * {
        //aie_help sits in header, remove padding and height from content
        padding: 0 !important;
        min-height: 0 !important;
    }

    &__header {
        display: flex;
        width: 1800px;
        padding: 60px 0 60px 0px;
        align-items: center;
        gap: 10px;

        &_logo {
            display: flex;
            width: 80px;
            height: 80px;
            padding-right: 0.112px;
            justify-content: center;
            align-items: center;
            background-image: url('../../../../Content/images/CLA-logo-login.png')
        }

        &_text {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 880px
        }
    }

    &__body-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        flex: 1 0 0;

        &_page-title-h1 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            /* H1 */
            font-family: MuseoSans;
            font-size: 46px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 23px;
            margin-bottom: 0px;
        }

        &_page-title-h4 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans-300;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0px;
            width: 489px;
        }

        &_page-title-h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off; /* H3 */
            font-family: MuseoSans;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &_help-text {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 460px;
        }
    }

    &__preferred {
        display: flex;
        width: 91px;
        height: 24px;
        margin-top: -20px;
        border-radius: 3px;
        background: #c0462f;
        position: relative;
        top: 25px;
        left: 15px;
        color: #FFF;
        justify-content: center;
        align-items: center;
    }

    &__button {
        color: white;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background: #7DD2D3;
        align-self: stretch;
        margin: 20px 0;
        padding: 25px 25px;
        justify-content: center;
        align-items: center;

        &--user {
            margin-right: 20px;
            border-radius: 4px;
            background: var(--Button-Initial, #7DD2D3);
            display: flex;
            width: 300px !important;
            padding: 20px 80px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            color: #2180bc;

            &:hover {
                color: #2E334E !important;
            }
        }

        &--text {
            color: #2E334E;
            font-feature-settings: 'liga' off, 'clig' off;
            /* Button Label */
            font-family: MuseoSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;
            text-align: center;

            &:hover {
                color: #2E334E;
                text-decoration: none;
            }
        }

        &:hover {
            background: #fff;
            cursor: pointer;

            &:after {
                background: #fff;
                color: #2180bc;
            }
        }
    }

    &__padding {
        padding-left: 350px
    }

    &__helptext {
        max-width: 290px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off; /* Steps */
        font-family: MuseoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__mobile-hide {
        display: block;
    }

    @include media('>desktop-xlarge') {
        .clienthq-aie_help {

            &__content {
                min-height: 990px;
            }

            &__infographic {
                min-height: 1100px;
            }
        }
    }
    //Infographic specific

    @include media('>tablet-landscape','<desktop') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-aie_help__content {
            margin: 0;
            padding-top: 40px;
        }
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        background: none !important;
        background-color: #2E334E !important;
        height: 1400px;

        .clienthq-aie_help__preferred {
            font-size: 24px;
            height: 45px;
            width: 150px;
            top: 65px;
            right: 285px;
            left: unset;
        }

        .clienthq-aie_help__content {
            padding-top: 50px;
            min-height: 800px;
            margin: 0;
            max-width: 768px;
            padding: 40px 0 40px 0px;
        }

        .clienthq-aie_help__contact {
            diplay: none;

            & a {
                font-size: 24px;
            }
        }

        .clienthq-aie_help__button {
            height: 80px;
            width: 700px;
        }

        .clienthq-aie_help__form-info-btn {
            gap: 60px;
            height: 100px;
            align-items: center;
            padding-left: 65px;
            width: auto;
        }

        .clienthq-aie_help__form-btn-icon {
            height: 26px;
        }

        .clienthq-aie_help__form-info-text {
            font-size: 24px;
        }

        .clienthq-aie_help__body-content {
            flex-direction: column;
            gap: 50px;
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 768px;
                Font-size: 56px;
            }

            &_page-title-h3 {
                font-size: 36px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 28px;
                text-align: center;
            }
        }

        .clienthq-aie_help__button--text {
            font-size: 36px;
        }
    }

    @include media('>phone','<=tablet-portrait') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-aie_help__content {
            margin: 0;
            max-width: 320px;
            padding: 40px 0 40px 0px;
        }

        .clienthq-aie_help__contact {
            diplay: none;
        }

        .clienthq-aie_help__button {
            width: 300px;
        }

        .clienthq-aie_help__form-info-btn {
            gap: unset !important;
            width: 100%;
        }

        .clienthq-aie_help__body-content {
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 320px;
                font-size: 28px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    @include media('<=phone') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-aie_help__preferred {
            font-size: 14px;
            height: 25px;
            width: 74px;
            top: 25px;
            right: 95px;
            left: unset;
        }

        .clienthq-aie_help__contact {
            display: none;
        }

        .clienthq-aie_help__content {
            margin: 0;
            max-width: 320px;
            padding: 40px 0 40px 0px;
        }

        .clienthq-aie_help__button {
            width: 300px;
        }

        .clienthq-aie_help__form-info-btn {
            gap: unset !important;
            width: 100%;
        }

        .clienthq-aie_help__body-content {
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 320px;
                font-size: 28px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }
}
@media print {
    html:has(.clienthq-aie_help) {
        height: auto;
        width: auto;

        @page {
            size: A4 landscape;
        }
    }
    .clienthq-aie_help {
        visibility: hidden;
        background: none;
        height: auto;

        
        & .show-for-print {
            visibility: visible;
            left: 0;
            top: 0;
        }       
    }    

    .ecEmbedWrapper {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
    .proactive-animation-tooltip {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
    #ecLauncherFloatingBubble {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}

// aie_help page chat overrides
#ecLauncherFloatingBubble {
    background-color: #76D2D3 !important;
}

#ecLauncherFloatingBubble:focus {
    outline: 2px solid #fff !important;
    background-color: #76D2D3 !important;
}

.mini-chat-footer-btn {
    background: #76D2D3 !important;
}

.header-svg-wave {
    background-color: #76D2D3 !important;
}

.header-rectangle.home-page {
    background-color: #76D2D3 !important;
}


