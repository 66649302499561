﻿.type-ahead {
    z-index: 1;
    background-color: $color-white;
    width: calc(100% + 2px);
    list-style-type: none;
    padding: 0;
    position: absolute;
    margin: 1px 0 0 0;
    left: -1px;

    li {
        padding: 0.25em 1em;
        cursor: pointer;
        margin-bottom: 0 !important;
        border-left: 1px solid $color-gray-light-grey;
        border-right: 1px solid $color-gray-light-grey;
        border-bottom: 1px solid $color-gray-light-grey;
        transition: color .25s ease-in-out;

        &:hover {
            color: $color-green-olive;
        }
    }
}
