.filter-controls {
    --gutter: 15px;
    background-color: $colors__white;

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        // margin: 0 calc(var(--gutter) * -1);
        padding-top: 1em;
        padding-bottom: 1em;

        @include media('>=tablet-portrait') {

        }
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 18px;
    }

    &__toggles-column {
        margin: var(--gutter) 0;
        order: 3;
    }

    &__filters-column {
        width: 100%;
        order: 4;
    }

    
    &__header-column {
        flex-shrink: 0;
        max-width: 25%;
        padding-right: 10px;
        margin: var(--gutter) 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // padding-bottom: 28px;
        order: 1;

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;
        }
    }

    &__filter-item {
        margin-bottom: 30px; 

        label {
            @include fonts__sans-serif-medium;
            font-size: 28px;
            color: $colors__blue;
        }
    }

    &__title-icon {
        width: 16px;
        height: 10px;
        transition: transform 0.25s;
        
        &.active {
            transform: rotateX(180deg);
        }
    }

    &__search-column {
        flex-grow: 1;
        max-width: 74.5%;
        width: 100%;
        margin: var(--gutter) 0;
        order: 2;
    }

    .location-filter {
        &__search-column {
            .location-filter__filter-item {
                display: none;
            }
        }
    }

    &__search-box {
        display: flex;
    }

    & &__search-input {
        height: 100%;
    }

    &__search-input {
        height: 37px;
        &#{&} {
            height: 37px;
        }
    }
    &__search-icon {
        width: px-to-em(20px);
        height: px-to-em(20px);
    }

    &__filter-toggle {
        & + & {
            margin-top: 1em;
        }
    }

    &__submit {
        background-color: $colors__blue;
        color: $colors__white;
        padding: 10px;
        height: 37px;

        svg {
            height: 18px;
            width: 18px;
        }
        
        &:focus {
            outline: thin dotted $colors__blue;
        }
    }
    &--locations &{
        &__search-column .filter-controls__filter-item,
        &__search-or{
            display: none;
        }
    }

    @include media('>=tablet-portrait') {
        &__header-column,
        &__search-column,
        &__toggles-column,
        &__filters-column {
            order: initial;
        }
        &__header-column {
            max-width: calc(((4/12) * 100%) - var(--gutter));
        }
        &__title {            
            @include fonts__sans-serif-bold;
            font-size: 24px;
        }
        &__search-column {
            max-width: calc(((4/12) * 100%) - var(--gutter));
            // order: initial;
            float: right;
        }

        &__toggles-column {
            display: flex;
            flex-grow: 1;
            padding-left: 2em;
        }

        &__filter-toggle {
            & + & {
                margin-top: 0;
                margin-left: 1em;
            }
        }

        $drawer-gutter: 16px;
        &__drawer {
            display: flex;
            margin-left: $drawer-gutter * -0.5;
            margin-right: $drawer-gutter * -0.5;

            &--spaced {
                margin-top: px-to-em(40px);
            }

            &--three-column {
                justify-content: space-between;
                padding-right: 60px;
            }
        }

        &__drawer--three-column & {
            &__filter-item {
                width: calc(30.3333% - #{$drawer-gutter});    
            }    
        }

        &__filter-item {
            width: calc(25% - #{$drawer-gutter});
            margin: 0 $drawer-gutter/2 $drawer-gutter; 
        }

        &__title--desktop-expanded {
            cursor: default;
        }

        &__title--desktop-expanded &{
            &__title-icon {
                display: none;
            }
        }

        &__drawer {
            &--desktop-expanded {
                height: auto !important;
            }
        }

        &--locations & {
            &__search-column {
                display: flex;
                align-items: center;
                max-width: calc(((8/12) * 100%) - var(--gutter));

                .filter-controls__filter-item {
                    display: block;
                }
            }

            &__search-or {
                @include fonts__sans-serif-medium;
                margin: 0 0.5em;
                display: block;
            }
            &__filter-item {
                margin: 0;
            }

            &__filter-item,                
            &__search-box {                
                width: calc(50% - 40px); // 40 width of Or
            }

            &__drawer,
            &__toggle-icon {
                display: none;                
            }

            &__title {
                cursor: default;
            }
        }

    }
}