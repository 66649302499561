p button,
p .button,
p .btn,
p .btn-default {
    margin: inherit;
}


.btn-set-preferences {
    margin-top: 1em;
    border-radius: 0;
    width: 100%;
    font-size: 1.1em;
    background-color: $color-orange-santa-fe;
    border: none;
    box-shadow: none;
}

.btn-go {
    margin: 0;
    height: px-to-em(45px);
    font-size: 1em;
    border-radius: 0;
    padding: .65em .75em;
    background-color: $color-orange-santa-fe;
    border: none;
    box-shadow: none;

    &:hover {
        background-color: $color-orange-rich-gold !important;
    }
}

form {
    button, .button, submit {
        width: 100%;
    }
}
