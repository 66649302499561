.event-detail {
    h2 {
        @include fonts__serif-regular;
        font-size: 38px;
    }
    h5 {
        @include fonts__sans-serif-medium;
        margin-bottom: 10px;
        font-size: 24px;
    }
    button {
        margin-bottom: 0;
    }
    .event-hero {
        width: 100%;
        background-color: $colors__orange--saffron;

        &.green {
            background-color: $colors__green;

            &::before {
                background-color: $colors__green--dark;
            }
        }

        &.teal {
            background-color: $colors__teal;

            &::before {
                background-color: $colors__teal--dark;
            }
        }

        &::before {
            content: '';
            background-color: $colors-orange-dark;
            height: 38px;
            display: block;
        }

        &__content {
            max-width: 910px;
            margin: auto;
            padding-top: 45px;
            padding-bottom: 45px;
        }
    
        &__title {
            --page-hero__title-size: 32px;
            font-size: var(--page-hero__title-size);
            text-align: center;

            @include media('>=tablet-portrait') {
                --page-hero__title-size: 52px;
            }
        }
    }

    &__hero-img {
        img {
            width: 100%;
            margin-bottom: 1em;
        }
    }

    .event-details,
    // for details inside accordions
    .accordion-container .event-details {
        margin-bottom: 1em;

        &.past-event {
            .event-details__content {
                background-color: $colors__gray--d8;
            }
        }

        &__title {
            @include fonts__eyebrow;
        }

        &__content {
            background-color: $colors__teal--lightest;
            display: flex;
            padding: 16px 27px;
            flex-wrap: wrap;

            @media print {
                flex-wrap: nowrap;
            }
        }
        
        &__item {
            flex: 1 1 100%;
            @include media('>=tablet-portrait') {
                flex: 1 0 0;
            }
        }

        &__item-list,
        ul {
            list-style-type: none;
            padding: 0;
            margin: 1em;
            display: flex;
            align-items: flex-start;
            li {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @include media('>=tablet-portrait') {
                margin: 0;
                display: block;
            }
            @media print {
                flex-wrap: wrap;
            }
        }

        &__item-list-content {
            padding-left: 1em;
            @media print {
                padding-left: 0;
                flex: 1 1 100%;
            }
            @include media('>=tablet-portrait') {
                padding-left: 0;
            }
        }

        &__item-title,
        strong {
            @include fonts__sans-serif-bold;
            font-size: 16px!important;
            font-weight: 700!important;
        }

        &__item-meta,
        ul li {
            @include fonts__sans-serif-medium;
            font-size: 14px;
            margin-left: 0;
        }

        &__icon {
            max-height: 40px;
        }
    }
    &__main-copy {
        margin-bottom: 30px;
    }

    .accordion-container .event-details {
        margin-left: -18px;
        margin-right: -18px;
        margin-top: -1em;
    }

    // author bios inside accordions
    .accordion-container {
        .author-bio {
            display: flex;
            align-items: center;

            figure {
                margin-bottom: 0;
                img {
                    width: 100%;
                    max-width: none;
                    height: auto;
                    margin-right: 0;
                }
            }

            &__info {
                a {
                    display: block;
                    text-decoration: underline;
                }

                span {
                    @include fonts__eyebrow;
                    display: block;
                    font-size: 12px;
                }
            }
        }
    }

    

    &__cta {
        width: 100%!important;
        background-color: $colors__teal--lightest!important;
        padding: 32px 48px;
        margin: 40px 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        
        @include media('>=tablet-portrait') {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    &__cta-info {
        h4 {
            @include fonts__sans-serif-bold;
            font-size: 24px;
        }
        margin-bottom: 1em;
        text-align: center;
        @include media('>=tablet-portrait') {
            margin-bottom: 0;
            text-align: left;
        }
    }

    &__cta-button {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        background-color: transparent;
        border: 2px solid $colors__blue;
        color: $colors__blue;
        border-radius: 4px;
        padding: 10px 24px;
        flex: 1 1 100%;
        text-align: center;
        margin-left: 0;
        @include media('>=tablet-portrait') {
            text-align: left;
            flex: 0 1 auto;
            margin-left: 1em;
        }

        &:hover {
            text-decoration: none;
            color: $colors__white;
            background-color: $colors__blue;
        }
    }
    &__people-container {
        display: flex;
        flex-wrap: wrap;
        .people-card {
            flex: 0 1 190px;
            margin-right: 1em;
        }
    }

    &__cancellation-policy {
        @include fonts__sans-serif-bold;
        font-size: 12px;
        font-style: italic;
        text-decoration: underline;
    }

    &__show-more-label {
        @include fonts__sans-serif-medium;
        font-size: 16px;
        margin-top: 16px;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: '';
            display: inline-block;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='9px' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E61F65A17-1E11-4AB9-A1CC-BEDBC54F0C0D@3x%3C/title%3E%3Cg id='MVP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='D---Homepage-with-Banner' transform='translate(-642.000000, -130.000000)' stroke='currentColor' stroke-width='2'%3E%3Cg id='Navigation/Desktop/Main' transform='translate(0.000000, 54.000000)'%3E%3Cg id='Group-9' transform='translate(487.000000, 67.000000)'%3E%3Cpolygon id='Stroke-3-Copy-6' transform='translate(162.000000, 13.000000) rotate(-270.000000) translate(-162.000000, -13.000000) ' points='159 7 165 12.9991875 159 19 165 12.9991875'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-size: 20px 12px;
            height: 12px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 1em;
            transition: .3s ease all;
        }

        &.active {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &__materials {

    }

    // for materials list inside accordions
    .accordion-container &__materials {
        // margin-left:-1em;
        // margin-right:-1em;
        // padding: 16px 27px;
        margin-bottom: 0;

        li {
            margin-left: 0;
        }
    }

    &__materials-list,
    .accordion-container &__materials ul {
        &#{&} {
            padding-left: 0;
        }
        li {
            list-style-type: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            margin-left: 0;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                text-decoration: underline;
            }
        }
    }

    &__materials-icon,
    .accordion-container img {
        max-width: 50px;
        margin-right: 1em;
    }

    &__register-now {
        background-color: $colors__teal--lightest;
        color: $colors__blue;
        padding: rem(20px);
        margin-top: rem(40px);

        h3 {
            @include fonts__sans-serif-bold();
        }

        @include media('>=tablet-landscape') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: rem(30px) rem(40px);
            margin-top: rem(48px);

            &-content {
                padding-right: 9%;
                flex-grow: 1;
            }

            &-cta {
                flex-shrink: 0;
                max-width: rem(200px);

                .call-to-action--section-margin {
                    margin-top: 0;
                }
            }
        }
    }

    &__hubspot {
        margin-top: 30px;
        scroll-margin-top: 4.5em; //mobile menu height
        
        @include media('>=tablet-portrait') {
            scroll-margin-top: 8.3125em; //desktop menu height
        }
    }

    &__hubspot-footer {
        margin-top: 20px;
    }
}

.event-series {
    .accordion-container {
        .article {
            background-color: $colors__teal--light;
        }
    }
}

// author bios in mobile speakers display
.mobile-speakers {
    margin-bottom: 1em;
    display: block;

    .author-bio__info {
        display: flex;
        align-items: center;
    }

    @include media('>=tablet-portrait') {
        display: none;
    }
}