.careers-header {
    padding: px-to-em(10px) 0;
    /*border-bottom: 7px solid $color-brown-cocao-brown;*/
    background-color: $color-white;
    position: relative;
    z-index: 5; //position this above careers nav

    #logo a {
        height: 57px;
        width: 57px;
        margin: 0 auto;
        display: block;
    }

    .social-image {
        display: none;
    }
}

@media print {
    .careers-header {
        border-bottom: 1px solid $color-brown-cocao-brown !important;
        padding-bottom: 10px !important;
    }

    #logo .header-image {
        display: block !important;
    }

    .page-hero-image.hero-careers-landing {
        display: none !important;
        width: 0px !important;
        height: 0px !important;
    }
}
