// Meant to be used with components where client uploads icons
.svg-icon {
    display: block;
    width: 68px;
    height: 68px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    image {
        width: 100%;
        height: 100%;
    }

    @include media('<=tablet-portrait') {
        &__career-tile {
            display: none!important;
        }
    }
}
