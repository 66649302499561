.content-container {
    @include container;

    .content-left {
        @include span(6);
    }

    .content-right {
        @include span(6 last);
    }

    @include bp-max($bp-desktop) {
        .content-left, .content-right {
            display: block;
            width: 100%;
        }
    }
}
