.jump-link {
    --jump-link-margin-top: -131;

    margin-top: calc(var(--jump-link-margin-top)*1px);
    position: absolute;
}

div:has(.global-header--simple) .jump-link {
    --jump-link-margin-top: -114;
}
