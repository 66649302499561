.splash-bar {
    .splash-bar-image {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        display: block;
    }

    &.flip-splash {
        .splash-bar-image {
            transform: translateX(-50%) rotate(180deg);
        }
    }
}
