.detail-header-legacy.blue {
    background-color: $color-blue-riptide;
}

.detail-header-legacy {
    background-color: $color-blue-riptide;
    margin-bottom: 15px;
    padding-bottom: 0px;
    display: table;
    line-height: .50;
    width: 100%;

    figure {
        margin-bottom: 0px;
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        max-width: 358px; /*support for larger images to fit into the design*/
    }

    div {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        padding: 1em;

        h1, h6, p {
            color: $color-dark-blue-footer;
        }

        h1 {
            font-size: 33px;
            line-height: 1.25;
        }

        p {
            font-size: 1.05em;
            line-height: 1.25;
            margin: 0px;
            padding: 0px;
        }
    }

    @include bp-max(550px) {


        figure.featured-image {
            display: block;
            margin-bottom: 0px;
            vertical-align: middle;
            width: 100%;
            background-color: $color-white;

            img {
                display: block;
                margin: 0px auto;
            }
        }

        div {
            display: block;
            vertical-align: middle;
            width: 100%;
            padding: 1em;
        }
    }

    @include bp-max(450px) {
        figure {
            margin-bottom: 0px;
            /*display: table-cell;
            vertical-align: middle;*/
            width: 100%;
            display: block;
        }
    }
}

@media print {
    .detail-header-legacy {
        width: 100% !important;
        padding: 0 !important;
        text-align: left !important;


        h1 {
            margin: 0px !important;
            padding: 0px !important;
        }

        div {
            display: table-cell;
            vertical-align: middle;
            padding: 1em;

            h1, h6, p {
                color: $color-black !important;
            }
        }
    }

    .detail-header-legacy.blue {
        background-color: $color-white !important;
    }
}
