.highlights {
    background-color: $colors__gray--f7;
    padding: 30px 0;
    
    @include media('>=tablet-portrait') {
        background-color: $colors__teal--lightest;
        padding: 64px 0 55px 0;
    }
    
    &__title-container {
        text-align: center;
    }

    &__title {
        margin-bottom: 30px;
        @include media('>=tablet-portrait') {
            margin-bottom: 50px;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -1em;
        margin-right: -1em;
    }

    &__item {
        flex: 1 1 100%;
        margin: 0 1em 1em 1em;
        list-style-type: none;
        text-align: center;
        color: $colors__blue;
        background-color: $colors__teal--light;
        padding: 1em 2em;

        &:last-child {
            margin-bottom: 0;
        }
        
        @include media('>=tablet-portrait') {
            flex: 1 1 calc(25% - 2em);
            margin-bottom: 0;
            background-color: transparent;
            padding: 0;
        }
    }

    &__item-stat {
        @include fonts__sans-serif-black;
        line-height: 1.25;
        font-size: 45px;
        @include media('>=tablet-portrait') {
            font-size: 60px;
        }
    }

    &__item-stat--sm {
        @include fonts__sans-serif-bold;
        font-size: 24px;
        strong {
            @include fonts__sans-serif-black;
        }
        
        @include media('>=tablet-portrait') {
            font-size: 30px;
        }
    }

    &__item-desc {
        font-size: 16px;
        @include media('>=tablet-portrait') {
            font-size: 20px;
        }
    }
}