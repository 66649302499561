.global-header-search {
    --global-header-search__button-size: 48px;
    --global-header-search__padding: 8px #{$spacing__gutter-width};


    position: absolute;
    top: auto;
    left: 0;
    width: 100%;
    background-color: rgba($colors__black, 0.3);
    padding: var(--global-header-search__padding);
    z-index: 1;

    &__form {
        display: flex;
        max-width: 730px;
        margin-left: auto;
        margin-right: auto;
    }
    
    &__input.global-header-search__input {
        width: calc(100% - var(--global-header-search__button-size));
        height: auto;
        padding: 8px 12px;
        background: $colors__white !important;   // override legacy styles
    }

    &__submit {
        width: var(--global-header-search__button-size);
        height: var(--global-header-search__button-size);
        background-color: $colors__blue;
    }

    &__submit-icon {
        $size: 24px;
        height: $size;
        width: $size;
        color: $colors__white;
    }

    @include media('>=global-header__desktop') {
        --global-header-search__padding: 16px #{$spacing__gutter-width};
    }
}
