@mixin call-to-action-styles($className: '.call-to-action') {
    --call-to-action__color: #{$colors__white};
    --call-to-action__bg-color: #{$colors__blue};
    --call-to-action__border-color: #{$colors__blue};
    --call-to-action__hover-bg-color: #{rgba($colors__blue, 0)};
    --call-to-action__hover-color: #{$colors__blue};
    --call-to-action__width: 100%;

    &--outline {
        --call-to-action__color: #{$colors__blue};
        --call-to-action__bg-color: #{rgba($colors__blue, 0)};
        --call-to-action__border-color: #{$colors__blue};
        --call-to-action__hover-bg-color: #{$colors__blue};
        --call-to-action__hover-color: #{$colors__white};

        &.call-to-action {
            &:hover {
                background-color: var(--call-to-action__hover-bg-color);
                --call-to-action__color: #{$colors__white};
            }
        }
        // renders as call-to-action--outline-white
        &-white {
            --call-to-action__color: #{$colors__white};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__white};

            &:hover {
                background-color: var(--call-to-action__hover-bg-color);
                --call-to-action__color: #{$colors__blue};
            }
        }
    }

    &--section-margin {
        --call-to-action__margin-top: 32px;
    }

    @include font-primary-medium-bold;
    display: inline-block;
    padding: 7px 24px;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    border: 2px solid var(--call-to-action__border-color);
    background-color: var(--call-to-action__bg-color);
    border-radius: 4px;
    @include transition(background-color, color);
    margin-top: var(--call-to-action__margin-top);
    width: var(--call-to-action__width);
    height: auto; //override existing button styles
    margin-bottom: 0; //override existing button styles
    // using double classes to be more specific than styles in .rte when this component is used there
    &#{$className} {
        color: var(--call-to-action__color);
    }

    &:after {
        content: none; // override existing button styles
    }

    &:hover {
        color: var(--call-to-action__hover-color);
        background-color: var(--call-to-action__hover-bg-color);
        text-decoration: none; // override existing button styles
    }

    &#{$className}:hover {
        text-decoration:none;
    }

    &:focus {
        outline: thin dotted;
        outline-color: #{$colors__blue};
    }

    @include media('>=call-to-action__desktop') {
        --call-to-action__width: auto;

        &__centering-container {
            text-align: center;
        }

        &--size-large {
            width: 100%;
        }
    }

    @include media('>=tablet-portrait') {
        font-size: 20px;

        &--size-large {
            font-size: 24px;
            line-height: 1.2;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    @include media('>=tablet-landscape') {
        &--section-margin {
            --call-to-action__margin-top: 40px;
        }
    }
}

.call-to-action {
    @include call-to-action-styles;
    // Use solely on Button Elements
    &--centered {
        margin-left: auto;
        margin-right: auto;
        display: block; 
    }
}
// RTE USED
.call-to-action-outline {
    --call-to-action__color: #{$colors__blue};
    --call-to-action__bg-color: #{rgba($colors__blue, 0)};
    --call-to-action__border-color: #{$colors__blue};
    --call-to-action__hover-bg-color: #{$colors__blue};
    --call-to-action__hover-color: #{$colors__white};

    @include fonts__sans-serif-bold;
    display: inline-block;
    padding: 7px 24px;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    border: 2px solid var(--call-to-action__border-color);
    background-color: var(--call-to-action__bg-color);
    border-radius: 4px;
    @include transition(background-color, color);
    margin-top: var(--call-to-action__margin-top);
    width: var(--call-to-action__width);

    height: auto; //override existing button styles
    margin-bottom: 0; //override existing button styles

    // using double classes to be more specific than styles in .rte when this component is used there
    &.call-to-action-outline {
        color: var(--call-to-action__color);
    }

    &:after {
        content: none; // override existing button styles
    }

    &:hover {
        color: var(--call-to-action__hover-color);
        background-color: var(--call-to-action__hover-bg-color);
        text-decoration: none;  // override existing button styles
    }

    &:focus {
        outline: thin dotted;
        outline-color: #{$colors__blue};
    }

    @include media('>=call-to-action__desktop') {
        --call-to-action__width: auto;

        &__centering-container {
            text-align: center;
        }
    }
}
// RTE USED
.call-to-action-white-outline {
    --call-to-action__color: #{$colors__white};
    --call-to-action__bg-color: #{rgba($colors__blue, 0)};
    --call-to-action__border-color: #{$colors__white};
    --call-to-action__hover-bg-color: #{$colors__white};
    --call-to-action__hover-color: #{$colors__blue};


    @include fonts__sans-serif-bold;
    display: inline-block;
    padding: 7px 24px;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    border: 2px solid var(--call-to-action__border-color);
    background-color: var(--call-to-action__bg-color);
    border-radius: 4px;
    @include transition(background-color, color);
    margin-top: var(--call-to-action__margin-top);
    width: var(--call-to-action__width);

    height: auto; //override existing button styles
    margin-bottom: 0; //override existing button styles

    // using double classes to be more specific than styles in .rte when this component is used there
    &.call-to-action-white-outline {
        color: var(--call-to-action__color);
    }

    &:after {
        content: none; // override existing button styles
    }

    &:hover {
        color: var(--call-to-action__hover-color);
        background-color: var(--call-to-action__hover-bg-color);
        text-decoration: none;  // override existing button styles
    }

    &:focus {
        outline: thin dotted;
        outline-color: #{$colors__blue};
    }

    @include media('>=call-to-action__desktop') {
        --call-to-action__width: auto;

        &__centering-container {
            text-align: center;
        }
    }
}

.rte {
    .call-to-action {
        &:hover {
            color: var(--call-to-action__hover-color);
            background-color: var(--call-to-action__hover-bg-color);
            text-decoration: none !important;  // override existing button styles
        }
    }

    .call-to-action-outline,
    .call-to-action-white-outline {
        &:hover {
            text-decoration: none !important;  // override existing button styles
        }
    }

    .cta-component__button {
        &:hover {
            text-decoration: none;
        }
    }
}
