.four-block-copy {
    --heading-four-block: 45px;
    --copy-four-block: 18px;
    --copy-four-block-large: 24px;
    
    @include spacing__component-y-margin;
    
    background-color: $colors__gray--f7;
    text-align: center;

    &__heading {
        @include fonts__sans-serif-bold;
        font-size: var(--heading-four-block);
        margin-bottom: 5px;
        line-height: 1;
        color: inherit;
    }
    &__copy {
        font-size: var(--copy-four-block);
        line-height: 1.15;

        &--large {
            @include fonts__sans-serif-bold;
            font-size: var(--copy-four-block-large);
        }
    }

    &__item {
        color: $colors__blue;
        text-align: center;
        background-color: $colors__teal--lightest-alt;
        padding: 20px;

        & + & {
            margin-top: 12px;
        }
    }

    $grid-gutter: 16px;

    @include media('>=tablet-portrait') {
        --heading-four-block: 50px;
        --copy-four-block: 20px;
        --copy-four-block-large: 29px;

        background-color: $colors__teal--lightest-alt;

        .section-name {
            margin-bottom: rem(25px);
        }

        
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: $grid-gutter * -0.5;
            margin-right: $grid-gutter * -0.5;
        }

        &__item {
            width: calc(50% - #{$grid-gutter});
            margin: 0 $grid-gutter/2 $grid-gutter;
            padding: 20px 0 0;
            & + & {
                margin-top: 0
            }
        }
    }

    @include media('>=tablet-middle') {
        &__list {
            flex-wrap: nowrap;
        }
        &__item {
            width: calc(25% - #{$grid-gutter});
            margin-bottom: 0;
        }
    }
}