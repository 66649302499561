.copy-block-with-circles {

    .icon-copy-block__list {
        margin-top: 56px;
    }

    .icon-copy-block__image-container {
        --copy-block-with-circles__circle-size: 180px;
        --svg-icon-size: 60px;
        --icon-copy-size: 17px;

        width: var(--copy-block-with-circles__circle-size);
        height: var(--copy-block-with-circles__circle-size);
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
          width: var(--svg-icon-size);
          height: var(--svg-icon-size);
        }

        .icon-copy-block__title {
            font-size: var(--icon-copy-size);
            font-weight: 600;
            line-height: 1.2;
        }
    }
}

@include media('<=tablet-portrait') {

  .icon-copy-block__heading  {

    .section-headline-2 {
      font-size: 32px;
    }
  }

  .copy-block-with-circles {
    padding: 40px 9px 20px;
  }
}
