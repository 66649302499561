﻿.client-login-wrapper {
    .client-login {
        color: $color-brown-cocao-brown;

        @include bp-min(951px) {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        @include bp-max(950px) {
            &:hover {
                background-color: $color-main-nav-hover;
                color: $color-white;
                text-decoration: none;
            }
        }
    }

    .client-links {
        background: none repeat scroll 0 0 $color-white;
        border: 1px solid $color-gray-gallery-grey;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        margin-top: 5px;
        margin-left: -100px;
        padding: 10px;
        text-align: left;
        max-width: 200px;
        width: 100%;
        transition: all $transition-timing-primary;

        &:before {
            border-color: transparent transparent $color-white;
            border-image: none;
            border-style: inset inset solid;
            border-width: 6px;
            content: "";
            display: block;
            height: 0;
            left: 150px;
            position: absolute;
            top: -12px;
            width: 0;
            z-index: 99;
        }

        &:after {
            border-color: transparent transparent $color-gray-gallery-grey;
            border-image: none;
            border-style: inset inset solid;
            border-width: 7px;
            content: "";
            display: block;
            height: 0;
            left: 149px;
            position: absolute;
            top: -14px;
            width: 0;
            z-index: 98;
        }

        &.open {
            visibility: visible;
            opacity: 1;
        }

        @include bp-min(951px) {
            li {
                float: none;
                margin-top: 10px;
                list-style: none;
                font-size: 1em;
                display: block;
            }
        }

        @include bp-max(950px) {
            li {
                font-size: .85em;
                line-height: 1;


                a {
                    padding: .25em .5em;
                }
            }
        }

        li:before {
            content: "" !important;
            margin-left: 0px;
            display: none;
        }

        @include bp-max($bp-menu) {
            max-width: none;
        }
    }
}
