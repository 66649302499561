.industries-services-segments {
    $spacing: 1em;
    @include container;

    .left-column {
        @include span(6);
    }

    .right-column {
        @include span(6 last);
    }

    .filters {
        background-color: $color-gray-gallery-grey;
        padding: 1em;
        min-height: 80px;
        /*.filter-links{color:$color-brown-cocao-brown;}*/
        .filter {
            margin-left: 1em;
            line-height: 1em;
            margin-bottom: .75em;
            list-style: none;
            font-size: .85em;
        }

        .filter-label {
            cursor: pointer;

            h2 {
                color: $color-brown-cocao-brown;
                font-weight: normal;
                font-size: 1em;
                display: inline;

                a {
                    color: $color-brown-cocao-brown;
                }
            }

            &:after {
                font-size: .8em;
                @include font-ionicons($ionicon-var-plus-round);
                float: right;
            }
        }

        &.no-children {
            .filter-label {
                cursor: default;

                &:after {
                    content: "";
                }
            }
        }

        .filter-selects {
            margin-top: $spacing/2;
            display: none;
        }
    }

    .filters {
        margin-top: $spacing;
        width: 48.75%;
        display: inline-block;
        vertical-align: top;
        min-height: 80px;

        @include bp-max($bp-ipad) {
            display: block;
            width: 100%;
        }

        &:nth-child(even) {
            margin-left: .8em;

            @include bp-max($bp-ipad) {
                margin-left: 0;
            }
        }

        &.show-filters {
            &:not(.no-children) {
                background-color: $color-gray-white;

                .filter-label {
                    &:after {
                        @include font-ionicons($ionicon-var-minus-round);
                    }

                    h2 {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
