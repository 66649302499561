.not-found-component {
    background-color: $colors__teal;
    padding: 40px 0;

    @include media('>=tablet-portrait') {
        padding: 96px 0;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include media('>=tablet-portrait') {
            flex-wrap: nowrap;
        }
    }

    &__col--right {
        flex: 1 1 100%;
        text-align: center;

        @include media('>=tablet-portrait') {
            flex: 0 0 calc((100%/12) * 8);
        }
    }

    &__col--left {
        flex: 1 1 100%;
        margin-bottom: 24px;

        @include media('>=tablet-portrait') {
            flex: 0 0 calc((100%/12) * 4);
            margin-bottom: 0;
            padding-right: 30px;
        }
    }

    &__image {
        width: 100%;
        display: block;
        margin: auto;
        max-width: 145px;

        @include media('>=tablet-portrait') {
            max-width: none;
        }
    }

    &__title {
        @include fonts__sans-serif-light;
        font-size: 130px; // ???
        line-height: 1;
        margin-bottom: 24px;
        text-align: center;
        color: $colors__blue;

        @include media('>=tablet-portrait') {
            font-size: 230px; // ???
            line-height: 1;
        }
    }

    &__subtitle {
        @include fonts__serif-regular;
        font-size: 24px;
        line-height: 41px;
        margin-bottom: 32px;
        text-align: center;

        @include media('>=tablet-portrait') {
            font-size: 36px;
        }
    }

    &__text {
        @include fonts__sans-serif-light;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 40px;

        @include media('>=tablet-portrait') {
            font-size: 22px;
        }
    }

    &__cta-container {
        text-align: center;
    }

    &__cta {
        @include fonts__sans-serif-bold;
        @include transition(background-color, color);

        border: 2px solid $colors__blue;
        border-radius: 4px;
        padding: 9px 24px;
        font-size: 20px;
        line-height: 1.5;
        display: block;
        width: 100%;
        text-align: center;
        color: $colors__blue;

        &:hover {
            text-decoration: none;
            background-color: $colors__blue;
            color: $colors__white;
        }

        @include media('>=tablet-portrait') {
            width: auto;
            display: inline-block;
        }
    }
}

.error header {
    padding-top: 0!important; //override legacy styles
}
