.event-detail {    
    /* Style the buttons that are used to open and close the accordion panel */
    section.accordion-container {
        margin-bottom: 1.25em;
    }
    .accordion {
        margin-bottom: 1px;

        & + .article {
            margin-bottom: 1em;
        }
    }
}

div.article {
    padding: 0 18px;
    background-color: $colors__teal--lightest;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    opacity: 0;
    margin-bottom: 0px;

     p:last-child {
         margin-bottom: 24px;
     }

     h3 {
         @include fonts__serif-regular;
         font-size: 28px;
         color: $colors__blue;
         margin-bottom: 15px;
     }
}

div.article.show {
    opacity: 1;
    max-height: 10000px; /*Whatever you like, as long as its more than the height of the content (on all screen sizes) */
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: $colors__teal--lightest;
}

.speaker-info {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    img {
        border-radius: 4px;
    }
    &__info {
        padding-left: 16px;
        a {
            @include fonts__sans-serif-medium;
            font-size: 18px;
        }

        span {
            @include fonts__eyebrow;
            display: block;
            font-size: 12px;
        }
    }
}