.ad {
    p {
        color: $color-white;
        font-family: $font-family-franklin-gothic;
        font-weight: bold;
        font-size: 2em;
        margin-bottom: .25em;

        .font-jenna {
            font-size: 2.25em;
            font-weight: normal;
        }
    }

    figure {
        /*ADD THE BACKGROUND INLINE ON THE FIGURE TAG*/
        padding: 1.5em;
        background-size: cover;
        background-position: 50% 50%;

        .button {
            float: right;
        }
    }
}
