.service-tiles {
    --service-tiles__border-style: 6px solid #{$colors__teal};
    // --service-tiles__item-spacing: 8px;
    --service-tiles__label-font-size: 18px;

    $service-tiles__item-spacing-sm: 8px;
    $service-tiles__item-spacing-lg: 30px;

    position: relative;
    z-index: 1;

    &__items {
        list-style-type: none;
    }

    &__item {
        &:not(:first-child) {
            margin-top: $service-tiles__item-spacing-sm;
        }
    }

    &__link {
        border-left: var(--service-tiles__border-style);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        padding-left: 26px;
        box-shadow: 0 0 6px 0 rgba($colors__black, 0.2);
        background-color: $colors__white;

        @include transition(border-color);

        @include target-devices-with-hover {
            border-color: rgba($colors__teal, 0);
        }

        &:hover {
            text-decoration: none;  // Override legacy styles

            @include target-devices-with-hover {
                border-color: $colors__teal;
            }
        }

        &:focus {
            border-color: $colors__teal;
        }
    }

    &__service-icon {
        display: none;
    }

    &__label {
        @include fonts__sans-serif-bold;
        font-size: var(--service-tiles__label-font-size);
        line-height: 1.22;
        color: $colors__blue;
    }

    &__arrow {
        $service-tiles__arrow-width: 10px;

        width: $service-tiles__arrow-width;
        height: $service-tiles__arrow-width * 2;
        color: $colors__blue;
    }

    @include media('>=tablet-portrait') {
        // --service-tiles__item-spacing: 30px;
        --service-tiles__item-width: calc(50% - #{$service-tiles__item-spacing-lg} / 2);
        --service-tiles__label-font-size: 20px;
        --service-tiles__label-padding: 25px;

        &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            width: var(--service-tiles__item-width);

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        &__link {
            flex-direction: column;
            border-left: none;
            border-bottom: var(--service-tiles__border-style);
            padding: var(--service-tiles__label-padding);
            height: 100%;

            @include target-devices-with-hover {
                border-color: rgba($colors__teal, 0);
            }
        }

        &__service-icon {
            display: block;
            width: 100%;
            max-width: 66px;
            height: 66px;
            margin-bottom: 20px;
        }

        &__label {
            text-align: center;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__arrow {
            display: none;
        }
    }

    .section-headline {
        margin-bottom: 24px;
    }

    @include media('>=tablet-landscape') {
        
        @for $i from 1 through 5 {
            &__item:first-child:nth-last-child(#{$i}),
            &__item:first-child:nth-last-child(#{$i}) ~ &__item {
                --service-tiles__item-width: calc((100% / #{$i}) - #{$service-tiles__item-spacing-lg} * 3 / 4);
            }
        }

        &__item {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: 0;
            }
        }
    }
}
