.careers-detail {
    --layout-padding-top: 16px;
    background-color: $colors__white;

    .breadcrumbs { 
        background-color: transparent;
    }

    &__components {        
        padding-top: var(--layout-padding-top);

        > *:first-child {
            margin-top: 0;
            padding-top: 0;
        }
    }

    @include media('>=tablet-portrait') {
        &__components {
            --layout-padding-top: 32px;
        }
    }

    @include media('>=tablet-landscape') {
        &__components {
            --layout-padding-top: 40px;
        }
    }
}