div.update {
    background-color: $color-grey-very-light-grey;
    color: $color-brown-cocao-brown;
    padding: .50em;
    margin-bottom: .75em;
    font-style: italic;

    h1, h2, h3, h4, h5, h6 {
        color: $color-white !important;
        font-style: italic;
    }

    p {
        color: $color-brown-cocao-brown;
    }

    a {
        text-decoration: underline;
    }

    p:last-child {
        margin-bottom: 0px !important;
    }
}
