.location-card {
    --location-card__link-font-size: 14px;
    --location-card-title: 18px;

    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    &__header {
        @include fonts__sans-serif-bold;
        line-height: 1.1;
        font-size: var(--location-card-title);
        color: $colors__blue;
        display: block;        
    }

    &__info {
        padding: 20px 0 0 10px;
        font-size: 20px;
        line-height: 1.4;
        font-size: var(--location-card__link-font-size);
    }

    &__links {
        padding: 20px 0 20px 0;    

        .button {
            width: auto;
            font-size: 1em;
            padding-top: px-to-em(5px);
        }

        &--icon + &--icon {
            margin-top: px-to-em(10px);
        }
    }
    &__number {
        font-size: px-to-em(15px);
    }
    &__icon-wrapper {
        display: inline-block;
        width: px-to-em(48px);
        vertical-align: middle;
    }
    &__icon-phone {
        width: px-to-em(28px);
        height: px-to-em(28px);
    }

    &__icon-fax {
        width: px-to-em(32px);
        height: px-to-em(33px);
    }

    @include media('>=tablet-portrait') {
        --location-card__link-font-size: 16px;
        --location-card-title: 20px;

        &__info {
            padding-left: 0;
        }

        &__number {
            font-size: px-to-em(16px);
        }

        &__icon-wrapper {
            width: px-to-em(52px);
        }

        &__icon-phone {
            width: px-to-em(30px);
            height: px-to-em(30px);
        }
        &__icon-fax {
            width: px-to-em(35px);
            width: px-to-em(36px);
        }
    }
}
