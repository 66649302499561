.inspired-carousel-card {    
    background-color: $colors__green--light;
    max-width: px-to-em(264px);
    margin-left: auto;
    margin-right: auto;

    &__figure {
        width: 100%;
        height: px-to-em(232px);
        overflow: hidden;
        position: relative;
    }
    &__image {
        width: 100%;
        margin-bottom: 16px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
    }

    &__author {
        @include font-primary-medium-bold(18px);
        font-weight: normal;
        line-height: 1.1;
        margin-bottom: 0.5em;
    }

    &__title {
        @include fonts__sans-serif-medium;
    }

    &__testimonial {
        @include font-primary-light(14px);
        padding-top: 10px;
        font-style: italic;

        quotes: '\201c' '\201d';
        &:before { 
            content: open-quote; 
        }
        &:after { 
            content: open-quote; 
        }
    }

    &__content {
        padding: 2em 1em;
    }
}