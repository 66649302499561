.team-member-carousel-component {
    &__rte {
        margin-bottom: 24px;
    }

    @include media('>=tablet-portrait') {
        &__rte {
            margin-bottom: 36px;
        }
    }

    @include media('>=tablet-landscape') {
        &__rte {
            margin-bottom: 48px;
        }
    }
}
