.most-viewed-resource {
    --most-viewed-resource__content-padding: 48px 24px;
    --most-viewed-resource__background-padding: 154px;
    --most-viewed-resource__content-translateX: 0;
    --most-viewed-resource__content-translateY: -15%;
    --most-viewed-resource__tiles-translateY: 0;
    position: relative;

    .width-container {
        margin-bottom: 0px;
    }

    &__width-container {
        z-index: 2;
    }

    &__headline {
        text-transform: uppercase;
        padding: 0 0 30px 5px;
        font-size: 20px;
    }

    &__img-container {
        margin: 0 $spacing__gutter-width * -1;
        position: relative;
        z-index: 3;
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding-bottom: 62.4%;
    }

    &__content {
        padding: var(--most-viewed-resource__content-padding);
        text-align: center;
        background-color: $colors__white;
        z-index: 4;
        position: relative;
        transform: translate(var(--most-viewed-resource__content-translateX), var(--most-viewed-resource__content-translateY));

        &--text-align {
            &-right {
                text-align: right;
            }

            &-left {
                text-align: left;
            }

            &-center {
                text-align: center !important;
            }
        }

        .section-link {
            padding-top: 15px;
        }

        .section-headline {
            --section-headline__font-size: 28px;
        }

        .section-headline-small {
            --section-headline__font-size: 24px;
        }

        .section-body {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__background {
        min-height: 280px;
        height: calc(100% - var(--most-viewed-resource__background-padding));
        width: 100%;
        position: absolute;
        left: 0;
        top: var(--most-viewed-resource__background-padding);
        background-color: var(--most-viewed-resource__background-color);
    }

    &--theme {
        &-celadon {
            --most-viewed-resource__background-color: #{$colors__celadon};
        }

        &-celadontint {
            --most-viewed-resource__background-color: #{$colors__celadontint};
        }

        &-cloud {
            --most-viewed-resource__background-color: #{$colors__cloud};
        }

        &-home {
            --most-viewed-resource__background-color: #{$colors__teal};
        }

        &-navy {
            --most-viewed-resource__background-color: #{$colors__navy};
        }

        &-riptide {
            --most-viewed-resource__background-color: #{$colors__riptide};
        }

        &-riptidetint {
            --most-viewed-resource__background-color: #{$colors__riptidetint};
        }

        &-saffron {
            --most-viewed-resource__background-color: #{$colors__saffron};
        }

        &-saffrontint {
            --most-viewed-resource__background-color: #{$colors__saffrontint};
        }
    }

    @include media('>=tablet-landscape') {
        .section-headline {
            --section-headline__font-size: 32px;
        }

        .section-headline-small {
            --section-headline__font-size: 28px;
        }
    }

    @include media('>=tablet-portrait') {
        --most-viewed-resource__image-height: 364px;
        --most-viewed-resource__content-width: 40%;
        --most-viewed-resource__content-translateY: 32px;
        --most-viewed-resource__content-translateX: 100%;

        &__width-container {
            position: relative;
            padding-bottom: 3.5em;
        }

        &--align-right {
            --most-viewed-resource__image-right: 0;
            --most-viewed-resource__content-translateX: 0;
        }

        &__img-container {
            right: var(--most-viewed-resource__image-right);
            position: absolute;
            width: 60%;
            margin: 0;
        }

        &__content {
            min-height: 300px;
            width: 50%;
        }

        &__img {
            padding-bottom: var(--most-viewed-resource__image-height);
            position: relative;
            z-index: 1;
        }
    }
}
