.hero-banner {
    position: relative;
}

.notification-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: $color-brown-russet;
    color: $color-white;

    &__inner {
        position: relative;
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    &__text {
        font-size: 1.125em;
    }

    &__link {
        color: $color-white;
        text-decoration: underline;

        &:hover {
            color: $color-white;
        }
    }

    &__close-button {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    cursor: pointer;
    font-size: 1rem;
    }

    &.hide-banner {
        display: none;
    }

    @include bp-max($bp-ipad) {
        position: relative;

        &__link {
            display: block;
        }

        &__close-button {
            top: 18px;
            transform: translateY(0);
        }
    }
}

.component-hero {
    position: relative;
    height: 580px;
    margin: 0 0 2em;
    overflow: hidden;

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*background: none no-repeat center fixed;*/
        background-size: cover;
        background-position: center top;

        &--mobile {
            display: none;
        }

        &--tablet {
            display: none;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 35px 30px;
        background: rgba(255,255,255,0.7);
        // Standard
        > h1 {
            font-size: 3.438em;
            line-height: 1.2;
            font-weight: 300;
            color: $color-brown-spice;
            letter-spacing: .5px;
            @include font-primary-light;

            small {
                font-size: .471em;
                display: block;
            }
        }

        > h3 {
            font-size: 1.938em;
            line-height: 1.2;
            color: $color-brown-spice;
            text-transform: none;
            margin-bottom: 0;
            @include font-primary;
        }

        > a {
            font-size: 1.25em;
            margin-bottom: 0;
            margin-top: 1.125em;
        }
    }
    // Video
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        z-index: 0;
    }
    // Wrapper
    .page-wrapper {
        padding-top: 130px;
    }
    // Links
    .links {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background: rgba(64, 64, 65, .56);

        ul {
            display: inline-block;
            padding: 15px 0;
            margin: 0;

            li {
                display: inline-block;
                padding: 0 20px;
                margin: 0;
                position: relative;

                a {
                    color: $color-white;
                    font-size: 1.265em;
                    line-height: 24px;
                }

                & + li:before {
                    content: '';
                    position: absolute;
                    height: 20px;
                    width: 3px;
                    top: 2px;
                    left: -2px;
                    background: $color-white;
                }
            }
        }
    }
    // Variations
    // Form
    @include bp-min(476px) {
        &.form {
            .page-wrapper {
                width: 100%;
                height: 100%;
                display: table;
                padding-top: 0;

                > div {
                    padding: 5.65em 2em 2em;
                    display: table-cell;
                    @include span(8);
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    // Form Structure
                    ul {
                        list-style: none;

                        > li {
                            padding: 0;

                            & + li {
                                margin-top: 20px;
                            }

                            &:last-child {
                                margin-top: 50px;
                            }
                            // Inputs
                            input {
                                color: $color-white;
                                font-size: 1.313em;
                                background: none;
                                border: none;
                                border-bottom: 1px solid $color-white;
                                height: 45px;
                                border-radius: 0;

                                @include placeholder {
                                    color: $color-white;
                                }
                            }
                            // Button
                            button {
                                margin: 0 auto;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    @include bp-max(475px) {
        &.form {
            .page-wrapper {
                width: 100%;
                height: 100%;
                display: table;
                padding-top: 0;

                > div {
                    padding: 5.65em 2em 2em;
                    display: table-cell;
                    @include span(8);
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    // Form Structure
                    ul {
                        list-style: none;

                        > li {
                            padding: 0;

                            & + li {
                                margin-top: 20px;
                            }

                            &:last-child {
                                margin-top: 50px;
                            }
                            // Inputs
                            input {
                                color: $color-white;
                                font-size: .9em;
                                background: none;
                                border: none;
                                border-bottom: 1px solid $color-white;
                                height: 45px;
                                border-radius: 0;

                                @include placeholder {
                                    color: $color-white;
                                }
                            }
                            // Button
                            button {
                                margin: 0 auto;
                                display: block;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
    // Small
    &.small {
        height: 175px;
        margin: 0;
        box-shadow: none;
        background-position: 0px -50px;
    }
    // Responsive
    // Laptop
    @include bp-max($bp-desktop) {
        height: 580px;
        // Content
        &__content {
            > h1 {
                font-size: 3em;
            }

            > h3 {
                font-size: 1.75em;
            }
        }

        .page-wrapper {
            // Standard
            padding-top: 75px;
        }
        // Links
        .links {
            ul li a {
                font-size: 1.25em;
            }
        }
        // Variations
        // Small
        &.small {
            height: 320px;
        }
    }
    //tablet image spread
    @include bp-max(900px) {
        &__image {
            display: none;

            &--tablet {
                display: block;
            }
        }
    }
    // iPad/Phablet
    @include bp-max(767px) {
        height: 520px;

        &.hasVideo { // hide background on displays larger devices to avoid double flash as images load
            background-image: none !important;
        }


        &__content {
            width: 100%;
            padding: 20px 50px;
            position: absolute;
            bottom: 58px;

            h1 {
                font-size: 2.813em;
            }
        }
        // Content
        .page-wrapper {
            // Standard
            padding: 0;
            height: 100%;
        }
        // Links
        .links {
            ul li a {
                font-size: 1em;
            }
        }
        // Variations
        // Form
        &.form {
            .page-wrapper {
                > div {
                    width: 100%;
                }
            }
        }
        // Small
        &.small {
            height: 300px;
        }
    }
    // Tablet
    @include bp-max($bp-tablet) {
        height: 505px;
        margin: 0 0 4em;

        &__image {

            &--tablet {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }

        &__content {
            h1 {
                font-size: 2.813em;
            }

            h3 {
                font-size: 1.5em;
            }
        }
        // Content
        .page-wrapper {
            // Standard
            padding: 0;
        }
        // Links
        .links {
            bottom: 0;
            font-size: .85em;

            ul {
                li {
                    padding: 0 10px;

                    a {
                        font-size: 0.8em;
                        text-shadow: none;
                    }

                    & + li:before {
                        background: $color-white;
                        box-shadow: none;
                        width: 1px;
                    }
                }
            }
        }
        // Variations
        // Form
        &.form {
            height: auto;

            .page-wrapper {
                > div {
                    padding: 2em;
                }
            }
        }
        // Small
        &.small {
            height: 250px;
        }
    }

    @include bp-max(448px) {
        &__content {
            bottom: 86px;
        }
    }
    // Phone
    @include bp-max($bp-mobile) {
        height: 505px;
        margin: 0 0 5em;

        &__content {
            h1 {
                font-size: 2.813em;
            }
        }
        // Content
        .page-wrapper {
            // Standard
            padding: 0;
        }
        // Links
        .links {
            bottom: 0;

            ul > li {
                &:last-child:before {
                    display: none;
                }

                a {
                    font-size: 1em;
                }
            }
        }
        // Variations
        // Form
        &.form {
            .page-wrapper {
                > div {
                    padding: 1.25em;
                }
            }
        }
        // Small
        &.small {
            height: 200px;
        }
    }
}

.hero-carousel {
    @include bp-min($bp-ipad) {
        .page-wrapper {
            padding-left: 5em;
            padding-right: 5em;
        }
    }

    @include bp-min($bp-wide) {
        .page-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include bp-max($bp-ipad) {

        .component-hero {
            margin-bottom: 40px;

            h1 {
                font-size: 2em;
            }

            h3 {
                font-size: 1.5em;
            }

            .links {
                bottom: 0;
            }
        }

        &.slick-slider {
            .fa-chevron-left, .fa-chevron-right {
                font-size: 38px;
            }
        }

        .fa-chevron-left {
            left: 0.25em !important;
        }

        .fa-chevron-right {
            right: 0.25em !important;
        }

        .slick-dots {
            visibility: hidden;
        }
    }

    .fa-chevron-left, .fa-chevron-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
        color: $color-brown-spice;
        opacity: 0.45;
        font-size: 4em;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    .fa-chevron-left {
        left: .35em;
    }

    .fa-chevron-right {
        right: .35em;
    }

    .slick-dots {
        display: flex;
        list-style: none;
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);

        li {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            &.slick-active {
                button {
                    background: rgba(64,64,65,0.5);
                    height: 18px;
                    width: 18px;
                }
            }
        }

        button {
            font-size: 0;
            line-height: 0;
            height: 14px;
            width: 14px;
            background: $color-white;
            border: 1px solid #979797;
            border-radius: 50%;
            padding: 0;

            &:after {
                display: none;
            }
        }
    }
}
