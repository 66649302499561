.careers-cla-locations {
    // padding: 1em 0 2em;
    padding: 3em 0;

    .section-header {
        margin: 0;
        font-size: 1em;
    }

    .header-style {
        @include font-primary-bold;
        font-weight: normal;
        color: $color-violet-jagger;
        font-size: 45px;
        
        &:first-of-type {
        }
        
        &:nth-of-type(2) {
            margin-left: .1em;
        }
        
        @include bp-min($bp-ipad) {
            font-size: 5.25em;
        }
    }

    .section-copy {
        color: $color-brown-spice;
        margin-bottom: 1.25em;
    }

    .locations-image {
        display: block;
        margin-top: px-to-em(20px);
        max-width: 100%;
    }

    @include bp-max($bp-ipad) {
        .locations-image {
            display: none;
        }
    }

    a img {
        width: 100%!important; //override inline image styles
        height: auto!important;
    }
}
