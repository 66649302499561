//------------------------------------------
//              Breakpoints
//------------------------------------------
$bp-wide: 1200px;
$bp-desktop: 990px;
$bp-menu: 950px;
$bp-ipad: 768px;
$bp-tablet: 550px;
$bp-mobile: 414px;

//------------------------------------------
//              File Paths
//------------------------------------------
$file-path-images: '/Content/images/';
$file-path-fonts: '/Content/fonts';
// font-awesome font path.
$fa-font-path: $file-path-fonts;

//------------------------------------------
//              Math Constants
//------------------------------------------

$sqrt-2: 1.41421356237;
$image-ratio: ( 258 / 400 );

//------------------------------------------
//              Transitions
//------------------------------------------

$transition-timing-primary: .3s;
$transition-ease-primary: cubic-bezier(0.86, 0, 0.07, 1);


//------------------------------------------
//              z-index
//------------------------------------------

//------------------------------------------
//      Component/Module Variables
//------------------------------------------
