.clienthq-cta {

    &--Blue {
        background-color: $colors__blue;
        // & a:hover {
        //     color: $color-blue-shakespear;
        // }
    }

    &--Green {
        background-color: $color-green-olive;
        // & a:hover {
        //     color: $color-green-olive;
        // }
    }

    &--Orange {
        background-color: $colors__red;
        // & a:hover {
        //     color: $colors__red;
        // }
    }

    &--Grey {
        background-color: $color-grey-border;
    }


    &__container {
        display: flex;
        margin-top: 25px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 8em;
        padding: 1.5em;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, .3);
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        color: $colors__white;
    }

    &__subtitle {
        @include fonts__sans-serif-light;
        font-size: 1em;
        color: $colors__white;
    }

    &__ButtonSection {
        margin-top: 10px;

        & a:hover {
            /*color: #819F3D !important;*/
            color: $colors__white !important;
            // background-color: #ffff !important;
            text-decoration: none !important;
        }
    }

    &__buttonlink {
        @include fonts__sans-serif-medium;
        color: $colors__white;
        background: transparent;
        font-size: 1em;
        margin-top: 1em;
        padding-right: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        text-decoration: none;

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '\f105';
            font-family: 'FontAwesome';
            font-size: 1.25em;
            color: $colors__blue;
            background-color: $colors__white;
            border-radius: 100%;
            text-align: center;
            line-height: 24px;
            display: block;
            height: 24px;
            width: 24px;
        }
    }

    &__icon {
        margin-bottom: 10px;
        display: block;
    }
}
