.clienthq-login {
    /*text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 60px 0 0 60px;*/
    display: flex;
    height: 909px;
    align-items: flex-start;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    background: linear-gradient(0deg, rgba(38, 42, 64, 0.65) 0%, rgba(38, 42, 64, 0.65) 100%), url(/-/media/clienthq/images/loginbg.png) lightgray -328.195px -113.517px / 165.641% no-repeat;

    &__content {
        display: flex;
        flex-wrap: wrap;
        height: 75vh;
        padding-left: 40vw;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
    }

    &__call-to-action {
        &:hover {
            background-color: #fff;
            --call-to-action__color: #2180bc;
        }
    }

    &__focus {
        background-color: #fff !important;
        color: #2e334e !important
    }

    &__heading {
        color: #fff;
        font-family: 'MuseoSans-300';
        font-size: 4em;
        padding: .5em;
    }

    &__contact a {
        padding-top: 25px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
    }

    &__form {
        display: flex;
        justify-content: center;

        &-btn {
            color: #2180bc;
            margin-bottom: 0;

            &:after {
                color: #fff;
            }

            &:hover {
                background: #fff;
            }
        }

        &-info-btn {
            display: flex;
            padding: 20px;
            text-align: center;
            align-self: stretch;
            border-radius: 4px;
            border: .2px solid white !important;
            background: #2E334E;
            width: 489px;

            &:hover,
            &:focus {
                border-radius: 4px;
                border: 1.5px solid white !important;
                cursor: pointer;
            }
        }

        &-info-text {
            color: #FFF;
            font-family: MuseoSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: #FFF;
            }
        }

        &-btn-icon {
            height: 13px;
            width: 24px;
            color: $colors__white;
            align-self: center;
        }

        & &-btn {
            //increse specificity
            width: inherit;
        }
    }

    & ~ * > * {
        //login sits in header, remove padding and height from content
        padding: 0 !important;
        min-height: 0 !important;
    }

    &__header {
        display: flex;
        width: 1800px;
        padding: 60px 0 60px 0px;
        align-items: center;
        gap: 10px;

        &_logo {
            display: flex;
            width: 80px;
            height: 80px;
            padding-right: 0.112px;
            justify-content: center;
            align-items: center;
            background-image: url('../../../../Content/images/CLA-logo-login.png')
        }

        &_text {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 880px
        }
    }

    &__body-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        flex: 1 0 0;

        &_page-title-h1 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            /* H1 */
            font-family: MuseoSans;
            font-size: 46px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 23px;
            margin-bottom: 0px;
        }

        &_page-title-h4 {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans-300;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0px;
            width: 489px;
        }

        &_page-title-h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off; /* H3 */
            font-family: MuseoSans;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &_help-text {
            color: #FFF;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: MuseoSans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 460px;
        }
    }

    &__preferred {
        display: flex;
        width: 91px;
        height: 24px;
        margin-top: -20px;
        border-radius: 3px;
        background: #c0462f;
        position: relative;
        top: 25px;
        left: 15px;
        color: #FFF;
        justify-content: center;
        align-items: center;
    }

    &__button {
        color: white;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background: #7DD2D3;
        align-self: stretch;
        width: 489px;
        padding: 20px 100px;
        justify-content: center;
        align-items: center;

        &--user {
            margin-right: 20px;
            border-radius: 4px;
            background: var(--Button-Initial, #7DD2D3);
            display: flex;
            width: 300px !important;
            padding: 20px 80px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            color: #2180bc;

            &:hover {
                color: #2E334E !important;
            }
        }

        &--text {
            color: #2E334E;
            font-feature-settings: 'liga' off, 'clig' off;
            /* Button Label */
            font-family: MuseoSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;
            text-align: center;

            &:hover {
                color: #2E334E;
                text-decoration: none;
            }
        }

        &:hover {
            background: #fff;
            cursor: pointer;

            &:after {
                background: #fff;
                color: #2180bc;
            }
        }
    }

    &__padding {
        padding-left: 350px
    }

    &__helptext {
        max-width: 290px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off; /* Steps */
        font-family: MuseoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__mobile-hide {
        display: block;
    }

    &__infographic {
        width: 75vw;

        &-tablet-768 {
            display: none;
        }

        &-tablet-1024 {
            display: none;
        }

        &-mobile-320 {
            display: none;
        }

        &-mobile-480 {
            display: none;
        }

        &-wrapper {
            width: 50vw; /* unfortunately width has to be set to some hard value */
            display: flex;
        }

        &-slide {
            width: 0;
        }

        &-content {
            height: 80vh;
            width: 85vw;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            opacity: 0;
            pointer-events: none;
            transition: opacity .6s;
            max-height: 90vh;
            overflow-y: auto;
        }

        & label {
            color: #FFF;
            transition-duration: .6s;
            cursor: pointer;
        }

        &-switch {
            color: #7DD2D3;

            @include media('<desktop') {
                display: none;
            }
        }

        &-controls {
            padding-bottom: 3vh;
        }
    }

    @include media('>desktop-xlarge') {
        .clienthq-login {

            &__content {
            }

            &__infographic {
            }
        }
    }
    //Infographic specific

    @include media('>tablet-landscape','<desktop') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-tablet-1024 {
                display: block;
            }
        }

        .clienthq-login__content {
            margin: 0;
            padding-top: 40px;
        }
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        background: none !important;
        background-color: #2E334E !important;
        height: 1400px;

        .clienthq-login__preferred {
            font-size: 24px;
            height: 45px;
            width: 150px;
            top: 65px;
            right: 285px;
            left: unset;
        }

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-tablet-768 {
                display: block;
            }
        }

        .clienthq-login__content {
            margin: 0;
            padding: 10vh;
            height: 1200px;
        }

        .clienthq-login__contact {
            diplay: none;

            & a {
                font-size: 24px;
            }
        }

        .clienthq-login__button {
            height: 80px;
            width: 700px;
        }

        .clienthq-login__form-info-btn {
            gap: 60px;
            height: 100px;
            align-items: center;
            padding-left: 65px;
            width: auto;
        }

        .clienthq-login__form-btn-icon {
            height: 26px;
        }

        .clienthq-login__form-info-text {
            font-size: 24px;
        }

        .clienthq-login__body-content {
            flex-direction: column;
            gap: 50px;
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 768px;
                Font-size: 56px;
            }

            &_page-title-h3 {
                font-size: 36px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 28px;
                text-align: center;
            }
        }

        .clienthq-login__button--text {
            font-size: 36px;
        }
    }

    @include media('>phone','<=tablet-portrait') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-mobile-480 {
                display: block;
            }
        }

        .clienthq-login__preferred {
            font-size: 14px;
            height: 25px;
            width: 74px;
            top: 25px;
            right: 95px;
            left: unset;
        }

        .clienthq-login__content {
            margin: 0;
            max-width: 320px;
            padding: 40px 0 40px 0px;
        }

        .clienthq-login__contact {
            diplay: none;
        }

        .clienthq-login__button {
            width: 300px;
        }

        .clienthq-login__form-info-btn {
            gap: unset !important;
            width: 100%;
        }

        .clienthq-login__body-content {
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 320px;
                font-size: 28px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    @include media('<=phone') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-mobile-320 {
                display: block;
            }
        }

        .clienthq-login__preferred {
            font-size: 14px;
            height: 25px;
            width: 74px;
            top: 25px;
            right: 95px;
            left: unset;
        }

        .clienthq-login__contact {
            display: none;
        }

        .clienthq-login__content {
            margin: 0;
            height: 100vh;
            padding: 10%;
        }

        .clienthq-login__button {
            width: 300px;
        }

        .clienthq-login__form-info-btn {
            gap: unset !important;
            width: 100%;
        }

        .clienthq-login__body-content {
            width: 100%;
            align-items: center;

            &_page-title-h1 {
                max-width: 320px;
                font-size: 28px;
            }

            &_page-title-h4 {
                line-height: 1.5;
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }
}
@media print {
    html:has(.clienthq-login) {
        height: auto;
        width: auto;

        @page {
            size: A4 landscape;
        }
    }
    .clienthq-login {
        visibility: hidden;
        background: none;
        height: auto;

        
        & .show-for-print {
            visibility: visible;
            left: 0;
            top: 0;
        }

        &__infographic {
            height: auto;

            &-switch {
                display: none;
            }

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-print {
                width: 1440px;
                position: absolute;
            }
        }
    }    

    .ecEmbedWrapper {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
    .proactive-animation-tooltip {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
    #ecLauncherFloatingBubble {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}

// Login page chat overrides
#ecLauncherFloatingBubble {
    background-color: #76D2D3 !important;
}

#ecLauncherFloatingBubble:focus {
    outline: 2px solid #fff !important;
    background-color: #76D2D3 !important;
}

.mini-chat-footer-btn {
    background: #76D2D3 !important;
}

.header-svg-wave {
    background-color: #76D2D3 !important;
}

.header-rectangle.home-page {
    background-color: #76D2D3 !important;
}
