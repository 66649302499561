.clienthq-header {

    &__img {
        height: 152px;
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
    }

    &__content {
        padding: 2em 0 0 4em;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 72em;
    }

    &__heading {
        color: $color-white;
        font-size: 3em;
        font-weight: normal;
    }

    &__organization {
        color: $color-white;
        font-size: 2em;
        font-weight: normal;
    }

    &__nav {
        text-align: right;
        min-height: 56px;
        background-color: $colors__gray--d8;
        color: $colors__black;
        // &--blue {
        //     background-color: $colors__blue;
        //     color: $color-white;
        // }
    }

    &__navlist {
        list-style: none;
        color: $colors__black;
        height: 100%;
        margin: 0 auto;
        max-width: 58em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__navitem {
        display: flex;
        margin-left: 0;
        flex-direction: row;
        align-items: center;
        margin-left: auto !important;
        cursor: pointer;
        position: relative;
        margin-bottom: 0;
        border: 1px solid transparent;
        border-radius: 6px;

        &:focus {
            border: 1px solid $colors__blue;
        }
        // &--active {
        //     background-color: $color-blue-clienthq-nav-hover;
        // }
        // &:hover {
        //     background-color: $color-blue-clienthq-nav-hover;
        // }

        @include media('>=tablet-portrait') {
            margin-left: 1.5em;
        }
    }

    &__profileicon-container {
        border-radius: 100%;
        overflow: hidden;
        height: 25px;
        width: 25px;
        background-color: $colors__gray--84;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #2e334e;
    }

    &__profileicon {
        padding: 0 .5em;
    }


    &__alerticon {
        display: block;
    }

    &__alerticon-unread {
        display: none;

        &-active {
            display: block;
        }
    }

    &__alerticon-container {
        position: relative;
    }

    &__alerticon-notif {
        background-color: $colors__red;
        position: absolute;
        top: -10px;
        right: -10px;
        color: $colors__white;
        border-radius: 20px;
        overflow: hidden;
        padding: .15em;
        height: 22px;
        min-width: 22px;
        height: auto;
        width: auto;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        opacity: 0;
        transition: .3s ease opacity;

        &.active {
            opacity: 1;
        }
    }

    &__arrow {
        font-style: normal;
        margin-left: .5em;

        &::after {
            font-family: 'FontAwesome';
            font-size: 1em;
            content: '\f107';
        }
        // border: solid $color-white;
        // border-width: 0 2px 2px 0;
        // box-sizing: border-box;
        // height: 1em;
        // width: 1em;
        // display: inline-block;
        // margin-left: 10px;
        // margin-bottom: 2px;
        // transform: rotate(45deg);
        // -webkit-transform: rotate(45deg);
    }

    &__circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: $color-grey-light-grey-hq;
        background-color: $color-white;
        float: right;

        &--unread {
            background-color: $color-green-olive;
            border-color: $color-green-olive;
        }
    }

    &__messages, &__profileItems {
        display: block;
        position: absolute;
        background-color: $color-white;
        border: 1px solid $color-grey-border-hq;
        padding: 10px 20px 20px;
        border-top-width: 0;
        box-sizing: border-box;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        border-radius: 6px;

        &.open {
            visibility: visible;
            opacity: 1;
        }
    }

    &__messages {
        width: 250%;
        right: 0;
        top: 50px;
        margin-left: 38px; //match padding of menu item
        &:before {
            border-image: none;
            border: 6px inset rgba(0,0,0,0);
            border-bottom: 6px solid $color-white;
            content: "";
            display: block;
            height: 0;
            right: 41px;
            position: absolute;
            top: -12px;
            width: 0;
            z-index: 99;
        }
    }


    &__profileItems {
        /*width: calc(100% - 3em);*/
        right: 0;
        top: 24px;
        min-width: 100%;

        & a {
            text-decoration: none;
            color: $colors__blue;
        }

        & a:hover {
            text-decoration: underline;
            color: $colors__blue;
        }
    }

    &__messageItem, &__profileItem, &__messageHeader {
        text-align: left;
        display: block;
        @include fonts__sans-serif-bold;
        color: $colors__blue;
        text-decoration: none;

        &:before {
            content: "";
            margin-left: inherit;
            display: inherit;
            text-align: inherit;
            padding: inherit;
        }
    }

    &__messageItem {
        display: block;
        border-top: 1px solid $color-grey-light-grey-hq;
    }

    &__messageDate {
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
        color: $color-grey-dark-grey;
        display: block;
        padding: 10px 0 5px 0;
    }

    &__message {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        color: $color-grey-dark-grey;
        display: inline-flex;
        padding-bottom: 6px;
        font-family: MuseoSans-500;
    }

    &__profileLink {
        color: $colors__blue;
        font-size: 16px;
        line-height: 31px;
        white-space: nowrap;
    }

    &__profileLink:hover {
        color: $color-blue-blue;
        /*font-family: MuseoSans-700;*/
        text-decoration: none;
    }

    &__message:hover {
        color: $color-blue-blue;
        font-weight: 500;
        text-decoration: none;
    }

    &__profileTitle {
        &--name {
            font-family: MuseoSans-700;
        }
    }

    &__mmenu-links, &__mmenu-messages {
        width: 0;
        display: none;
    }


    @include media('<=tablet-portrait')f {

        &__navlist {
            direction: rtl;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 18px;
            flex-wrap: wrap-reverse;

            .clienthq-header__navitem {
                direction: ltr;
                padding: 0 .5em;

                & > img {
                    padding: 0 .2em;
                }
            }
        }

        &__arrow {
            height: .5em;
            width: .5em;
            margin-left: 5px;
            margin-bottom: 4px;
        }

        &__content {
            padding-left: 0;
            text-align: center;
        }

        &__heading {
            font-size: 2.65em;
        }

        &__organization {
            font-size: 1.5em;
        }

        &__profileItems, &__messages {
            display: none;
            visibility: hidden;
        }
        /*mobile menu */
        &__mmenu-close {
            color: $color-grey-dark-grey;
            margin-bottom: 2em;
            position: absolute;
            top: 0;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;

            &:active {
                text-decoration: none;
            }
        }

        &__mmenu-messages &__mmenu-close {
            right: 0;
            left: 25px;
            margin-left: 0;
            margin-right: 50px;
        }

        & &__mmenu--active {
            @include media('<=tablet-portrait') {
                display: block;
                width: 70%;
            }
        }

        &__mmenu-welcome, &__mmenu-message-banner {
            padding: .5em .5em .5em 2em;
            display: block;
            font-family: MuseoSans-300;
            font-size: 22px;
            margin-bottom: 1em;
        }

        &__mmenu-welcome:before {
            font-family: FontAwesome;
            position: absolute;
            left: 0;
            width: 50px;
            text-align: center;
            content: "\f007";
            font-weight: 400 !important;
            color: $color-grey-dark-grey;
        }

        &__mmenu-message-banner {
            padding: .5em 0 .5em 0;
            font-family: MuseoSans-700;
            font-size: 18px;
            border-bottom: 1px solid $color-grey-light-grey-hq;
        }

        &__mmenu-links {
            width: 0;
            left: 0;

            &-link {
                font-size: 16px;
                color: $color-grey-dark-grey;
                font-family: MuseoSans-500;
                padding: .5em .5em .5em 2em;
                text-decoration: none;
                display: block;
                transition: 0.3s;
            }
        }

        &__mmenu-messages {
            right: 0;
            width: 0;
            padding: 0 1em 0 1em;
        }

        &__mmenu-message-date, &__mmenu-message-title {
            display: block;
        }

        &__mmenu-message-date {
            display: block;
            color: $color-grey-dark-grey;
            font-family: MuseoSans-300;
            font-size: 10px;
        }

        &__mmenu-message-title {
            display: block;
            border-bottom: 1px solid $color-grey-light-grey-hq;
            padding-bottom: .5em;
            font-family: MuseoSans-500;
            font-size: 14px;
            color: $color-grey-dark-grey;
            margin-bottom: 1em;
        }

        &__mmenu-message-title:active {
            color: $color-blue-blue;
        }



        &__mmenu-links, &__mmenu-messages {
            height: 100vh;
            position: fixed;
            z-index: 10000;
            top: 0;
            background-color: $color-white;
            overflow-x: hidden;
            padding-top: 60px;
            transition: 0.1s;
            margin: auto;
        }
    }

    &__intro {
        margin-top: 1em;
        margin-bottom: 2em;
    }

    &__intro-content {
        display: flex;
        flex-wrap: wrap;

        &--menu {
            flex: 1 1 100%;
            order: -1;
            margin-bottom: .5em;
        }

        &--intro {
            flex: 1 1 100%;
        }

        @include media('>=tablet-portrait') {
            justify-content: space-between;

            &--menu {
                flex: 1 1 auto;
                order: 1;
                margin-bottom: 0;
            }

            &--intro {
                flex: 1 1 auto;
            }
        }
    }

    &__intro-header {
        @include fonts__serif-regular;
        font-size: 24px;
    }

    &__intro-header--name {
        @include fonts__serif-regular;
        font-weight: bold;
    }

    &__intro-subtitle {
        font-size: 12px;

        span {
            @include fonts__sans-serif-medium;
        }
    }

    &__simple {
        display: flex;
        height: 25vh;
        padding-top: 3%;
        align-items: flex-start;
        flex-shrink: 0;
        align-self: stretch;
        margin: 0 auto;

        .logo-buttons {
            width: 50vw;
            padding-left: 5%;
        }

        @include media('>tablet-landscape','<desktop') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            gap: 10px;
            width: auto;

            .logo-buttons {
                padding: 0;

                .logo {
                    width: 55px;
                }
            }

            .textstyle {
                font-size: 14px;
            }
        }

        @include media('>tablet-portrait','<=tablet-landscape') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            gap: 50px;
            width: auto;

            .logo-buttons {
                padding: 0;

                .logo {
                    width: 80px;
                }
            }

            .textstyle {
                font-size: 28px;
            }
        }

        @include media('>phone','<=tablet-portrait') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            gap: 10px;
            width: auto;

            .logo-buttons {
                padding: 0;

                .logo {
                    width: 55px;
                }
            }

            .textstyle {
                font-size: 14px;
            }
        }

        @include media('<=phone') {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            gap: 10px;
            width: auto;
            height: 10vh;

            .logo-buttons {
                padding: 0;

                .logo {
                    width: 55px;
                }
            }

            .textstyle {
                font-size: 14px;
            }
        }
    }

    &__logo-link {

        @include media('<desktop') {
            width: auto;
        }
    }
}
