.section-name {
    --section-name__font-size: 14px;
    --section-name__letter-spacing: 0.88px;
    --section-name__margin-bottom: 16px;

    @include fonts__sans-serif-bold;
    font-size: var(--section-name__font-size);
    line-height: 1.21;
    text-transform: uppercase;
    letter-spacing: var(--section-name__letter-spacing);
    color: $colors__blue;
    padding-bottom: 8px;
    border-bottom: 4px solid $colors__red;
    display: inline-block;
    margin-bottom: var(--section-name__margin-bottom);

    @include media('>=tablet-portrait') {
        --section-name__font-size: 16px;
        --section-name__letter-spacing: 1px;
    }

    @include media('>=tablet-landscape') {
        --section-name__margin-bottom:: 24px;
    }
}
