.copy-icon-block-grid {
	&__tiles {
		--copy-icon-block-grid__border-style: 6px solid #{$colors__teal};
	    --copy-icon-block-grid__label-font-size: 18px;
	    --copy-icon-block-grid__label-padding: 22px;

	    $copy-icon-block-grid__item-spacing-sm: 16px;
	    $copy-icon-block-grid__item-spacing-lg: 64px;

	    &__items {
	        list-style-type: none;    
	    }

	    &__item {
	        &:not(:first-child) {
	            margin-top: $copy-icon-block-grid__item-spacing-sm;
	        }

	        display: flex;
            flex-direction: column;
	        justify-content: flex-start;
	        align-items: center;
	        padding: var(--copy-icon-block-grid__label-padding);	        
	        background-color: $colors__teal--lightest;
	    }

	    &__label {
	        @include fonts__sans-serif-bold;
	        font-size: var(--copy-icon-block-grid__label-font-size);
	        line-height: 1.22;
	        color: $colors__gray--25;
	        text-align: center;	            
	    }

		&__text {
			text-align: center;	        
		}

	    @include media('>=tablet-portrait') {
	        // --copy-icon-block-grid__item-spacing: 30px;
	        --copy-icon-block-grid__item-width: calc(50% - #{$copy-icon-block-grid__item-spacing-lg});
	        --copy-icon-block-grid__label-font-size: 20px;
	        --copy-icon-block-grid__label-padding: 32px;

	        &__items {
                display: grid;
                grid-gap: 24px;
                grid-template-columns: repeat(2,minmax(calc(50% - 24px),1fr));
            }

            &__item {
                &:not(:first-child) {
                    margin-top: 0;
                }
            }

        }
    }
}