.professional-content {
    @include container;

    .industries li {
        font-weight: bold;
    }

    .facts {
        @include span(3);

        p {
            font-size: 1em;
        }
    }

    .overview {
        @include span(9 last);
    }
}

@include bp-max(99999px) {
}

@include bp-max($bp-desktop) {
    .professional-content .facts {
        p {
            font-size: 1em;
        }
    }
}

@include bp-max(500px) {
    .professional-content .facts {
        display: block;
        width: 100%;

        p {
            font-size: 1.20em;
        }
    }

    .professional-content .overview {
        display: block;
        width: 100%;
    }
}
