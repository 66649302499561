.careers-intro {
    @include container;
    padding: 3em 0;

    .intro-content-left {
        @include span(8);
    }

    .intro-testimonials {
        @include span(4 last);
    }

    .embeded-video {
        h2 {
            margin: 0 0 1em 0;
        }
    }

    .embeded-video + .embeded-video {
        margin-top: 2.5em;
    }

    .disclaimer {
        margin-top: px-to-em(35px);
    }

    @include bp-max($bp-ipad) {
        .intro-content-left {
            @include span(100%);
        }

        .intro-testimonials {
            margin-top: 2em;
            @include span(100%);
        }
    }
}

@media print {
    .careers-intro .intro-content-left {
        width: 100% !important;
    }
}
