.gallery-carousel {
    --gallery-carousel__caption-padding: 8px 16px;
    $spacing: 40px;

    margin-top: $spacing;
    margin-bottom: $spacing;

    .swiper-wrapper {
        align-items: center;
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__caption {
        background-color: $colors__white;
        padding: var(--gallery-carousel__caption-padding);
        @include fonts__sans-serif-light;
        line-height: 1.2;
        color: $colors__gray--33;
        font-size: 14px;
    }

    @include media('>=tablet-portrait') {
        --gallery-carousel__caption-padding: 16px;
    }
}
