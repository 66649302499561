.author-bio {
    width: 100%;
    display: flex;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    &__container {
        padding: 32px 0;
        margin: 32px 0;
        border-top: 2px solid $colors__gray--d8;
        border-bottom: 2px solid $colors__gray--d8;
    }

    figure {
        flex: 0 0 60px;

        img {
            border-radius: 200px;
            /*max-width: 150% !important;*/
            object-fit: cover;
            object-position: top;
            min-width: 60px;
            height: 60px!important;
        }
    }

    &__figure {
        position: relative;
        height: 69px;
        border-radius: 3px;
        overflow: hidden;

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            object-fit: contain;
        }
    }



    &__standarized-image {
        padding-bottom: 100%;
        background-size: cover;
        background-position: center top;
        display: block;
        border-radius: 200px;
        border: solid 2px $color-lighter-grey;

        &:hover {
            border: solid 2px $color-dark-blue-footer;
            border-radius: 200px;
            padding-bottom: 100%;
        }
    }

    &__info {
        flex: 1 1 auto;
        padding-left: 18px;
        @include fonts__sans-serif-light;

        &-personal {
            margin-bottom: 10px;
        }

        &-contactinfo {
        }
    }


    ul {
        list-style: none;
        line-height: 1.25;
        margin-left: 0px;
        margin-bottom: 0px !important;
        padding-left: 0 !important;
    }

    &__name {
        @include fonts__sans-serif-medium;
        color: $colors__blue;
        font-size: 18px;

        a {
            color: $colors__blue;
        }
    }

    &__title {
        color: $colors__gray--33;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
    }

    &__social {
        font-size: 1em;
        color: $colors__gray--84;

        a {
            color: $colors__gray--84;
            margin-right: 5px;

            &:hover {
                color: $colors__blue;
            }
        }
        // a .fa-linkedin {
        //     color: $color-linkedin;
        //     &:hover {
        //         color: $colors__gray--84;
        //     }
        // }
        // a .fa-facebook {
        //     color: $color-facebook;
        //     &:hover {
        //         color: $colors__gray--84;
        //     }
        // }
        // a .fa-youtube {
        //     color: $color-youtube;
        //     &:hover {
        //         color: $colors__gray--84;
        //     }
        // }
        // a .fa-twitter {
        //     color: $color-twitter;
        //     &:hover {
        //         color: $colors__gray--84;
        //     }
        // }
    }

    &__telephone {
        font-size: 14px;
    }

    &__profile {
        font-size: 14px;

        a {
            text-decoration: underline;
            font-weight: $fonts__font-weight--medium;
        }
    }

    @include bp-max(600px) {
        figure {
            width: 50%;
        }

        .personal, .contactinfo {
            display: block;
            width: 100%;
        }
    }
}

// LEGACY STYLES

.homepage-content {
    .author-bio {
        background-color: #f0efef;
        padding: 1em;
        margin-bottom: 15px;
        font-size: .85em;
        clear: both;
        display: table;

        li {
            margin-left: 0;
        }

        figure {
            min-width: 120px;
            display: table-cell;
            padding-bottom: 0;
            padding-right: 10px;
            width: 15%;
            vertical-align: top;
        }

        .personal {
            display: table-cell;
            width: 35%;
            padding-right: 1em;
        }

        figure img {
            height: 100px!important;
            width: 100px;
            vertical-align: bottom;
            border-radius: 200px;

            &:hover {
                border: solid 5px $color-dark-blue-footer;
            }
        }

        .name a,
        .name {
            color: #5cb8b2;
            @include fonts__sans-serif-bold;
        }

        .contactinfo {
            display: table-cell;
        }

        .contactinfo a {
            color: #5cb8b2;
            @include fonts__sans-serif-bold;
        }

        .social {
            font-size: 1.25em;
        }

        .social a {
            color: #404041;
            margin-right: 5px;

            .fa-linkedin {
                color: #0077b5;
            }
        }

        @include bp-max($bp-tablet) {
            figure {
                vertical-align: top;
                width: 50%;
            }

            .contactinfo,
            .personal {
                display: block;
                width: 100%;
            }
        }
    }
}
