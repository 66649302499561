.form {
    

    &__field-item {
        & + & {
            margin-top: 15px;
        }
    }

    &__label {
        font-size: 14px;
        color: $colors__black;
        margin-bottom: 5px;

        &--required {
            &:after {
                content: '*'
            }
        }

    }
    &__inputbox[type=email],
    &__inputbox[type=tel],
    &__inputbox[type=text],
    &__inputbox {
        @include fonts__sans-serif-light;

        width: 100%;
        padding: 16px;
        border: 1px solid $colors__gray--ac;
        height: auto;
        box-sizing: border-box;
        font-size: 15px;
        height: auto;
        color: $colors__black;
        margin-bottom: 0;

        &::placeholder {
            color: $colors__gray--71;
            opacity: 1;
        }

        &:focus {
            box-shadow: none;
            background-image: none;
            background-color: $color-white;
            border-color: $colors__teal;
        }

        &--warning {
            border-color: $colors__red;
        }
    }

    &__error-message {
        margin-top: 5px;
        font-size: 14px;
        color: $colors__red;
        display: none;

        &--show {
            display: block;
        }
    }

    &__inputbox {
        &--textarea {
            height: 200px;
        }
    }

    &__subtitle {
        color: $colors__black;
        font-size: 1em;
    }

    &__message {
        @include font-primary-medium-bold(18px);
        color: $color-black;

        &--waiting {
            color: $colors__gray--84;
        }

        &--error {
            color: $colors__red;
        }
    }

    &__disclaimer {
        padding-top: 20px;
    }

    &__box {
        padding: 1px 0;
        background-color: $colors__gray--f7;
    }

    @include media('>=tablet-landscape') {
        &__box {
            padding: 0;
            background-color: transparent;
        }
        &__field-item {
            & + & {
                margin-top: 30px;
            }
        }

    }
}