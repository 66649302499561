.page-wrapper {
    padding: 0 1em;
    /*max-width: px-to-em(1000px);*/
    max-width: px-to-em(1040px);
    margin-left: auto;
    margin-right: auto;

    @include bp-max($bp-desktop) {
        &.home {
            padding: 0 0;
        }
    }
}

.home-wrapper {
    padding: 0 1em;
    max-width: px-to-em(1300px);
    margin-left: auto;
    margin-right: auto;
}

.footer-wrapper {
    padding: 0 1em;
    /*max-width: px-to-em(1000px);*/
    max-width: 70em;
    margin-left: auto;
    margin-right: auto;

    @include bp-max($bp-desktop) {
        &.home {
            padding: 0 0;
        }
    }
}

.error-wrapper {
    padding: 0 1em;
    min-height: 100vh;
    background-size: contain;
    margin: 0 auto;
    background-position: 50%;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;

    @include bp-max($bp-desktop) {
        &.home {
            padding: 0 0;
        }
    }

    .top-row {
        background-color: rgb(86, 149, 174);
        padding-top: 75px;
        text-align: center;
    }
}

.content-main {
    padding-top: 2em;
    padding-bottom: 2em;

    .extraheader {
        margin-top: -1em;
        margin-bottom: -1.25em;
    }

    .featured-resources {
        //margin-top:-1em;
    }

    header .featured-image img {
        /*fix IE being dumb with max-width*/
        width: 100%;
        max-width: 100%;
    }

    &.detail {
        .main-container {
            a {
                @include font-primary-medium-bold;
            }
        }
    }
}

.content-main.home {
    .featured-resources {
        margin-top: 0em;
    }
}
