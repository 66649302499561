﻿.person-detail {
    .events, .resources {
        opacity: 1;
        transition: opacity .25s ease-in-out;

        &.loading {
            opacity: 0;
        }
    }
}
