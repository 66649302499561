.clienthq-text_infographic {
    display: flex;
    height: 100vh;
    align-items: flex-start;
    padding-top: 1%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    background: linear-gradient(0deg, rgba(38, 42, 64, 0.65) 0%, rgba(38, 42, 64, 0.65) 100%), url(/-/media/clienthq/images/loginbg.png) lightgray -328.195px -113.517px / 165.641% no-repeat;

    @include media('>tablet-landscape','<desktop') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-tablet-1024 {
                display: block;
            }
        }
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        background: none !important;
        background-color: #2E334E !important;
        height: 1400px;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-controls {
                display: none;
            }

            &-tablet-768 {
                display: block;
            }
        }
    }

    @include media('>phone','<=tablet-portrait') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-controls {
                display: none;
            }

            &-mobile-480 {
                display: block;
            }
        }
    }

    @include media('<=phone') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-mobile-320 {
                display: block;
            }
        }
    }
}

.clienthq-authenticator_infographic {
    display: flex;
    height: 100vh;
    align-items: flex-start;
    padding-top: 1%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center;
    background: linear-gradient(0deg, rgba(38, 42, 64, 0.65) 0%, rgba(38, 42, 64, 0.65) 100%), url(/-/media/clienthq/images/loginbg.png) lightgray -328.195px -113.517px / 165.641% no-repeat;

    @include media('>tablet-landscape','<desktop') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-tablet-1024 {
                display: block;
            }
        }
    }

    @include media('>tablet-portrait','<=tablet-landscape') {
        background: none !important;
        background-color: #2E334E !important;
        height: 1400px;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-tablet-768 {
                display: block;
            }
        }
    }

    @include media('>phone','<=tablet-portrait') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-mobile-480 {
                display: block;
            }
        }
    }

    @include media('<=phone') {
        background: none !important;
        background-color: #2E334E !important;
        height: auto !important;

        .clienthq-login__infographic {
            justify-content: center;
            flex-direction: column;
            display: flex;
            /* height: 100%; */
            text-align: center;
            height: auto;
            width: auto;

            &-desktop {
                display: none;
            }

            &-content {
                height: 0px;
            }

            &-controls {
                display: none;
            }

            &-mobile-320 {
                display: block;
            }
        }
    }
}



#i_1:checked ~ .controls label[for="i_1"],
#i_2:checked ~ .controls label[for="i_2"],
#i_3:checked ~ .controls label[for="i_3"],
#i_4:checked ~ .controls label[for="i_4"],
#i_5:checked ~ .controls label[for="i_5"],
#i_6:checked ~ .controls label[for="i_6"] {
    color: #7DD2D3;
}

#i_1:checked ~ .wrapper .content1,
#i_2:checked ~ .wrapper .content2,
#i_3:checked ~ .wrapper .content3,
#i_4:checked ~ .wrapper .content4,
#i_5:checked ~ .wrapper .content5,
#i_6:checked ~ .wrapper .content6 {
    opacity: 1;
    pointer-events: auto;
}

@media print {
    html:has(.clienthq-authenticator_infographic) {
        @page {
            size: A4 landscape;
        }
    }

    html:has(.clienthq-text_infographic) {
        @page {
            size: A4 landscape;
        }
    }

    .clienthq-authenticator_infographic {
        visibility: hidden;
        background: none;
        height: 0px;


        & .show-for-print {
            visibility: visible;
            left: 0;
            top: 0;
        }

        & .clienthq-login__infographic {
            height: 0px;

            &-switch {
                display: none;
            }

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-print {
                position: absolute;
            }
        }
    }

    .clienthq-text_infographic {
        visibility: hidden;
        background: none;
        height: 0px;


        & .show-for-print {
            visibility: visible;
            left: 0;
            top: 0;
        }

        & .clienthq-login__infographic {
            height: 0px;

            &-switch {
                display: none;
            }

            &-wrapper {
                display: none;
            }

            &-controls {
                display: none;
            }

            &-print {
                position: absolute;
            }
        }
    }

    .ecEmbedWrapper {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    .proactive-animation-tooltip {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }

    #ecLauncherFloatingBubble {
        display: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}
