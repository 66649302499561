.panel, .paneljr {
    padding: 1.5em;
    margin-bottom: 1em;

    &.dutch-white {
        background-color: $color-gray-white;
    }

    &.bg-blue {
        background-color: $color-blue-prusian-blue;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    &.bg-purple {
        background-color: $color-violet-jagger;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    &.bg-orange {
        background-color: $color-orange-rich-gold;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    &.bg-brown {
        background-color: $color-brown-spice;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    &.bg-green {
        background-color: $color-green-olive;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    &.bg-red {
        background-color: $color-brown-maroon;

        h1, h2, h3, h4, h5 {
            color: $color-white;
        }
    }

    h2 {
        // @include font-primary-light;
        // color: $color-dark-blue-footer;
        font-family: $fonts__serif-regular;
        font-size: 38px;
        margin-bottom: 24px;        
        line-height: 1.25;
    }

    h2.full {
        margin: -.75em -.75em 1em -.75em;
    }

    h3.full {
        margin: -1em -1em 1em -1em;
    }

    p {
        &:last-child {
            margin-bottom: 0px;
        }
    }

    h2.full, h3.full {
        display: block;
        line-height: 0;
        padding: 1em .75em;

        &.purple {
            color: $color-white;
            background-color: $color-violet-jagger;
        }

        &.orange {
            color: $color-white;
            background-color: $color-orange-rich-gold;
        }

        &.cla-blue {
            color: $color-white;
            background-color: $color-blue-prusian-blue;
        }

        &.brown {
            color: $color-white;
            background-color: $color-brown-spice;
        }

        &.cerulean {
            color: $color-white;
            background-color: $color-blue-cerulean;
        }

        &.red {
            color: $color-white;
            background-color: $color-brown-maroon;
        }
    }

    input {
        width: 100%;
    }

    @media screen and (max-width: 61.875em) {
        h2.full, h3.full {
            font-size: .75em !important;
        }
    }

    .button {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        padding: 9.5px 24.5px;

        &:after {
            display: none;
        }
    }
}

.paneljr {
    &.right {
        float: right;
        margin: 0em 0em 1em 1em;
        max-width: 45%;
        min-width: 35%;
    }

    &.left {
        float: left;
        margin: 0em 1em 1em 0em;
        max-width: 45%;
        min-width: 35%;
    }
}

@include bp-max(550px) {
    .paneljr.right, .paneljr.left {
        float: none;
        margin-left: 0px;
        max-width: 100%;
        width: 100%;
    }
}

// panels in rich text editor areas
.rte .panel {   
    float: none!important;
    width: 100%!important;
}