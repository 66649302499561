.location-landing {
	--location-results-section__border-bottom-height: 2px;

    @include spacing__component-bottom-padding;

    .location-results {
    	&__container {
    		&--map {
    			@include spacing__component-bottom-padding;

				opacity: 1;
				height: 100%;
				overflow: hidden;
				
				&.no-results {
					opacity: 0;
					height: 0;
					@include transition(opacity 0.5s, ease-out);
				}
    		}
    	}    	
        &__results-list {
            font-size: 1em;
        }
        &__results-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
        }

        &__results-item {
            flex: 1 1 100%;
            list-style-type: none;
            margin: 15px;
            
            @include media('>=phone') {
                flex: 0 1 calc(50% - 30px);
            }
            
            @include media('>=tablet-portrait') {
                flex: 0 1 calc((100%/3) - 30px);
            }
        }

		&__results {

			&-item {
				margin: 0;
				padding: 25px 15px 0 15px;		
				
				&:not(:last-child) {
					border-bottom: var(--location-results-section__border-bottom-height) solid $colors__gray--d8;
				}
			}
		}

        

	    &__header {
	        @include fonts__sans-serif-medium;
	        font-size: 32px;
	        line-height: 1.2;
	        color: $colors__blue;
	        padding-bottom: 8px;
	        border-bottom: var(--location-results-section__border-bottom-height) solid $colors__gray--d8;
	        margin-bottom: 24px;
	    }  	

		@include media('>=phone') {
			&__results {
				&-item {
					&:not(:last-child) {
						border-bottom: none;
					}					
					&:not(:nth-last-child(-n+2)) {
						&:first-child,
						&:nth-child(2n+1) {
					        &::after {
					            content: "";
					            width: 100%;
					            height: 0;
					            border-bottom: var(--location-results-section__border-bottom-height) solid $colors__gray--d8;
					            position: absolute;
							    max-width: calc(100% - (#{$spacing__gutter-width} * 2);
							    margin-left: auto;
							    margin-right: auto;
							    padding-left: $spacing__gutter-width;
							    padding-right: $spacing__gutter-width;          
					        }
				        }
					}
				}
			}        
	    }

	    @include media('>=tablet-portrait') {
            &__filters {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
            }

            &__title {
                width: 100%;
            }

            &__filter-list {
                margin-bottom: 0;
            }

			&__results {
				&-item {					
					&:not(:nth-last-child(-n+2)) {
						&:first-child,
						&:nth-child(2n+1) {
					        &::after {
					            border-bottom: none;
					        }
				        }
					}
					&:not(:nth-last-child(-n+3)) {
						&:first-child,
						&:nth-child(3n+1) {
					        &::after {
					            content: "";
					            width: 100%;
					            height: 0;
					            border-bottom: var(--location-results-section__border-bottom-height) solid $colors__gray--d8;
					            position: absolute;
							    max-width: calc(var(--max-width__width) - (#{$spacing__gutter-width} * 2);
							    margin-left: auto;
							    margin-right: auto;
							    padding-left: $spacing__gutter-width;
							    padding-right: $spacing__gutter-width;          
					        }
				        }
					}
				}
			}        
	    } 	    
    }

    @include media('>=tablet-portrait') {
        --location-results-section__border-bottom-height: 3px;
    }

    @include media('>=tablet-landscape') {
        --location-results-section__border-bottom-height: 4px;        
    }      
}