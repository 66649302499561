.resources {
    @include container;

    .content-well .main-container {
        @include span(100%);
    }


    .personalization-cta {
        background-color: $color-whitesand-grey;
        margin-bottom: 1em;

        .content {
            margin: 0 1em;
            display: inline-block;
            width: auto;
            vertical-align: text-bottom;
        }

        .tool-tip-icon {
            font-size: 1.25em;
            margin-top: .25em;
        }

        .fa-question-circle:before {
            color: $color-blue-air-force-blue;
        }

        em.tool-tip-icon, #personalization-message {
            display: none;
        }

        .personalization-cta-button {
            display: inline-block;
            width: 1.25em;
            height: 1.25em;
            border-radius: 1.875em;
            background-color: $color-blue-air-force-blue;
            z-index: 1;
            margin: 5px 0;
            vertical-align: bottom;
            transition: background-color 0.3s;

            &:before {
                @include font-fontawesome($fa-var-question);
                vertical-align: text-bottom;
                top: -20%;
                margin-left: 3px;
                position: relative;
                color: $color-white;
                display: inline-block;
                padding-top: 0.1em;
                padding-left: 0.1em;
                //font-size: 1.25em;
            }

            .popout {
                &.normalpopout {
                    display: none;
                }

                &.showpopout {
                    display: inline-block;
                    position: relative;
                    left: 160%;
                    top: -175%;
                    z-index: 1;
                    background: white;
                    vertical-align: top;
                    width: 15em;
                    box-shadow: 2px 2px 10px #000;

                    .popout-content {
                        font-size: 90%;
                        padding: 10px;
                        display: inline-block;
                        width: 80%;
                    }

                    .cta-popout-close {
                        background-color: $color-white !important;
                        color: $color-brown-sand-dune !important;
                        float: right;
                        margin-top: 4px;
                        display: inline-block;
                        padding: 0.313em 0.75em;

                        &:before {
                            @include font-ionicons($ionicon-var-close-round);
                            float: right;
                        }
                    }

                    &:after {
                        width: 0px !important;
                        display: none !important;
                        background-color: transparent !important;
                        content: "" !important;
                    }
                }
            }

            &:hover {
                .normalpopout {
                    display: inline-block;
                    position: relative;
                    left: 160%;
                    top: -175%;
                    z-index: 1;
                    background: white;
                    vertical-align: top;
                    width: 15em;
                    box-shadow: 2px 2px 10px $color-black;

                    .popout-content {
                        font-size: 90%;
                        padding: 10px;
                        display: inline-block;
                        width: 80%
                    }

                    .cta-popout-close {
                        background-color: $color-white !important;
                        color: $color-brown-sand-dune !important;
                        float: right;
                        margin-top: 4px;
                        display: inline-block;
                        padding: 0.313em 0.75em;

                        &:before {
                            @include font-ionicons($ionicon-var-close-round);
                            float: right;
                        }
                    }

                    &:after {
                        width: 0px !important;
                        display: none !important;
                        background-color: transparent !important;
                        content: "" !important;
                    }
                }
            }
        }
    }

    ul {
        margin-left: 0px;
        padding-left: 0px;

        li {
            color: $color-brown-spice;
            margin-bottom: 1.5em;
        }
    }

    select {
        padding: 5px;
    }

    &.form-page ul li {
        margin-bottom: 0.25em;
    }
}

@include bp-max($bp-mobile) {
    .left, .right {
        display: block;
        width: 100% !important;
    }

    .personalization-cta, .personalization-cta-button {
        display: none !important;
    }
}

.resource-results {
    opacity: 1;
    transition: opacity .25s ease-in-out;

    li {
        padding-left: 0px !important;
        margin-left: 0px !important;
        list-style-type: none;

        .abstract {
            font-size: 18px !important;
            line-height: 1.25;

            p {
                font-size: 18px !important;
                line-height: 1.25;
            }
        }

        a {
            @include font-primary-medium-bold;
            font-size: 1.25em;
        }
    }

    ul.search-results-meta {
        display: block;

        li:first-of-type:before {
            display: none !important;
        }

        li:before {
            padding-left: 0px;
        }
    }

    &.loading {
        opacity: 0;

        &.ng-hide {
            display: block !important;
        }
    }
}
