.social-sidebar {
    display: table;
    position: fixed;
    margin-left: 4px !important;
    list-style-type: none !important;

    li {
        display: table-cell;
        display: block;
        color: $color-white;
        margin-bottom: 0px;

        a {
            color: $color-white;
            display: block;
        }
    }

    .linkedin, .facebook, .twitter, .email, .print {
        width: 40px;
        height: 40px;
        font-size: 1.25em;
        vertical-align: middle;
        text-align: center;
        line-height: 1.75;
    }

    .linkedin {
        background-color: $color-linkedin;

        &:hover {
            background-color: $color-white;

            a {
                color: $color-linkedin;
            }
        }
    }

    .facebook {
        background-color: $color-facebook;

        &:hover {
            background-color: $color-white;

            a {
                color: $color-facebook;
            }
        }
    }

    .twitter {
        background-color: $color-twitter;

        &:hover {
            background-color: $color-white;

            a {
                color: $color-twitter;
            }
        }
    }

    .email {
        background-color: $color-green-celedon;

        &:hover {
            background-color: $color-white;

            a {
                color: $color-green-celedon;
            }
        }
    }

    .print {
        background-color: $color-orange-saffron;

        &:hover {
            background-color: $color-white;

            a {
                color: $color-orange-saffron;
            }
        }
    }

    .share {
        background-color: $color-grey-25;
        transform: rotate(270deg);
        transform-origin: left top;
        height: 40px;
        width: 90px;
        text-transform: uppercase;
        text-align: center;
        font-size: 1em;
        padding-top: 8px;
        margin-top: 90px;
    }
}

@include bp-max($bp-desktop) {
    .social-sidebar {
        display: none;
    }
}
