.professional header {
    // background-color: $color-green-olive;
    background-color: $color-green-celedon;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 1em;

    h1 {
        @include font-primary-serif;
        font-size: 42px;
        // font-size: 2em;
        margin-bottom: .25em;
        line-height: 1.1;
    }

    li {
        @include font-primary;
        font-size: 18px;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: $color-dark-blue-footer;
    }

    h1, li, a {
        // color: $color-white;
        color: $color-dark-blue-footer;
    }

    li {
        list-style: none;

        a:hover {
            color: $color-blue-prusian-blue;
        }
    }

    figure {
        flex: 0 0 auto;

        img {
            display: block;
            height: 225px;
            width: auto;
            max-width: none;
            max-height: 100%;
        }
    }

    .profile {
        // flex: 1 1 auto;
        //padding:0 0 0 2.5em;
        // display: flex;
        // flex-flow: row nowrap;
        // align-items: center;
        // justify-content: space-between;

        & > div {
            margin: 0.5em 1em;
        }

        .personal {
            flex: 0 1 auto;
            margin-bottom: 1rem;
        }

        .contact {
            flex: 0 0 auto;

            li:first-of-type a {
                text-decoration: none !important;
            }

            li a {
                text-decoration: underline;
            }

            .fa {
                font-size: 1.25em;
                margin-right: .25em;
            }
        }
    }

    .no-bullets {
        margin-bottom: 0;
    }
}

@include bp-max($bp-desktop) {
    .professional header {
        .profile {
            flex-flow: row wrap;
            //padding:0 0 0 1.5em;
        }
    }
}

@include bp-max($bp-tablet) {
    .professional header {
        .profile {
            padding: 0;
        }
    }
}

@include bp-max(625px) {
    .professional header {
        flex-flow: column nowrap;
        align-items: flex-start;

        h1 {
            font-size: 1.75em;
        }

        figure {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .profile {
            flex-flow: column nowrap;
            align-items: flex-start;
            width: 100%;

            .personal {
                width: 100%;
            }

            .contact {
                width: 100%;
            }
        }
    }
}

@media print {
    .professional header {

        h1 {
            font-size: 2.5em;
        }

        figure {
            display: none !important;
            height: 0px !important;
            width: 0px !important;
            float: none !important;
            margin: 0px !important;
        }

        .profile {
            margin: 0;
            padding: 2.5em 0;

            & > div {
                margin: 0;
            }
        }
    }
}
