.hubspot-form__container {
    iframe {
        width: 100%!important; //override iframe inline width
    }
}
.hubspot-form__narrow-width {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;

    .hs-richtext {
        margin-bottom: 30px !important;
    }

    .sc-richtext {
        margin-top: 30px;
    }

    a {
        color: $colors__blue;
    }

    .forms {
        padding: 34px 0 64px;
    }
}

.hubspot-form,
.event-detail__hubspot {
    padding: 0;

    .rte {
        margin-bottom: 1em;
    }
    .hbspt-form {
        padding: 1px;
        .hs-richtext {
            @include fonts__sans-serif-light;
            margin-bottom: 1em;
        }
        .hs-richtext {
            --rte__font-size: 16px;
            --rte__headline-font-size: 28px;
            --rte__h3-font-size: 24px;
            --rte__list-padding: 24px;
            --rte__y-spacing: 24px;

            font-size: var(--rte__font-size);
            line-height: 1.5;
            color: $colors__gray--33;

            p {
                font-family: inherit;
                font-weight: inherit;
                font-size: inherit;
                color: $colors__gray--33;
            }

            a {
            font-size: inherit;
            color: $colors__blue;            

                &:hover {
                    text-decoration: underline;
                }
            }

            @include media('>=tablet-landscape') {
                --rte__font-size: 18px;
            }
        }
        
        .hs-button {
            @include call-to-action-styles('.button');
        }
    }
}

