.upcoming-webinars {
    display: flex;
    color: $color-brown-spice;

    &__image {
        width: 50%;
        padding: 120px 70px;
        background-size: cover;
        background-position: center;
    }

    &__intro {
        width: 100%;
        max-width: px-to-em(564px);
        float: right;
    }

    &__intro-block {
        background: rgba(255,255,255,0.7);
        padding: 35px 30px;
        display: inline-block;
    }

    &__intro-title {
        font-size: 3.438em;
        @include font-primary-light;
    }


    &__intro-subtitle {
        font-size: 1.938em;
        @include font-primary-medium;
    }

    &__list {
        width: 50%;
        background: $color-white;
        padding: 90px 70px;
    }

    &__eyebrow {
        color: $color-brown-russet;
        text-transform: uppercase;
        margin-bottom: 40px;
        @include font-primary;
    }

    &__webinar {
        display: flex;
        margin-bottom: 40px;

        &:hover {
            cursor: pointer;
            text-decoration: none;

            .upcoming-webinars {
                &__date {
                    background: $color-blue-blue;
                }

                &__title {
                    color: $color-blue-blue;
                }
            }
        }
    }

    &__date {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: $color-brown-spice;
        color: $color-white;
        padding: 20px;
        margin-right: 20px;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__month {
        text-transform: uppercase;
    }

    &__day {
        @include font-primary-medium;
        font-size: 2.375em;
        line-height: 1;
    }

    &__info {
        max-width: 420px;
    }

    &__title {
        font-size: 1.750em;
        @include font-primary-medium;
        margin-bottom: 8px;
        color: $color-brown-spice;
    }

    &__type {
        font-style: italic;
        padding-right: 5px;
    }

    &__time,
    &__type {
        color: $color-brown-spice;
    }

    &__see-all-link {
        color: $color-brown-spice;
        border: 1px solid $color-brown-spice;
        background: transparent;
        font-size: 1.25em;
        @include font-primary-medium;
        padding: 10px 20px;

        &:hover {
            color: $color-white;
            background: $color-blue-blue;
            border-color: $color-blue-blue;
            text-decoration: none;
        }
    }

    @include bp-max($bp-desktop) {
        &__image {
            padding-left: 0;
        }

        &__intro-title {
            font-size: 2.375em;
            line-height: 46px;
        }

        &__intro-subtitle {
            font-size: 1.5em;
            line-height: 29px;
        }


        &__list {
            padding: 50px 24px 50px 30px;
        }

        &__eyebrow {
            margin-bottom: 30px;
        }

        &__date {
            width: 80px;
            height: 80px;
            background: $color-blue-blue;
        }

        &__month {
            font-size: 0.75em;
        }

        &__day {
            font-size: 1.875em;
        }

        &__title {
            font-size: 1.375em;
            line-height: 27px;
            color: $color-blue-blue;
        }
    }

    @include bp-max($bp-ipad) {
        flex-direction: column;

        &__image {
            padding: 0;
            width: 100%;
            height: 300px;
        }

        &__intro-block {
            width: 100%;
        }

        &__list {
            width: 100%;
        }

        &__see-all-link {
            display: block;
            text-align: center;
        }
    }
}
