.buzzwords {
    display: table;
    width: 100%;
    border-spacing: .5em;

    .col {
        padding: 1em;
        width: 33.3%;
        display: table-cell;
        background-color: $color-gray-white;

        h3 {
            color: $color-white;
            background-color: $color-violet-jagger;
            margin: -1em -1em 1em -1em;
            padding: .50em;
            font-size: .95em;
        }

        p {
            font-size: 1em !important;
        }

        p:last-of-type {
            margin-bottom: 0px;
        }
    }
}

@include bp-max($bp-desktop) {
    .buzzwords {
        .col {
            width: 100%;
            display: block;
            margin-bottom: 1em;
            min-height: 1px;
        }
    }
}
