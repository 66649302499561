.locations {
    .date {
        font-size: .75em;
    }

    .search-container {
        height: 34px;
        width: 100%;
        margin-bottom: 1em;
        background-color: $color-white;

        input {
            &:focus {
                background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.46) 46%, rgba(212,212,212,1) 100%);
                box-shadow: none;
            }
        }

        button {
            transform: rotateY(-180deg);
            float: right;
            border-radius: 0;
            height: 32px;
            font-size: 1.05em;
            color: $color-white;
            background-color: $color-dark-blue-footer;
        }
    }

    input[type="text"] {
        background: transparent;
        padding: 10px 5px 5px 5px;
        border: 1px solid $color-brown-tide !important;
        border-right: none !important;
        color: $color-brown-spice;
        font-size: 1em;
        line-height: 1;
        width: 60%;
    }

    .office-events {
        .recomended-container.local {
            border-top: none !important;
            padding-top: 0px !important;
        }
    }

    select {
        padding: 5px;
    }

    .locations-results {
        background-color: $color-gray-white;
        @include container;
        padding: 1em;

        .state {
            @include span(2.5);
            font-size: 5em;
            color: $color-brown-spice;
        }

        @include bp-max(559px) {
            .state {
                display: none;
                width: 0px !important;
            }
        }

        .locations {
            @include span(9.5 last);
            @include container;

            .right {
                @include span(6);
            }

            .left {
                @include span(6 last);
            }

            ul {
                margin-bottom: 1em;

                li {
                    line-height: 1.25;
                }
            }
        }
    }

    .industries-services-leadin {
        margin-bottom: 1em;
    }

    .vcard {
        min-height: 225px;
    }
}

@include bp-min(600px) {
    .locations .locations-results .locations ul {
        padding-right: 1em;
        width: 50%;
        float: left;
    }
}

@include bp-max(559px) {
    .locations .locations-results .locations {
        width: 100%;
        float: none;
    }

    .locations .locations-results .locations ul {
        padding-right: 0px;
        width: 100%;
        float: none;
    }
}


.locations .location-search {
    margin-bottom: 1em;
}

@include bp-max($bp-desktop) {
    .locations .events .left, .locations .events .middle, .locations .events .right {
        width: 100% !important;
        display: block;
        margin-bottom: 1em;
        min-height: 1px;
    }
}
