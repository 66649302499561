.detail {
    @include container;
    /*.content-well*/ .main-container {
        min-height: 300px;
        @include span(11);

        h1 {
            margin-bottom: .25em !important;
            // font-weight: bold!important;
            font-size: 2.0625em;
        }

        h2 {
            font-size: 1.875em;
            font-weight: 700;
            @include font-primary; 
        }

        table.right {
            float: right;
            width: 50%;
            margin-left: 15px;
            margin-bottom: 15px;
        }

        table.left {
            float: left;
            width: 50%;
            margin-right: 15px;
            margin-bottom: 15px;
        }

        @include bp-max(550px) {
            table.right {
                float: none;
                width: 100%;
                margin-left: 0px;
                margin-bottom: 15px;
            }

            table.left {
                float: none;
                width: 100%;
                margin-right: 0px;
                margin-bottom: 15px;
            }
        }
    }

    aside {
        @include span(1 last);
    }

    .main-container--full {
        width: 100%;
    }

    @include bp-max($bp-tablet) {
        .content-well .main-container {
            width: 100%;
            display: block;
            margin-right: 0px;
        }

        aside {
            display: none;
        }
    }
}
