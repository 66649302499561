﻿.people-landing {
    .results, .no-results {
        opacity: 1;
        transition: opacity .25s ease-in-out;

        &.loading {
            opacity: 0;

            &.ng-hide {
                display: block !important;
            }
        }
    }
}
