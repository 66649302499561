.detail-page-link {
    background-color: $colors__gray--f7;
    padding: 40px 0;

    @include media('>=tablet-portrait') {
        padding: 56px 0;
    }

    &__columns {
        @include media('>=tablet-portrait') {
            display: flex;
            justify-content: space-between;
        }
    }

    &__button-column {
        list-style-type: none;
        margin: 0;

        @include media('>=tablet-portrait') {
            width: calc(50% - 15px);
        }
    }

    &__button-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding: 40px 0;
        list-style-type: none;
        margin: 0;

        // @include media('>=tablet-portrait') {
        //     padding: 56px 0;
        // }
    }

    &__button {
        // flex: 0 1 calc(50% - 15px);
        flex: 1 1 100%;
        border-radius: 4px;
        overflow: hidden;
        border-left: 6px solid $colors__blue;
        margin-bottom: 16px;
        background-color: $colors__white;
        color: $colors__blue;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);

        @include transition(background-color, color);

        &:hover {
            background-color: $colors__blue;
            color: $colors__white;

            span {
                color: $colors__white;
            }
        }

        @include media('>=tablet-portrait') {
            flex: 0 1 calc(50% - 15px);
        }
    }

    &__button-link {
        padding: 16px 22px;
        display: inline-block;
        width: 100%;

        @include media('>=tablet-portrait') {
            padding: 24px 32px;
        }
    }

    &__button-text {
        @include fonts__sans-serif-bold;
        font-size: 22px;
        line-height: 1.2;
        color: $colors__blue;
        display: inline-block;

        @include media('>=tablet-portrait') {
            font-size: 24px;
        }
    }

    + .insights-section {
        padding-top: 0;
    }
}

// styles for if detail page link button is used
// inside of 2 col page layout intended for accordions
.detail-page-accordions {
    &__col--left,
    &__col--right {
        .detail-page-link {
            padding: 0;
            background-color: transparent;
        }

        .detail-page-link__button {
            flex: 1 1 100%;
            @include media('>=tablet-portrait') {
                flex: 1 1 100%;
            }
        }
    }
}
