label {
    font-size: 1em;
    color: $color-brown-spice;
}

input {
    @include placeholder {
        color: $color-nobel-grey;
    }
}

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
    width: 100%;
    font-family: $font-family-primary;
    color: $color-black;
    border: 1px solid $color-brown-tide;
    background-color: $color-white;
    padding: 5px;

    &:focus {
        background: linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.46) 46%, rgba(212, 212, 212, 1) 100% );
        box-shadow: none;
        background-color: $color-white;
    }
}

select {
    border-radius: 3px;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
    padding: 0.5em;
    height: 32px;

    &::placeholder {
        color: $color-black;
    }
}

input[type="file"] {
    width: 100%;
    font-family: $font-family-primary;
    color: $color-brown-spice;
    height: 40px;
}

input[type="submit"] {
    &:after {
        font-family: $font-family-fontawesome;
        position: absolute;
        right: 0;
        width: 50px;
        background-color: $color-blue-air-force-blue;
        text-align: center;
        content: "\f061";
        font-weight: normal !important;
        transition: background-color $transition-timing-primary ease-out;
    }
}

textarea {
    padding: 0.5em 1em;
    min-height: 64px;
    margin-bottom: 1em;
}

fieldset {
    border: 1px solid $color-yellow-akaroa;
    margin: 0 2px 1em 2px;
    padding: 0 0.35em 1em 0.75em;
}

legend {
    @include font-primary-light;
    padding: 0.5em;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 0.25em;
    line-height: 1.25;
    color: $color-brown-cocao-brown;
}

.field-validation-error {
    display: block;
    background-color: $color-red-fire-engine-red;
    color: $color-white;
    font-weight: bold;
    font-size: 0.85em;
    font-style: italic;
    padding: 0.5em 1em 0.5em 1em;
    margin-bottom: 1em;
    margin-top: -1em;
}

.content-well {
    input[type="text"],
    select {
        width: 100%;
    }

    select {
        -webkit-appearance: menulist;
        color: $color-brown-spice;
        margin-bottom: 1em;
        height: 34px;
        padding-left: 10px;
        border-radius: 7px;
        border: 1px solid $color-brown-tide;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+50,d4d4d4+100 */
        background: rgb(255, 255, 255); /* Old browsers */
        background: linear-gradient( to bottom, rgba(255, 255, 255, 1) 50%, rgba(212, 212, 212, 1) 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d4d4d4',GradientType=0 ); /* IE6-9 */
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"] {
        padding: 0.5em;
        height: 32px;
        color: $color-brown-spice;
        margin-bottom: 1em;
    }
}

.required-field label:after {
    content: "*";
    color: $color-red-fire-engine-red;
}

.captcha {
    margin-bottom: 1em;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
    color: $color-white !important;
}

form.forms {
    .forms__title {
        margin-bottom: 1em;
    }

    .forms__section {
        margin: 1em 0;
        padding: 1em;
        border: 1px solid $color-yellow-akaroa;
    }

    .forms__section-title {
        margin-bottom: 0.75em;
    }

    select,
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
        margin-bottom: 1em;
    }

    .forms__input--radio {
        display: block;
        margin-bottom: 0;

        input {
            width: 30px;
        }
    }

    .forms__input--checkbox {
        display: block;
        margin-bottom: 0;

        input {
            width: 30px;
        }
    }

    .forms__input-label {
        margin-bottom: 0.5em;
    }

    .forms__input-label--required:after {
        content: "*";
        color: $color-red-fire-engine-red;
    }
}
