.aws-tooltip {
    position: absolute;
    border-radius: 5px;
    display: none;
    z-index: 1000;
    font-family: $font-family-primary;

    p {
        margin-bottom: 0px;
    }

    &.visible {
        display: block;
    }
    // Arrows
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }

    &.noarrow:before {
        display: none;
    }
    // Positions (i.e. top = tooltip appears above trigger)
    &.top:before {
        bottom: -12px;
        left: 50%;
        margin-left: -8px;
        border-width: 12px 8px 0 8px;
        border-color: $color-white transparent transparent transparent;
    }

    &.right:before {
        left: -12px;
        top: 50%;
        margin-top: -8px;
        border-width: 8px 12px 8px 0;
        border-color: transparent $color-white transparent transparent;
    }

    &.bottom:before {
        top: -12px;
        left: 50%;
        margin-left: -8px;
        border-width: 0 8px 12px 8px;
        border-color: transparent transparent $color-white transparent;
    }

    &.left:before {
        right: -12px;
        top: 50%;
        margin-top: -8px;
        border-width: 8px 0 8px 12px;
        border-color: transparent transparent transparent $color-white;
    }
}

// Simple Tooltip
span.aws-tooltip {
    background: $color-black; /* IE */
    color: $color-white;
    padding: 6px 10px;
    min-width: 100px;
    max-width: 200px;
}

// Advanced Tooltip
div.aws-tooltip {
    background: $color-white;
    padding: 10px;
    width: 205px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0;

    p {
        @include font-primary-light;
        color: $color-brown-spice;
        font-size: 14px !important;
        text-align: left !important;
    }
}

.loading > .aws-tooltip {
    background: url("../images/ajax-loader.gif") no-repeat center center;
}
