/* Style the buttons that are used to open and close the accordion panel */
section.accordion-container {
    margin-bottom: 1.25em;
}

.accordion {
    background-color: $color-blue-darkest;
    color: $color-white;
    position: relative;
    cursor: pointer;
    padding: .75em 2rem 0.75em 1em;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 20px;
    line-height: 1.2;
    @include font-primary;
    margin-bottom: 1px;

    &:hover {
        background-color: $color-grey-very-light-grey;
        color: $color-brown-spice;
    }

    & + .article {
        margin-bottom: 1em;
    }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion.active {
    background-color: $color-grey-very-light-grey;
    color: $color-brown-spice;

    &:hover {
        background-color: $color-blue-darkest;
        color: $color-white;
    }
}

.accordion:after {
    // content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: .75em;
    /*color:$color-brown-spice;*/
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    @include font-fontawesome($fa-var-plus);
}

.accordion.active:after {
    // content: "\2796"; /* Unicode character for "minus" sign (-) */
    @include font-fontawesome($fa-var-minus);
}

div.article {
    padding: 0 18px;
    background-color: $colors__teal--lightest;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    opacity: 0;
    margin-bottom: 0px;

     p:last-child {
         margin-bottom: 24px;
     }

     h3 {
         @include fonts__serif-regular;
         font-size: 28px;
         color: $colors__blue;
         margin-bottom: 15px;
     }
}

div.article.show {
    opacity: 1;
    max-height: 10000px; /*Whatever you like, as long as its more than the height of the content (on all screen sizes) */
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: $colors__teal--lightest;
}