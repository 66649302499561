// styles for campaign pages with inline components

// financial health planning campaign page
.campaign__financial-health-planning {
    padding: 0 16px;
    
    .hbspt-form {
        max-width: 50em;
        margin: auto;
        padding: 15px 1em;
      }
    #hbspt-form-1581093512398-7772522611 .hs-form h1 {
      text-align: center;
    }
    #hbspt-form-1581093512398-7772522611 .hs-button {
      width: 100%;
    }
    .h2-camp {
      color: #c8712d !important;
      font-family: MuseoSans-300 !important;
      margin-left: -25px;
    }
    h1 {
      color: #33475b !important;
      font-family: MuseoSans-300 !important;
      font-size: 30px;
    }
    .left {
      float: left;
      width: 50%;
      padding-right: 20px;
    }
    .right {
      float: right;
      width: 50%;
      padding-left: 20px;
    }
    .group:after {
      content: "";
      display: table;
      clear: both;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    @include media('>=tablet-portrait') {
      .left,
      .right {
        float: none;
        width: auto;
      }
    }
    ul.check {
      list-style: none;
    }
    ul.check li:before {
      content: "✓";
    }
    li.checked {
      list-style-position: inside;
      text-indent: -1em;
    }
  
    .rectangle-copy {
      padding-bottom: 16px;
    }
  
    .rectangle-copy img {
      display: block;
    }
    .latest-news__item {
      height: 150px;
      border-right: 0px!important;
      margin-top: -7px;
      padding: 0 16px;
      border-right: 0;
    }
    .latest-news__title {
      font-size: 1.5em;
      line-height: 1.25;
    }
    .organizations {
      height: 47px;
      color: #000000;
      font-family: "MuseoSans-700";
      font-size: 24px;
      letter-spacing: 0;
      line-height: 29px;
      text-align: center;
    }
    .individuals {
      height: 47px;
      color: #000000;
      font-family: "MuseoSans-700";
      font-size: 24px;
      letter-spacing: 0;
      line-height: 49px;
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

}

// for manually implemented header images on campaign pages
// important! to override inline wysiwyg styles
.Himg {
  height:auto;
  width:100%;
  
  &--desktop {
    display: none!important;
  }

  &--mobile {
    display: block!important;
  }
}

@include media('>=tablet-portrait') {
  .Himg {
    &--desktop {
      display: block!important;
    }

    &--mobile {
      display: none!important;
    }
  }
}

// temporary css for leadership
.leadership-team {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__img {
        flex: 0 1 250px;
        display: block;

        @include media('>=tablet-portrait') {
            flex: 1 0 calc(100%/3);
            justify-content: flex-start;
        }

        img {
            float: none !important;
            display: block;
            width: 100% !important;
            height: auto !important;
            border-radius: 200px;
            
        }
    }

    &__content {
        display: block;
        flex: 1 1 auto;
        padding-left: 0px;

        @include media('>=tablet-portrait') {
            padding-left: 20px;
        }
    }

    @include media('>=tablet-portrait') {
        flex-wrap: nowrap;
    }

    &__icon--linkedin,
    &__icon--email {
        display: inline-block;
        margin: .5em 0;

        a {
            width: 30px;
            height: 30px;
            border: 1px solid $colors__blue;
            border-radius: 100%;
            display: block;
            overflow: hidden;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                height: 20px;
                width: 20px;
                display: block;
                background-position: center center;
                background-size: 20px 20px;
                color: $colors__blue;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__icon--linkedin {
        a::after {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='linkedin' class='svg-inline--fa fa-linkedin fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z'%3E%3C/path%3E%3C/svg%3E");
        }
    }

    &__icon--email {
        a::after {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='envelope' class='svg-inline--fa fa-envelope fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'%3E%3C/path%3E%3C/svg%3E");
        }
    }
}

.campaign {
  &__form-anchor {
    scroll-margin-top: 117px; //offset by height of fixed menu
  }
}
