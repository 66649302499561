.home-resources {
    background-color: $color-yellow-dutch-white;
    box-shadow: inset 0px 7px 20px -3px rgba(50, 50, 50, 0.40);
    padding-top: 2em;

    h2 {
        @include font-primary-light;
        font-size: 2.25em;
        text-align: center;
        margin: 0 0 .5em;
        color: $color-brown-cocao-brown;
    }

    .resource-container {
        margin-bottom: 1em;
        @include container;

        .border {
            border: 1px solid $color-gray-white;
            border-top: none;
        }

        .resource {
            margin-bottom: 1em;
            @include span(4);

            &:last-of-type {
                @include span(4 last);
            }

            .shadow {
                box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
            }

            h1 {
                @include font-primary-light;
                font-size: 1em !important;
            }

            ul {
                margin-bottom: .25em;
                line-height: 1;

                a {
                    color: $color-blue-shakespear;
                }

                li {
                    color: $color-brown-cocao-brown;
                    display: inline-block;
                    font-size: 12px;
                    text-transform: uppercase;
                    margin-right: -2px;
                    line-height: 1;

                    &:before {
                        content: ' |';
                        margin: 0 .25em;
                    }

                    &:first-child:before {
                        content: "";
                        margin: 0px;
                    }
                }
            }

            .resource-content-container {
                background-color: $color-white;
                padding: 1em;
                margin-top: -.45em;
            }

            @include bp-min(950px) {
                .resource-content-container {
                    height: 140px;
                }
            }
        }
    }

    .resources-more-information {
        padding-bottom: .50em;

        p {
            text-align: center;
            font-size: 1em;

            .fa {
                font-size: 1.5em;
                color: $color-blue-air-force-blue;
            }
        }
    }

    @include bp-max(949px) {
        .resource-container {
            .resource-content-container {
                height: 215px;
            }
        }
    }

    @include bp-max(600px) {
        .resource-container {
            .resource {
                margin-bottom: 1em;
                width: 100%;

                &:last-of-type {
                    width: 100%;
                }
            }

            .resource-content-container {
                max-width: 400px;
                height: 165px;
            }
        }
    }

    @include bp-max($bp-mobile) {
        .js-tooltip {
            display: none !important;
        }
    }
}

.inside-resources {
    padding-top: 2em;

    .resource-container {
        margin-bottom: 1em;
        @include container;

        .resource {
            margin-bottom: 1em;
            @include span(6);

            &:last-of-type {
                @include span(6 last);
            }

            h1 {
                @include font-primary-light;
                font-size: 1em !important;
            }

            ul {
                line-height: 1;
                margin-bottom: .25em;

                a {
                    color: $color-blue-shakespear;
                }

                li {
                    color: $color-brown-cocao-brown;
                    display: inline-block;
                    font-size: 12px;
                    text-transform: uppercase;
                    margin-left: 0px !important;
                    margin-right: -2px;

                    &:before {
                        content: ' |';
                        margin: 0 .25em;
                    }

                    &:first-child:before {
                        content: "";
                        margin: 0px;
                    }
                }
            }

            .resource-content-container {
                background-color: $color-white;
                padding: 1em;
                margin-top: -.45em;
                border: 1px solid $color-gray-white;
                border-top: none;
                box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
            }

            @include bp-min(950px) {
                .resource-content-container {
                    max-width: 400px;
                    height: 140px;
                }
            }

            @include bp-max(949px) {
                .resource-content-container {
                    max-width: 400px;
                    height: 190px;
                }
            }
        }
    }

    @include bp-max(550px) {
        .resource-container {
            .resource {
                margin-bottom: 1em;
                width: 100%;

                &:last-of-type {
                    width: 100%;
                }
            }

            .resource-content-container {
                max-width: 400px;
                height: 165px !important;
            }
        }
    }
}
