.section-body {
    --section-body__font-size: 16px;
    --section-body__margin-top: 24px;

    @include fonts__sans-serif-light;
    line-height: 1.5;
    font-size: var(--section-body__font-size);
    color: $colors__gray--33;
    margin-top: var(--section-body__margin-top);

    p {
        color: $colors__gray--33;
        font-size: var(--section-body__font-size);
        font-weight: 300;
        line-height: 1.5;        
        margin-top: var(--section-body__margin-top);
    }

    a {
        font-size: inherit;
        color: $colors__blue;
        @include fonts__sans-serif-bold;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media('>=tablet-landscape') {
        --section-body__font-size: 22px;
        --section-body__margin-top: 32px;
    }
}
