.page-hero-image {
    position: relative;
    overflow: hidden;

    .hero-image {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        max-width: none;
    }

    &.hero-careers-landing {
        .hero-tagline {
            position: absolute;

            &:first-of-type {
                color: $color-white;
                top: 15%;
                left: 40%;
                width: 100%;
                transform: translateX(-400px);
                @include font-primary-medium-bold(90px);
            }

            &:last-of-type {
                top: 55%;
                left: 45%;
                width: 100%;
                transform: translateX(-50px);
                color: $color-grey-very-light-grey;
                @include font-primary-light(65px);
            }

            @include bp-max(1050px) {
                $font-size: 80px;

                &:first-of-type {
                    font-size: px-to-em($font-size);
                    transform: none;
                    left: 10%;
                }

                &:last-of-type {
                    font-size: px-to-em($font-size);
                    transform: none;
                    right: 5%;
                    width: auto;
                    left: auto;
                }
            }
        }

        @include bp-max(900px) {
            .hero-image {
                height: px-to-em(186px);
                transform: translateX(-55%);
                top: 0;
            }

            .hero-tagline {

                &:first-of-type {
                    font-size: 3em;
                }

                &:last-of-type {
                    text-shadow: 1px 1px 1px $color-black;
                    font-size: 2.75em;
                }
            }
        }

        @include bp-max(525px) {
            .hero-image {
                height: px-to-em(186px);
                transform: translateX(-55%);
                top: 0;
            }

            .hero-tagline {

                &:first-of-type {
                    font-size: 3em;
                }

                &:last-of-type {
                    text-shadow: 1px 1px 1px $color-black;
                    font-size: 2em;
                }
            }
        }
    }
}
