.rte {
    --rte__font-size: 16px;
    --rte__headline-font-size: 28px;
    --rte__h3-font-size: 24px;
    --rte__list-padding: 24px;
    --rte__y-spacing: 24px;
    font-size: var(--rte__font-size);
    line-height: 1.5;
    color: $colors__gray--33;

    &--small {
        --rte__font-size: 14px;
    }

    &--disclaimer {
        --rte__font-size: 14px;
        margin-bottom: 0.5em;
    }

    &--break-spaces {
        white-space: pre-line;
    }

    &--spaced {
        margin-top: 40px;
    }

    * {
        // margin: 0;
        font-size: inherit; // override legacy styles
        line-height: inherit; // override legacy styles
        // margin-bottom: 0; // override legacy styles
        font-weight: inherit; // override legacy styles
        color: inherit; // override legacy styles
        // letter-spacing: 0; // override legacy styles
        // text-transform: none; // override legacy styles
    }

    & > * + * {
        margin-top: var(--rte__y-spacing);
    }

    & > * + h2,
    & > * + h3,
    & > * + h4,
    & > * + h5 {
        // Set margin-top to 1.25em but also set a minimum margin top of 1.5rem;
        margin-top: #{"max(1.25em, 1.5rem)"};
    }

    h2,
    .rte__headline {
        @include fonts__serif-regular;
        font-size: var(--rte__headline-font-size);
        line-height: 1.15;
        color: $colors__blue;
        margin-bottom: 24px;
    }

    .rte__headline-with-border {
        @include fonts__serif-regular;
        font-size: var(--rte__headline-font-size);
        line-height: 1.15;
        color: $colors__blue;
        margin-bottom: 24px;
        border-bottom: 4px solid $color-grey-light-grey-hq;
        padding-bottom: 6px;
        display: block;
    }

    h3,
    .rte__subhead {
        @include fonts__sans-serif-medium;
        font-size: var(--rte__h3-font-size);
        line-height: 1.15;
        color: $colors__blue;
    }

    h4 {
        @include fonts__sans-serif-light;
        color: $colors__blue;
        font-size: 18px;
        line-height: 1.25;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    h5 {
        @include fonts__sans-serif-bold;
        color: $colors__blue;
    }

    h6 {
        @include fonts__sans-serif-medium;
        color: $colors__blue;
    }

    .rte__section-header {
        @include component-header__styles();
        display: block;

        &:first-child {
            margin-top: 0;
        }
    }

    > .rte__section-header {
        &:first-child {
            margin-top: 0;
        }
    }

    b, strong {
        font-weight: bold;
    }

    img, iframe, figure {
        max-width: 100%;
    }

    img {
        height: auto !important;
    }

    ul, ol {
        font-size: inherit;
        padding-left: var(--rte__list-padding);
        // list-style-type: initial;
    }
    // style ordered list numbers
    ol.styled-ol {
        counter-reset: ol-counter;
        list-style: none;
        padding-left: 0;

        li {
            --ol-num-size: 30px;
            --ol-num-padding: 10px;
            position: relative;
            counter-increment: ol-counter;
            padding-left: calc(var(--ol-num-size) + var(--ol-num-padding));
            margin-bottom: 1em;
            min-height: var(--ol-num-size);

            &::before {
                position: absolute;
                content: counter(ol-counter);
                color: $colors__white;
                top: 0;
                left: 0;
                background-color: $colors__blue;
                height: var(--ol-num-size);
                width: var(--ol-num-size);
                text-align: center;
                display: block;
                border-radius: 100%;
                z-index: 0;
                font-size: 1em;
                line-height: var(--ol-num-size);
            }

            @include media('>=tablet-portrait') {
                --ol-num-size: 50px;
            }
        }
    }

    li {
        margin: 0; // override legacy styles
        display: list-item;
        // list-style: initial;
        margin-top: 8px;

        &:before {
            content: none; // override legacy styles
        }
    }

    ul {
        list-style: disc;

        ul {
            list-style: circle;

            li {
                list-style: circle;
            }

            ul {
                list-style: square;

                li {
                    list-style: square;
                }
            }
        }
    }

    ol {
        list-style: number;

        ol {
            list-style: lower-alpha;

            li {
                list-style: lower-alpha;
            }

            ol {
                list-style: lower-roman;

                li {
                    list-style: lower-roman;
                }
            }
        }
    }

    a {
        font-size: inherit;
        color: $colors__blue;
        @include fonts__sans-serif-bold;

        &:hover {
            text-decoration: underline;
        }
    }

    a[name] {
        scroll-margin-top: 4.5em; //mobile menu height

        @include media('>=tablet-portrait') {
            scroll-margin-top: 8.3125em; //desktop menu height
        }
    }

    blockquote {
        float: none; // override legacy styles
        min-width: 0; // override legacy styles
        border: none; // override legacy styles

        @include fonts__sans-serif-medium;
        font-size: 24px;
        font-style: italic;
        margin-left: 2rem;
        padding-left: 1.25em;
        padding-right: 1.25em;
        border-left: 4px solid $colors__teal;
        max-width: none;

        &:not(:first-child) {
            margin-top: 1.5em;
        }

        &:not(:last-child) {
            margin-bottom: 1.5em;
        }
    }

    cite {
        @include fonts__sans-serif-medium;
        font-size: 16px;
        display: block;
        margin-top: 10px;

        &:before {
            content: none; // override legacy styles
        }
    }

    figcaption {
        font-size: 0.75em;
    }

    .rte__table {
        tr {
            &:nth-child(even) {
                background-color: $colors__gray--f7;
            }

            &:nth-child(odd) {
                background-color: $colors__gray--ee;
            }
        }

        th {
            @include fonts__sans-serif-medium;
            padding: 1rem;
            background-color: $colors__orange--light;
            color: $colors__gray--33;
        }

        td {
            padding: 1rem;
            color: $colors__gray--33;
        }
    }

    &__title {
        border-bottom: 4px solid $colors__gray--d8;
        padding-bottom: 5px;
    }

    &__columns {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: -1em;
        margin-right: -1em;

        > div {
            margin: 0 1em;

            @include media('<=tablet-portrait') {
                flex: 1 1 100%;
            }
        }
    }

    &__column {
        &--3 {
            flex: 1 1 calc((100%/4) - 1em);
        }

        &--4 {
            flex: 1 1 calc((100%/3) - 1em);
        }

        &--5 {
            flex: 1 1 calc(((5/12) * 100%) - 1em);
        }

        &--6 {
            flex: 1 1 calc((100%/2) - 1em);
        }
    }

    &__blue-box {
        padding: rem(20px);
        background-color: $colors__blue--light;

        ul {
            padding-left: 2em;

            li {
                @include fonts__sans-serif-medium;
            }
        }
    }

    &__contact-module-green {
        background-color: $colors__green--alt;
        color: $colors__blue;
        padding: rem(20px);
        margin-top: rem(40px);
        display: block;

        h3 {
            @include fonts__sans-serif-bold();
        }

        @include media('>=tablet-landscape') {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: rem(30px) rem(40px);
            margin-top: rem(48px);

            p {
                margin-bottom: 0;

                &:last-child {
                    margin-left: 1em;
                    flex-shrink: 0;
                    max-width: 45%;
                }
            }
            // &-content {
            //     padding-right: 9%;
            //     flex-grow: 1;
            // }
            // &-cta {
            //     flex-shrink: 0;
            //     max-width: rem(200px);
            //     .call-to-action--section-margin {
            //         margin-top: 0;
            //     }
            // }
        }
    }

    @include media('>=tablet-portrait') {
        --rte__headline-font-size: 34px;
        --rte__section-header-font-size: 24px;

        &--spaced {
            margin-top: 48px;
        }
    }

    @include media('>=tablet-landscape') {
        --rte__font-size: 18px;
        --rte__headline-font-size: 38px;
        --rte__section-header-font-size: 28px;
        --rte__h3-font-size: 24px;
        --rte__list-padding: 32px;

        &--disclaimer {
            --rte__font-size: 14px;
        }
    }
}

// Legacy RTE styles
.group {
    a[name] {
        scroll-margin-top: 4.5em; //mobile menu height
        
        @include media('>=tablet-portrait') {
            scroll-margin-top: 8.3125em; //desktop menu height
        }
    }
}

.rte--link-styles {
    a {
        font-size: inherit;
        color: $colors__blue;
        @include fonts__sans-serif-bold;

        &:hover {
            text-decoration: underline;
        }
    }
}
