.media-contact {
    a .fa {
        color: $color-brown-spice;

        &:hover {
            color: $color-brown-cocao-brown;
        }
    }

    .fa {
        font-size: 1.65em;
        margin-right: .25em;
    }
}
