.generic-hero {    
    position: relative;
    @include media('>=tablet-portrait') {
        padding: 80px 0;
    }
    
    &__background {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        height: 200px;
        display: block;
        
        @include media('>=tablet-portrait') {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &__content {
        background-color: $colors__teal;
        width: 100%;
        padding: 20px 35px;
        position: relative;
        z-index: 1;
        // padding-left: calc((100vw - 1100px)/2);
        
        @include media('>=tablet-portrait') {
            width: 40%;
            padding: 40px 70px;
            padding-left: calc((100vw - 1100px)/2);
        }
    }

    &__content-inner {
        text-align: center;
        padding-left: 0;
        @include media('>=tablet-portrait') {
            padding-left: 1em;
            text-align: left;
        }
    }

    &__title {
        color: $colors__blue;
        margin-bottom: 8px;
        font-size: 32px;
        @include media('>=tablet-portrait') {
            font-size: 42px;
        }

        @include media('>=tablet-landscape') {
            font-size: 52px;
        }
    }

    p {
        @include fonts__sans-serif-light;
        color: $colors__blue;
        font-size: 18px;

        @include media('>=tablet-portrait') {
            font-size: 24px;
        }
 
    }

    &__cta {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        padding: 9px 24px;
        border: 2px solid $colors__blue;
        border-radius: 4px;
        display: block;
    }
}