.inspired-carousel {
    &-component {
        --featured-content__content-padding: 48px 0;
        --subhead: 24px;
            
        padding: var(--featured-content__content-padding);
        text-align: left;

        &__heading {
            margin-bottom: 24px;
        }

        &__intro {
            margin-bottom: 24px;
        }

        &__image {
            width: 80%;
            display: block;
            margin: 0 auto 16px;
        }

        @include media('>=tablet-portrait') {
            --featured-content__content-padding: 64px 0;

            &__heading {
                margin-bottom: 32px;
            }

            // Swiper specific updates
            // CW2-I135
            .swiper-wrapper {
                align-items: stretch;
                align-content: stretch;
                height: auto;
            }

            .swiper-slide {
                height: inherit;
                display: flex;
                align-items: stretch;
                margin-bottom: 0;
            }

            &-card {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                align-content: stretch;
                height: 100%;
            }
        }
    }

    &-card {
        &-image {
            width: 100%;
            margin-bottom: 16px;
        }

        &-content {
            @include fonts__serif-regular;
            color: $colors__blue;
            display: block;
        }

        &-author {
            font-size: 24px;
            line-height: 1.143;
        }

        &-testemonial {
            @include fonts__sans-serif-light;
            padding-top: 10px;
            font-style: italic;

            quotes: '\201c' '\201d';
            &:before { 
                content: open-quote; 
            }
            &:after { 
                content: open-quote; 
            }
        } 
    }   
}