.email-subscribe {
    --email-subscribe__padding: 36px 0;

    margin: var(--email-subscribe__padding);
    text-align: center;

    &__body {
        margin-top: 8px;
    }

    &__form {
        margin: 24px auto 0;
        max-width: 580px;
        display: flex;
    }

    &__label {
        border: 1px solid $colors__gray--c5;
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .call-to-action {
        width: auto;
    }

    &__input.email-subscribe__input {
        height: 100%;
        border: none;
    }

    @include media('>=tablet-portrait') {
        --email-subscribe__padding: 48px 0;

        &__label {
            margin-right: 16px;
        }
    }

    @include media('>=tablet-landscape') {
        --email-subscribe__padding: 56px 0;
    }

    @media print {
        display: none;
    }
}
