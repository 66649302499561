.speaking-engagements {

    .events-container {
        margin-top: .5em;
        @include container;

        .event-left {
            @include span(6);

            @include bp-max(400px) {
                display: block;
                width: 100%;
            }
        }

        .event-right {
            @include span(6 last);

            @include bp-max(400px) {
                display: block;
                width: 100%;
            }
        }
    }
}
