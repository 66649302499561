$colors__white: #FFFFFF;
$colors__black: #000000;

$colors__blue: #2D3C59;
$colors__blue--dark: #343844;
$colors__blue--dark-alt: #1F2A3E;
$colors__blue--darkest: #1B2435;
$colors__blue-alt: #2E334E;
$colors__blue--light: #f0f8ff;
$colors__teal--dark: #64A8A9;
$colors__teal: #7DD2D3;
$colors__teal--light: #C2EAEA;
$colors__teal--lightest: #F2FBFB;
$colors__teal--lightest-alt: #E5F6F6;
$colors__teal--essence: #39A5A7;
$colors__gray--33: #333333;
$colors__gray--25: #25282A;
$colors__gray--84: #848484;
$colors__gray--71: #717171;
$colors__gray--ac: #ACACA7;
$colors__gray--c5: #C5C5C1;
$colors__gray--d8: #D8D8D8;
$colors__gray--de: #DEDEDC;
$colors__gray--ee: #EEEEED;
$colors__gray--f7: #F7F7F6;
$colors__red: #F0583B;
$colors__orange: #FBC55A;
$colors__orange--light: #FCD17B;
$colors__orange--saffron: #FBC55A;
$colors__green: #DCE464;
$colors__green--light: #EEF2B2;
$colors__green--dark: #c0ca3d;
$colors__green--alt: #EAEFA2;
$colors__riptide: #7DD2D3;
$colors__navy: #2E334E;
$colors__celadon: #E2E868;
$colors__saffron: #FBC55A;
$colors__cloud: #F7F7F6;
$colors__riptidetint: #C2EAEA;
$colors__celadontint: #EEF2B2;
$colors__saffrontint: #FCD17B;

// AA Accessibility Text Mappings
$colors__gray-on-white: $colors__gray--25;
