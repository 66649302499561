.recomended-container {
    @include container;
    padding-top: 1em;
    float: left;
    border-top: 1px solid $color-gray-light-grey;
    width: 100%;

    .recommended-article:last-child,
    .recommended-article-3:last-child,
    .recommended-article-2:last-child,
    .recommended-article-1:last-child {
        margin-right: 0;
    }

    @include bp-min(501px) {
        .recommended-article {
            width: 23.40438%;
            float: left;
            margin-right: 2.1275%;
        }

        .recommended-article-3 {
            width: 31.8%;
            float: left;
            margin-right: 2.1275%;
        }

        .recommended-article-2 {
            width: 48.8%;
            float: left;
            margin-right: 2.1275%;
        }

        .recommended-article-1 {
            display: flex;

            & a {
                flex-basis: 50%;
                flex-shrink: 0;
            }

            & p {
                padding: 0 1em;
            }

            &-abstract {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 2em;
            }

            &-buttonlink {
                color: $color-grey-dark-grey;
                border: 1px solid $color-grey-dark-grey;
                background: rgba(0,0,0,0);
                font-size: 1.25em;
                font-family: MuseoSans-700;
                font-style: normal;
                padding: 10px 20px;
                margin-left: 1em;

                &:hover {
                    background-color: #fff !important;
                    text-decoration: none !important;
                    color: $color-grey-dark-grey;
                }
            }
        }

        p {
            font-size: 1.10em;
        }
    }

    @include bp-max(500px) {
        .recommended-article,
        .recommended-article-3,
        .recommended-article-2,
        .recommended-article-1 {
            width: 100%;
            float: none;
            margin-right: 0px;
            margin-bottom: 1em;
            display: block;

            &-abstract, &-buttonlink {
                display: none;
            }
        }

        p {
            font-size: 1em;
        }

        img {
            width: 100%;
        }
    }
}

.service {
    .featured-resources {
        margin-top: 3.5em;
    }
}

.office-news > .recomended-container:first-child {
    border-top: 1px solid $color-gray-light-grey;
}

.office-news > .recomended-container:nth-child(n+2) {
    border-top: none;
}
