.hbspt-form {
    .hs-button {
        color: $color-white !important;
        background-color: $color-dark-blue-footer !important;
        border: 2px solid $color-dark-blue-footer !important;
        box-shadow: none !important;
        text-shadow: none !important;
        font-size: 20px !important;
        padding: 7px 24px !important;
        line-height: 1.5 !important;
        font-weight: 400 !important;
        font-family: MuseoSans-700, arial, sans-serif !important;
        transition: background-color .25s ease-in-out, color .25s ease-in-out !important;

        &:hover {
            color: $color-dark-blue-footer !important;
            background-color: $color-white !important;
            text-decoration: none;
        }
    }
}

.hubspot-form {
    &__narrow-width {
        margin-bottom: 30px;
    }

    .rte {
        a {
            font-size: inherit !important;
            color: $color-dark-blue-footer !important;
            font-family: MuseoSans, arial, sans-serif !important;
        }
    }
}
