/*TEXT JUSTIFICATION*/
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

/*BORDERS*/
.border-top {
    border-top: 1px solid #d4d4d4;
}

.border-bottom {
    border-bottom: 1px solid #d4d4d4;
}

.border-right {
    border-right: 1px solid #d4d4d4;
}

.border-left {
    border-left: 1px solid #d4d4d4;
}

/*FONT WEIGHT OVER RIDERS*/
.font-normal {
    font-weight: normal !important;
}

.font-bold {
    font-weight: bold !important;
}

/*PHOTOS*/
.photo-left {
    float: left;
    margin-bottom: 15px;
    margin-right: 15px;
}

.photo-right {
    float: right;
    margin-bottom: 15px;
    margin-left: 15px;
}

.photo-smaller-left {
    float: left;
    margin-bottom: 15px;
    margin-right: 15px;
    max-width: 65%;
}

.photo-smaller-right {
    float: right;
    margin-bottom: 15px;
    margin-left: 15px;
    max-width: 65%;
}

/*HIDE|SHOW*/
.hide {
    display: none;
}

.show-for-print {
    display: none;
}

.invisible {
    visibility: hidden;
}

@include bp-max(99999999999px) {
    .hide-for-large {
        display: none;
    }
}

@include bp-max(768px) {
    .hide-for-large {
        display: block;
    }

    .hide-for-medium-down {
        display: none;
    }
}

@include bp-max(550px) {
    .hide-for-medium-down {
        display: none;
    }

    .hide-for-small {
        display: none;
    }
}
/*POSTIONING*/
.fixed {
    position: fixed; /*top:0;*/
}

.byline, .caption {
    font-size: 16px !important;
    font-style: italic;
    margin-left: 0px;
}

.intro {
    font-size: 1.250em;
}

time {
    display: block;
    font-style: italic;
    font-size: 0.875em;
    margin-top: -7px;
}

.date {
    font-style: italic;
}

/*OPTIONAL 2 COLUMNS*/
@include bp-max(99999999999px) {
    .col-left {
        width: 50% !important;
        float: left;
        margin-right: 5% !important;
    }

    .col-right {
        width: 41% !important;
        float: left;
    }
}

@include bp-max(900px) {
    .col-left {
        width: 47.5% !important;
        float: left;
        margin-right: 5% !important;
    }

    .col-right {
        width: 47.5% !important;
        float: left;
    }
}

@include bp-max(550px) {
    .col-left, .col-right {
        width: 100% !important;
        display: block;
        float: none;
    }
}

/*BASIC HORIZONTAL MENU*/
ul.menu {
    display: block;
    overflow: hidden;
    margin-left: 0;
    list-style: none;

    > *:before {
        content: '|';
        position: relative;
        top: 1px;
        margin: 0 0.25em;
    }

    > *:first-child:before {
        content: '';
        margin: 0px;
    }
}

ul.menu > li {
    margin: 0;
    float: left;
    display: inline-block;

    a, span {
        padding: 0.25em 0em;
        line-height: 1;
    }
}

/*OTHER LIST STYLE OPTIONS*/
ul.no-bullets {
    margin-left: 0px !important;

    li {
        list-style: none !important;
        margin-left: 0px !important;
        padding-left: 0px !important;

        &:before {
            content: none !important;
            margin-left: 0px !important;
        }
    }
}

ul.tight {
    li {
        margin-bottom: 0px !important;
        line-height: 1.15;
    }
}

ul.publications {
    font-size: 0.875em;
    margin-top: -7px;

    li {
        display: inline;
        list-style: none;
        font-style: italic;

        &.after:first-of-type:after {
            content: " | ";
            font-style: normal;
        }

        &:first-of-type:before {
            display: none !important;
        }

        &:before {
            padding-left: 0px;
        }

        &:last-of-type:after {
            content: "";
        }
    }
}

ul.search-results-meta {
    font-size: 0.875em;
    margin-bottom: 0px;
    margin-left: 0px;

    li {
        display: inline;
        list-style: none;
        font-style: italic;
        padding-left: 0px !important;

        &:before {
            content: " | ";
            font-style: normal;
            margin-left: 0;
            padding-left: .3em;
        }

        &:first-of-type:before {
            content: "";
        }
    }
}

ul.inline-list, ol.inline-list {
    margin: 0 auto 1.0625em auto;
    margin-left: -1.375em;
    margin-right: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}

ul.inline-list > li, ol.inline-list > li {
    list-style: none;
    float: left;
    margin-left: 1.375em;
    display: block;
}

ul.inline-list > li > *, ol.inline-list > li > * {
    display: block;
}

ul.list-group {
    li.list-group-item-danger {
        font-size: .75em;
        font-style: italic;
        color: #660003;
    }
}

ul.meta-list {
    display: block;
    font-style: italic;
    width: 100%;
    font-size: 16px;
    margin-left: 0px !important;

    li {
        margin-left: 0px !important;
        display: inline-block;
        list-style: none !important;
        // &::after {content:" - ";}
        // &:not(:first-child):last-child::before   {content:" - ";}
        &.author:first-child::before {
            content: "By ";
        }

        &.author:not(:first-child)::before {
            content: " and ";
        }
    }

    .date {
        display: block;
    }
}

ul.small {
    li {
        font-size: .85em;
    }
}

/*these are editable in sitecore*/
.telephone:before {
    content: "T: ";
}

.mobile-phone:before {
    content: "M: ";
}

.fax:before {
    content: "F: ";
}

// .name {font-weight:bold;}

.contact-callout:before {
    content: "Contact: ";
    font-weight: bold;
}

/*Background-colors*/
.bg-blue {
    background-color: $color-blue-prusian-blue;
}

.bg-purple {
    background-color: $color-violet-jagger;
}

.bg-orange {
    background-color: $color-orange-rich-gold;
}

.bg-brown {
    background-color: $color-brown-spice;
}

.bg-green {
    background-color: $color-green-olive;
}

.bg-red {
    background-color: #660003;
}
/*UPDATE THIS LATE*/

/*No text reversing example:text in buttons*/
.no-reverse {
    webkit-transform: none !important;
    transform: none !important;
}

.site-logo-container img {
    margin-bottom: 1em;
}

// HTML Templates (implemented for AWS Tooltip)
#html_templates, .html_templates {
    display: none;
}
