.clienthq-featured-resources {
    .clienthq-tabs {
        clienthq-tabs__nav {
            margin: 0;

            .clienthq-tabs__nav-item {
                margin-left: 0;
            }
        }

        &__inner-featured-resources {
            .clienthq-tabs__nav {
                display: flex;
            }

            .clienthq-tabs__nav-item-label {
                color: #565656;
                font-size: 16px;
                line-height: normal;
                @include fonts__sans-serif-regular;
            }
        }

        &__featured-resources {
            .clienthq-tabs {
                &__tab-content {
                    max-width: 775px;
                }

                &__inner__nav-container {
                    padding-left: 20px;
                }

                &__nav {
                    border-bottom: none;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    a {
                        @include fonts__sans-serif-semibold;
                        text-decoration: none;
                        color: $colors__blue-alt;
                        font-size: 12px;
                    }

                    &--wrapper {
                        display: flex;
                        align-items: center;
                    }

                    &--preferences {
                        display: flex;
                        gap: 1em;

                        &:after {
                            content: "\f105";
                            font-family: FontAwesome;
                            font-size: 1em;
                            font-weight: 600;
                            color: #fff;
                            background-color: #2d3c59;
                            border-radius: 100%;
                            text-align: center;
                            line-height: 1.5;
                            display: block;
                            height: 1.5em;
                            width: 1.5em;
                        }
                    }
                }

                &__nav-item {
                    border-bottom: none;
                    font-family: inherit;
                    transition: all .2s ease;

                    &:hover {
                        border-bottom: none;
                        background-color: white;
                    }

                    &.active {
                        background-color: white;
                        color: black;
                    }

                    &-label {
                        @include fonts__sans-serif-semibold;
                        color: $colors__navy;
                        font-size: 12px;
                        line-height: normal;
                        height: 100%;
                        padding: 10px;
                        align-content: center;
                    }

                    &:last-child {
                        margin-left: 0px;
                    }
                }

                &--no-results {
                    padding: 20px;
                    font-size: 12px;

                    @include fonts__sans-serif-regular;

                    p {
                        color: $colors__blue-alt;
                        font-weight: 600;
                    }
                }
            }

            .featured-resources {
                &__carousel {
                    display: flex;
                    overflow: hidden;
                    position: relative;
                    width: 100%;

                    .swiper-carousel {
                        width: 100%;

                        &__content-wrapper {
                            padding: 0px;
                        }

                        &__nav-button {
                            z-index: 2;
                            top: 30%;
                            margin: 10px;
                        }

                        .swiper-slide {
                            height: auto;
                        }
                    }

                    .insight-card {
                        height: 100%;
                        border-radius: 6px;
                        background-color: #fff;
                        overflow: hidden;

                        &__container {
                            padding: 1.5em;
                        }

                        &__img {
                            margin-bottom: 0;
                            padding-bottom: 0;
                            object-fit: cover;
                            height: 172px;
                            width:100%;
                        }

                        &__link {
                            text-decoration: none;
                            color: #2D3C59;
                            font-size: 21px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &__topics {
                            @include fonts__sans-serif-semibold;
                            color: $colors__blue;
                            margin-top: 16px;
                            padding-top: 20px;
                            font-size: 12px;
                            line-height: normal;
                            display: flex;
                            gap: 5px;
                            flex-wrap: wrap;
                            border-top: 1px solid $colors__gray--d8;

                            a {
                                color: $colors__blue;
                                text-decoration: none;

                                &:not(:last-of-type)::after {
                                    content: ', ';
                                }

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        &__info {
                            @include fonts__sans-serif-semibold;
                            padding-bottom: 2px;
                            font-size: 12px;
                            color: #4A4A4A;

                            &--wrapper {
                                display: flex;
                            }

                            &--bookmark {
                                &:after {
                                    content: "\f02e";
                                    font-family: FontAwesome;
                                    font-size: 1.34em;
                                    color: $colors__teal--essence;
                                }
                            }

                            &--underline {
                                border-bottom: 3px solid #EE5340;
                            }

                            & + .insight-card__info {
                                &::before {
                                    content: '|';
                                    padding: 0 10px;
                                }
                            }

                            &-container {
                                justify-content: space-between;
                            }
                        }
                    }

                    .insight-card__info:not(:last-child) {
                        padding-right: 0;

                        &::after {
                            display: none;
                        }
                    }

                    &--view-all {
                        justify-items: center;
                        align-content: center;
                        height: auto;

                        .view-all__link {
                            background-color: white;
                            width: 120px;
                            height: 120px;
                            border-radius: 100px;
                            box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
                            float: left;
                            margin-left: 10px;
                            position: relative;
                            bottom: 17%;

                            a {
                                text-decoration: none;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                border-radius: 100px;
                                justify-content: center;
                                align-items: center;
                                color: #2d3c59;
                                @include fonts__sans-serif-semibold;

                                &::after {
                                    padding-left: 10px;
                                    content: "\f105";
                                    font-family: FontAwesome;
                                    font-size: 1.25em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.bookmarkicon:after {
    content: "\f097";
    font-family: FontAwesome;
}
