.event-tiles {
    &__intro {
        background-size: cover;
        color: $color-white;
        position: relative;
    }

    &__background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*background: none no-repeat center fixed;*/
        background-size: cover;
        background-position: left;

        &--mobile {
            display: none;
        }
    }

    &__intro-inner {
        padding-top: 50px;
        padding-bottom: 50px;
        display: flex;
        position: relative;
        z-index: 1;
    }

    &__logo {
        width: 85px;
        margin-right: 18px;
    }

    &__logo-image {
        width: 85px;
        height: 85px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__intro-block {
        max-width: 430px;
    }

    &__title {
        font-size: 3.313em;
        @include font-primary-light;
    }

    &__subtitle {
        font-size: 1.875em;
        margin-bottom: 50px;
        @include font-primary;
    }

    &__description {
        font-size: 1.375em;
        margin-bottom: 20px;
    }

    &__register-link {
        background: $color-white;
        color: $color-blue-blue;
        margin-bottom: 0;

        &:hover {
            background: $color-blue-blue;
            color: $color-white;

            &:after {
                background: $color-white;
                color: $color-blue-blue;
            }
        }
    }

    &__events {
        background: $color-blue-blue;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__events-inner {
        display: flex;
        margin-bottom: 40px;
    }

    &__event {
        background: $color-white;
        margin-right: 26px;
        color: $color-brown-spice;
        padding: 30px;
        flex: 1 1 0px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;

        &--past {
            background: $color-blue-pale;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            text-decoration: none;

            .event-tiles {
                &__event-info {
                    &:before {
                        color: $color-brown-russet;
                    }
                }

                &__event-date {
                    color: $color-brown-spice;
                }

                &__event-name,
                &__event-register {
                    color: $color-blue-blue;
                }
            }
        }
    }

    &__upcoming-label {
        text-transform: uppercase;
        color: $color-brown-russet;
        @include font-primary;
    }

    &__event-date {
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    &__event-name {
        font-size: 1.5em;
        margin-bottom: 20px;
        @include font-primary-medium;
    }

    &__event-register {
        font-size: 1.25em;
        text-decoration: underline;
        color: $color-brown-cocao-brown;
        position: relative;
        padding-right: 35px;
        display: inline-block;
        @include font-primary;

        &:after {
            font-family: $font-family-fontawesome;
            font-size: 22px;
            position: absolute;
            right: 0;
            content: '\f061';
            font-weight: normal !important;
            text-decoration: none;
        }
    }

    &__link-wrapper {
        text-align: center;
    }

    &__see-all-link {
        color: $color-white;
        border: 1px solid $color-white;
        background: transparent;
        font-size: 1.25em;
        @include font-primary-medium;
        padding: 10px 20px;

        &:hover {
            color: $color-blue-blue;
            background: $color-white;
            text-decoration: none;
        }
    }

    @include bp-max($bp-desktop) {
        &__logo {
            width: 55px;
        }

        &__logo-image {
            width: 55px;
            height: 55px;
        }

        &__title {
            font-size: 2.125em;
        }

        &__subtitle {
            font-size: 1.5em;
        }

        &__description {
            font-size: 1.125em;
        }

        &__events-inner {
            padding: 0 50px;

            .slick-track {
                display: flex;
                height: 100%;

                &:after,
                &:before {
                    display: none;
                }
            }

            .fa-chevron-left, .fa-chevron-right {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 9999;
                color: $color-brown-spice;
                opacity: 0.45;
                font-size: 4em;

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }

            .fa-chevron-left {
                left: 0;
            }

            .fa-chevron-right {
                right: 0;
            }
        }

        &__event {
            margin: 0 13px;
            float: none;
        }

        &__event-name {
            font-size: 1.375em;
        }

        &__event-register {
            color: $color-blue-blue;
        }
    }

    @include bp-max(767px) {

        &__background-image {
            display: none;

            &--mobile {
                display: block;
                background-position: center;
            }
        }

        &__intro-inner {
            height: 495px;
            position: relative;
        }

        &__mobile-group {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;

            &-top {
                padding: 20px 24px;
                background: rgba(0, 36, 102, 0.75);
            }

            &-bottom {
                padding: 20px 24px;
                background: rgba(33, 128, 188, 0.75);
            }
        }

        &__subtitle {
            margin-bottom: 14px;
        }

        &__description {
            margin-bottom: 0;
        }

        &__events-inner {
            padding: 0 35px;

            .fa-chevron-left, .fa-chevron-right {
                font-size: 38px;
            }
        }

        &__event {
            margin: 0 5px;
        }

        &__event-register {
            color: $color-blue-blue;
        }

        &__see-all-link {
            display: block;
        }
    }
    // Just below ipad portrait
    @include bp-max(767px) {
        &__image {
            display: none;

            &--mobile {
                display: block;
            }
        }
    }
}
