.homepage-content {
    $spacing: 1em;
    @include container;

    .content-aside {
        margin-top: 2em;
        @include span(2.25 of 8);

        @include bp-max($bp-desktop) {
            @include span(8 of 8);
        }
    }

    .content-main {
        @include span(5.75 of 8 last);

        @include bp-max($bp-desktop) {
            @include span(8 of 8);
        }
    }

    .content-main--full {
        @include span(8 of 8 last);
    }
    /*.personalization-cta .tool-tip-icon{display:none;}*/
    .personalization-bar-home,
    .personalization-bar-mobile,
    .aside-optional-content,
    .ad-placeholder {
        margin-top: $spacing
    }

    .personalization-bar-home {
        .aside-header {
        }
    }

    .personalization-bar-home, .personalization-bar-mobile {
        .aside-header {
            @include bp-max($bp-desktop) {
                display: none;
            }
        }

        .keyword-filter {
            .filter-label {
                @include bp-max($bp-desktop) {
                    display: none;
                }
            }
        }
    }

    .personalization-bar-home {
        @include bp-max($bp-mobile) {
        }
    }

    .personalization-bar-mobile {
        @include bp-min($bp-tablet) {
            display: none;
        }
    }

    .aside-optional-content, .ad-placeholder {
        @include bp-max($bp-desktop) {
            display: none;
        }
    }

    @include bp-max(990px) {
        .main-container {
            width: 100%;
            display: block;
        }
    }
}
