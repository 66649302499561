.typeahead {
    display: flex;
    position: relative;
    z-index: 3;

    & &__search-input {
        height: 100%;
    }

    &__submit {
        background-color: $colors__blue;
        color: $colors__white;
        height: 37px;
        padding: 8px 10px;

        &:focus {
            outline: thin dotted $colors__blue;
        }
    }

    &__search-icon {
        width: px-to-em(20px);
        height: px-to-em(20px);
    }

    &__options-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: px-to-em(388px);
        overflow-y: auto;
        background-color: $colors__white;
    }

    &__option {
        @include fonts__sans-serif-medium;
        cursor: pointer;
        font-size: 14px;
        line-height: 1.2;
        color: $colors__blue;
        border: 1px solid $colors__gray--c5;
        border-top: 0 none;
        margin-bottom: 0;
        padding: 0.75em 0.6em;
        
        &--is-active {
            background-color: $colors__gray--d8;
            // color: $color__black-text;
        }    
    }
    

    @include media('>=phone') {
        &--search-result {
            max-width: 200px;
            margin-bottom: 15px;
            // margin-left: 0;
            // margin-right: 0;
    
            & + & {
                margin-top: 0;
            }
        }
    }
}
