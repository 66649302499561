.general-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,.4);

    > div {
        height: 100%;
        padding: 2em 1em;
    }

    &__content {
        background-color: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        padding: 3em;
        text-align: left;
    }

    &__container {
        max-width: 700px;
        border-radius: 5px;
        margin: auto;
        padding: 1.5em 2.25em 1.5em 1.5em;
    }

    &__description p, ol, ul {
        color: #2d3c59;
    }

    &__close {
        height: 30px;
        width: 30px;
        display: block;
        position: absolute;
        right: 0;
        top: 1.5em;
        border: 2px solid #FFFFFF;
        border-radius: 100%;
        text-align: center;

        svg {
            position: relative;
            height: 50%;
            width: 50%;
            color: #FFFFFF;
        }
    }

    @include bp-max($bp-mobile) {
        &__content {
            padding: 2em;
        }
    }
}

.override-swiper-wrapper {
    transform: none !important;
    z-index: auto !important;
}

.override-swiper-container {
    position: static !important;
}

.section-image {
    display: flex;
    align-items: center;
    margin-top: 3em;

    &__img {
        flex: 0 0 auto;
        width: 33.33%;
    }
}

.section-text {
    padding-left: 2em;
    width: 66.66%;

    & p {
        font-size: 16px;
        font-weight: bold;
        font-family: 'MuseoSans';
    }
}

.cta-container {
    border-radius: 20px;
    font-size: 16px;
    background-color: #EAEFA2;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cta-text {
    padding-left: 20px;
    width: 70%;
}

@media only screen and (max-width: 767px) {
    .section-image {
        flex-direction: column;
    }

    .section-text {
        width: 100%;
        padding: 0px;
    }

    .section-image__img {
        width: 100%;
        max-width: 162px;
    }

    .cta-container {
        flex-direction: column;
    }

    .cta-text {
        padding: 0 0 10px 0;
        width: 100%;
        text-align: center;
    }
}

.cta-button {
    border: 2px solid;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

.general-modal.is-open {
    display: block;
}

[data-micromodal-trigger] {
    cursor: pointer;
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .general-modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .general-modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .general-modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .general-modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .general-modal__container,
.micromodal-slide .general-modal__overlay {
    will-change: transform;
}
