.triple-content {
    $triple-content__content-spacing-sm: 16px;
    $triple-content__content-spacing-lg: 30px;
    $triple-content__content-padding-lg: 48px 24px;
    $triple-content__content-padding-sm: 24px;
    $triple-content__content-width-lg: calc(50% - #{$triple-content__content-spacing-lg});

    @include spacing__component-y-margin;

    &__header {
        text-align: center;
        margin-bottom: 40px;
    }

    &__content {
        padding: $triple-content__content-padding-sm;
        background-color: $colors__teal--lightest;
        margin-top: $triple-content__content-spacing-sm;
    }

    &__container {
        margin-top: calc(#{$triple-content__content-spacing-sm} * -1); //negate margin from top of cards

        .two-column-layout & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__title {
        @include fonts__sans-serif-bold;
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
    }

    &__description {
        @include fonts__sans-serif-light;
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
        margin-top: 20px;
    }

    @include media('>=tablet-portrait') {
        &__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: calc(#{$triple-content__content-spacing-lg} * -1);
        }

        &__content {
            width: $triple-content__content-width-lg;
        }
    }

    @include media('>=tablet-landscape') {

        &__3-col {
            .triple-content__content {
                width: calc(33.33% - #{$triple-content__content-spacing-lg} * 2 / 3);                     
            }
        }

        &__4-col {
            .triple-content__content {
                width: calc((25% - #{$triple-content__content-spacing-lg}));                   
            }
        }

    }
}
