.careers-industries-services {
    // background-color: $color-violet-strikemaster;
    background-color: $color-dark-blue-footer;
    padding: 3em 0;
    margin-top: -8px;

    .section-header {
        margin: 0 0 .25em 0;
        font-size: 1em;
    }

    .header-style {
        @include font-primary-medium-bold;
        font-size: 45px;
        color: $color-white;
        line-height: 1;

        &:nth-of-type(2) {
            margin: 0 .2em;
        }

        @include bp-min($bp-ipad) {
            font-size: 5.25em;
        }
    }

    .industries-services-lists {
        @include container;
        margin-bottom: 2em;
    }

    .industries-services-group {
        @include span(4);

        &:last-of-type {
            @include span(4 last);
        }

        .industries-services-name {
            color: $color-white;
            list-style: none;
        }
    }

    .foot-note {
        font-style: italic;
        margin-top: 1em;
        color: $color-white;
    }

    .button {
        border: 2px solid $color-white;
    }

    @include bp-max($bp-ipad) {
        .industries-services-group {
            @include span(100%);
            margin-top: 2em;

            &:last-of-type {
                float: left;
            }
        }
    }

    // @include bp-max($bp-tablet) {
    //     .header-style {
    //         font-size: px-to-em(70px);

    //         &:nth-of-type(2) {
    //             font-size: px-to-em(90px);
    //         }
    //     }
    // }
}
