/*
  <a href="" class="tooltip">link name <span>tooltip</span></a>
*/
.tooltip {
    position: relative;
    display: inline;

    span {
        position: absolute;
        height: 25px;
        width: 200%;
        line-height: 25px;
        visibility: hidden;
        border-radius: 5px;
        color: $color-white;
        background: $color-brown-spice;
        text-align: center;
        box-shadow: 3px 1px 3px $color-gray-light-grey;
        font-size: .55em !important;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -8px;
            width: 0;
            height: 0;
            border-right: 8px solid $color-brown-spice;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
}

a:hover.tooltip span {
    visibility: visible;
    opacity: 0.8;
    left: 100%;
    top: 50%;
    margin-top: -15px;
    margin-left: 15px;
    z-index: 999;
}
