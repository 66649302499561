.help-landing {
    &__container {
        background-color: $colors__gray--f7;
        padding: $spacing__gutter-width * 2 0;
        padding: 0 1em;
        margin-left: auto;
        margin-right: auto;
    }

    &__content {
        max-width: 65em;
    }

    &__detail {
        width: 70.96781%;
        float: right;
        margin-right: 0;
        padding-top: 2em;
    }

    &__text {
        width: 300px;
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 15px;
        margin-bottom: 0px
    }

    &__smalltext {
        color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: MuseoSans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 15px;
        margin-bottom: 0px;
        padding-right: 15px;
    }

    &__padding {
        padding: 0px 0px 50px 42%;
    }

    &__mobile-center {
    }

    &__security-Text {
        max-width: 918px;
        color: #FFF;
        font-family: MuseoSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        bottom: 0 !important;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        text-align: center;
        position: absolute;
    }

    &__position-relative {
        position: relative !important;       
    }
}

@include media('<tablet-middle') {
    .help-landing {
        &__padding {
            padding-left: 9%;
        }

        &__detail {
            padding-top: 0em;
            padding-left: 45%
        }        
    }

    &__mobile-center {
        display: grid;
    }
}

@include media('<tablet-portrait') {
    .help-landing {
        &__padding {
            padding: 0px;
        }

        &__detail {
            padding-top: 0em;
            padding-left: 0px;
        }

        &__mobile-center {
            display: grid;
        }

        &__position-relative {
            position: relative !important;
            padding-top: 250px;
            max-width: 380px;
        }

    }
}

@include media('>=tablet-landscape','<desktop') {
    .help-landing {
        &__padding {
            padding: 0 0 20px 38%;
        }

        &__content {
            padding-top: 20px;
        }
    }
}


