.landing-page-content-block {
  margin-top: 0;

  .featured-content__content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 55px 0 95px;

    .section-body {
      margin-top: 65px;
    }
  }

  .featured-media__content {
    width: 42%;
  }
}

@include media('<=tablet-portrait') {

  .landing-page-content-block {

    .featured-content__content {
      padding: 36px 0 24px;

      .section-name {
        margin-bottom: 25px;
      }

      .section-body {
        margin-top: 42px;
        font-size: 20px;
        line-height: 1.4;

        .row {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          margin-bottom: 24px;
        }
      }
    }
  }
}
