.icon-links-list {
    &__container {
        list-style-type: none;
        margin: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        padding-left: 43px;
        margin: 24px 0;
    }

    &__icon {
        flex: 0 0 auto;
        margin-right: 19px;
        max-width: 30px;
    }

    &__link {
        @include fonts__sans-serif-bold;
        font-size: 16px;
        line-height: 1.8;
        color: $colors__blue;
    }

    &__desc {
        margin-bottom: 14px;
    }
}