.component-opportunities {
    margin: 0 0 2em;

    h2 {
        text-align: center;
        color: $color-brown-cocao-brown;
        @include font-primary-light;
        font-size: 2.25em;
        margin: 0 0 .5em;
    }

    > .items {
        height: 265px;

        > div {
            @include span(4);
            height: 265px;
            padding: 15px;

            &:last-child {
                @include span(4 last);
            }

            background: none no-repeat center center;
            background-size: cover;

            > .wrapper {
                display: table;
                width: 100%;
                height: 100%;
                padding: 10px;
                overflow: hidden;
                transition: all .5s ease-in;

                > div {
                    display: table-cell;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    text-align: center;

                    h4 {
                        font-family: $font-family-primary;
                        font-size: 1.5em;
                        color: $color-white;
                        transition: all .5s ease-in;
                    }

                    p {
                        font-size: 1.1em;
                        margin: 0;
                        max-height: 0;
                        visibility: hidden;
                        transition: all .5s ease-in;
                    }
                }
            }

            &:hover > div {
                background: rgba(255,255,255, .8);

                > div {
                    h4, p {
                        color: #222;
                    }

                    p {
                        max-height: 200px;
                        visibility: visible;
                    }
                }
            }
        }
    }
    // Responsive
    // Desktop
    @include bp-max($bp-desktop) {
        > .items {
            > div {
                > .wrapper {
                    > div {
                        h4 {
                            font-size: 1.3em;
                        }

                        p {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }
    //  iPad
    @include bp-max($bp-ipad) {
        > .items {
            height: auto;

            > div {
                width: 100% !important;
                height: auto;
                float: none !important;
                margin: 0;

                > .wrapper {
                    > div {
                        h4 {
                            margin: 0;
                            font-size: 1.5em;
                        }
                    }
                }

                & + div {
                    margin-top: 1em;
                }

                &:hover > .wrapper {
                    background: none;

                    > div {
                        h4 {
                            color: $color-white;
                        }

                        p {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    //  Tablet
    @include bp-max($bp-tablet) {
        h2 {
            font-size: 1.6em;
        }

        > .items {
            > div {
                > .wrapper {
                    > div {
                        h4 {
                            font-size: 1.25em;
                        }
                    }
                }
            }
        }
    }
    // Mobile
    @include bp-max($bp-mobile) {
        h2 {
            font-size: 1.3em;
        }

        > .items {
            > div {
                > .wrapper {
                    > div {
                        h4 {
                            font-size: 1.1em;
                        }
                    }
                }
            }
        }
    }
}
