$component-header__margin-top: 40px;
$component-header__margin-top--desktop: 48px;
$component-header__margin-bottom: 40px;

@mixin component-header__styles () {
    --component-header__font-size: 22px;
    --component-header__margin-top: #{$component-header__margin-top};
    --component-header__margin-bottom: #{$component-header__margin-bottom};

    @include fonts__sans-serif-bold;
    color: $colors__white;
    line-height: 1.2;
    font-size: var(--component-header__font-size);
    background-color: $colors__blue;
    padding: 16px 24px;
    margin-bottom: var(--component-header__margin-bottom);
    letter-spacing: 0; // override legacy styles
    margin-top: var(--component-header__margin-top);

    @include media('>=tablet-portrait') {
        --component-header__font-size: 26px;
    }

    @include media('>=tablet-portrait') {
        --component-header__font-size: 28px;
        --component-header__margin-top: #{$component-header__margin-top--desktop};
    }
}

.component-header {
    @include component-header__styles();

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__cta {
        padding-right: 24px;
        text-align: right;
    }

    &__container {
        --component-header__margin-top: #{$component-header__margin-top};
        --component-header__margin-bottom: #{$component-header__margin-bottom};

        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $colors__blue;
        margin-top: var(--component-header__margin-top);
        margin-bottom: var(--component-header__margin-bottom);

        .component-header {
            margin-top: 0;
            margin-bottom: 0;
        }

        @include media('>=tablet-portrait') {
            --component-header__margin-top: #{$component-header__margin-top--desktop};
        }
    }

    &__simple {
        @include component-header__styles();

        --component-header__font-size: 18px;
        @include media('>=tablet-portrait') {
            --component-header__font-size: 22px;
        }
        
        text-align: center; 
        color: $colors__blue;        
        background-color: transparent;
    }
}
