.cla-promise {
    margin-bottom: 2em;

    h2 {
        text-align: center;
        color: $color-brown-cocao-brown;
        @include font-primary-light;
        font-size: 2.25em;
        margin: 0 0 .5em;
    }

    p {
        font-size: 1.75em;
        margin: 0;
        color: $color-white !important;
    }

    a {
        background-color: $color-blue-segul;
        text-align: center;
    }

    @include bp-min(576px) {

        .promise-container {
            @include container;

            > div {
                position: relative;
                height: 135px;
                text-align: center;
                padding: 15px 5px 15px 25px;

                &:first-child {
                    padding: 15px 5px 15px 15px;
                }

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-top: 67.5px solid transparent;
                    border-bottom: 67.5px solid transparent;
                }

                > div {
                    display: table;
                    width: 100%;
                    height: 100%;

                    > div {
                        display: table-cell;
                        height: 100%;
                        vertical-align: middle;
                    }
                }
            }

            .listen {
                @include span(3);
                background-color: $color-green-pixie-green;

                &:before {
                    right: -20px;
                    border-left: 20px solid $color-green-pixie-green;
                }

                &:after {
                    display: none;
                }
            }

            .build {
                @include span(3);
                background-color: $color-green-olivine;

                &:after {
                    left: 0;
                    border-left: 20px solid $color-white;
                }

                &:before {
                    right: -20px;
                    border-left: 20px solid $color-green-olivine;
                    ;
                }
            }

            .bring {
                @include span(4);
                background-color: $color-green-olive;

                &:after {
                    left: 0;
                    border-left: 20px solid $color-white;
                }

                &:before {
                    right: -20px;
                    border-left: 20px solid $color-green-olive;
                }
            }

            .start {
                @include span(2 last);
                background-color: $color-blue-shakespear;
                color: $color-white;
                font-weight: bold;
                text-align: center;
                transition: background-color $transition-timing-primary ease-out;

                &:before {
                    display: none;
                }

                &:after {
                    left: 0;
                    border-left: 20px solid $color-white;
                }

                .start-container {
                    background-color: $color-blue-air-force-blue;
                    display: block;
                    margin: 0 auto;
                    max-width: 100px;
                    transition: background-color $transition-timing-primary ease-out;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                .fa {
                    font-weight: normal !important;
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-blue-prusian-blue;
                    /*background-color:$color-bluelagoon-green;*/
                    text-decoration: none;

                    .start-container {
                        background-color: $color-blue-shakespear;
                    }
                }
            }
        }
    }

    @include bp-max(575px) {
        h2 {
            font-size: 1.6em;
            margin: 0 0 1em;
        }

        a {
            text-align: center;
        }

        .promise-container {
            margin: 0 1em 0 1em;

            > div {
                position: relative;
                text-align: center;
                padding: 6px 12px 0;
                margin: 6px auto 1em auto;
                height: 100px;
                width: 100%;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 100%;
                }

                &:before {
                    transform: skew(0deg, 6deg);
                }

                &:after {
                    transform: skew(0deg, -6deg);
                }

                > div { // ideally this shouldn't be repeated
                    display: table;
                    width: 100%;
                    height: 100%;

                    > div {
                        display: table-cell;
                        height: 100%;
                        vertical-align: middle;
                    }
                }
            }

            div p {
                z-index: 100;
                position: relative;
                margin: 0;
                font-size: 1.5em;
            }

            .listen {
                margin-top: 1em;

                &:before {
                    left: 0;
                    width: 51%;
                    background: $color-green-pixie-green;
                }

                &:after {
                    right: 0;
                    width: 50%;
                    background: $color-green-pixie-green;
                }
            }

            .build {

                &:before {
                    left: 0;
                    width: 51%;
                    background: $color-green-olivine;
                }

                &:after {
                    right: 0;
                    width: 50%;
                    background: $color-green-olivine;
                }
            }

            .bring {

                &:before {
                    left: 0;
                    width: 51%;
                    background: $color-green-olive;
                }

                &:after {
                    right: 0;
                    width: 50%;
                    background: $color-green-olive;
                }
            }

            .start {
                color: $color-white;
                font-weight: bold;
                transition: background-color $transition-timing-primary ease-out;
                margin-bottom: 2em !important;

                div {
                    z-index: 100;
                    position: relative;
                    font-size: 1.1em;
                }

                &:before {
                    left: 0;
                    width: 51%;
                    background: $color-blue-shakespear;
                    transition: background-color $transition-timing-primary ease-out;
                }

                &:after {
                    right: 0;
                    width: 50%;
                    background: $color-blue-shakespear;
                    transition: background-color $transition-timing-primary ease-out;
                }

                .start-container {
                    background-color: $color-blue-air-force-blue;
                    padding: .25em;
                    transition: background-color $transition-timing-primary ease-out;
                    margin-left: .25em;

                    &:before { // ideally not duplicated here
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                .fa {
                    font-weight: normal !important;
                    color: $color-white;
                }

                &:hover {
                    text-decoration: none;

                    .start-container {
                        background-color: $color-blue-shakespear;
                    }

                    &:before {
                        left: 0;
                        width: 51%;
                        background-color: $color-blue-prusian-blue;
                        transform: skew(0deg, 6deg);
                        transition: background-color $transition-timing-primary ease-out;
                    }

                    &:after {
                        right: 0;
                        width: 50%;
                        background-color: $color-blue-prusian-blue;
                        transform: skew(0deg, -6deg);
                        transition: background-color $transition-timing-primary ease-out;
                    }
                }
            }
        }
    }

    @include bp-max($bp-mobile) {
        h2 {
            font-size: 1.3em;
        }
    }
}
