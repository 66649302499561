.page-hero {
    --page-hero__image-min-height: 250px;
    --page-hero__title-size: 32px;
    --page-hero__width-padding-top: 24px;
    --page-hero__width-padding-bottom: 24px;
    --page-hero__text-margin-top: 16px;
    --page-hero__text-size: 18px;
    --page-hero__color: #{$colors__blue};
    --page-hero__overlay-padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--followed-by-service-tiles {
        --page-hero__width-padding-bottom: 54px;

        + .service-tiles {
            margin-top: -26px;
        }
    }

    &--followed-by-navigation-tiles {
        --page-hero__width-padding-bottom: 54px;

        + .navigation-tiles {
            margin-top: -26px;
        }
    }

    &--theme-home {
        --page-hero__image-min-height: 380px;
        --page-hero__color: #{$colors__white};
        --page-hero__body-background-color: #{$colors__teal};
        --page-hero__content-background-color: #{$colors__teal--dark};
        --page-hero__content-blend-mode: multiply;
        --page-hero__text-size: 20px;
        position: relative;

        &:before {
            content: '';
            display: block;
            min-height: var(--page-hero__image-min-height);
        }

        .page-hero__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 0;
        }

        .page-hero__body {
            position: absolute;
        }

        .page-hero__videobody {
            position: absolute;
        }

        .page-hero__imagevideobody {
            position: absolute;
        }

        .call-to-action {
            --call-to-action__color: #{$colors__teal--dark};
            --call-to-action__bg-color: #{$colors__white};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__teal--dark};
            --call-to-action__hover-color: #{$colors__white};
        }
    }


    &--theme-teal {
        --page-hero__body-background-color: #{$colors__teal};
        --page-hero__content-background-color: #{$colors__teal};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-green {
        --page-hero__body-background-color: #{$colors__green};
        --page-hero__content-background-color: #{$colors__green};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-blue {
        --page-hero__color: #{$colors__white};
        --page-hero__body-background-color: #{$colors__blue};
        --page-hero__content-background-color: #{$colors__blue};

        .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{rgba($colors__blue, 0)};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__blue};
            --call-to-action__bg-color: #{$colors__white};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__blue};
            --call-to-action__hover-color: #{$colors__white};
        }
    }

    &--theme-celadon {
        --page-hero__body-background-color: #{$colors__celadon};
        --page-hero__content-background-color: #{$colors__celadon};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-navy {
        --page-hero__color: #{$colors__white};
        --page-hero__body-background-color: #{$colors__navy};
        --page-hero__content-background-color: #{$colors__navy};

        .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{rgba($colors__navy, 0)};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__navy};
        }

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__navy};
            --call-to-action__bg-color: #{$colors__white};
            --call-to-action__border-color: #{$colors__white};
            --call-to-action__hover-bg-color: #{$colors__navy};
            --call-to-action__hover-color: #{$colors__white};
        }
    }

    &--theme-riptide {
        --page-hero__body-background-color: #{$colors__riptide};
        --page-hero__content-background-color: #{$colors__riptide};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-saffron {
        --page-hero__body-background-color: #{$colors__saffron};
        --page-hero__content-background-color: #{$colors__saffron};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-orange {
        --page-hero__body-background-color: #{$colors__saffron};
        --page-hero__content-background-color: #{$colors__saffron};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-white {
        --page-hero__body-background-color: #{$colors__white};
        --page-hero__content-background-color: #{$colors__white};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-cloud {
        --page-hero__body-background-color: #{$colors__cloud};
        --page-hero__content-background-color: #{$colors__cloud};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-riptidetint {
        --page-hero__body-background-color: #{$colors__riptidetint};
        --page-hero__content-background-color: #{$colors__riptidetint};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-celadontint {
        --page-hero__body-background-color: #{$colors__celadontint};
        --page-hero__content-background-color: #{$colors__celadontint};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &--theme-saffrontint {
        --page-hero__body-background-color: #{$colors__saffrontint};
        --page-hero__content-background-color: #{$colors__saffrontint};

        &:not(.page-hero--background-only) .call-to-action {
            --call-to-action__color: #{$colors__white};
            --call-to-action__bg-color: #{$colors__blue};
            --call-to-action__border-color: #{$colors__blue};
            --call-to-action__hover-bg-color: #{$colors__white};
            --call-to-action__hover-color: #{$colors__blue};
        }
    }

    &__video {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media('>tablet-portrait') {
            // When there is a video, reduce spacing to allow more of the video to show
            ~ .page-hero__body {
                --page-hero__container-padding-reduction-amount: 0.4;
                --page-hero__container-padding-remaining-amount: 0.6; // 1 - the reduction amount;
                --page-hero__container-padding-vertical: 26px;
                --page-hero__video-space-margin: calc(var(--page-hero__overlay-padding) + var(--page-hero__width-padding-top) + var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-reduction-amount) - var(--page-hero__container-padding-vertical) * 2);
                margin-top: var(--page-hero__video-space-margin);
                padding-top: 0;

                .page-hero__width-container {
                    padding-top: var(--page-hero__container-padding-vertical);
                    padding-bottom: 0;

                    .page-hero--followed-by-service-tiles & {
                        padding-bottom: calc(var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-remaining-amount) + var(--page-hero__container-padding-vertical));
                    }
                }
            }

            ~ .page-hero__videobody {
                --page-hero__container-padding-reduction-amount: 0.4;
                --page-hero__container-padding-remaining-amount: 0.6; // 1 - the reduction amount;
                --page-hero__container-padding-vertical: 26px;
                --page-hero__video-space-margin: calc(var(--page-hero__overlay-padding) + var(--page-hero__width-padding-top) + var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-reduction-amount) - var(--page-hero__container-padding-vertical) * 2);
                margin-top: var(--page-hero__video-space-margin);
                padding-top: 0;

                .page-hero__width-container {
                    padding-top: var(--page-hero__container-padding-vertical);
                    padding-bottom: 0;

                    .page-hero--followed-by-service-tiles & {
                        padding-bottom: calc(var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-remaining-amount) + var(--page-hero__container-padding-vertical));
                    }
                }
            }

            ~ .page-hero__imagevideobody {
                --page-hero__container-padding-reduction-amount: 0.4;
                --page-hero__container-padding-remaining-amount: 0.6; // 1 - the reduction amount;
                --page-hero__container-padding-vertical: 26px;
                --page-hero__video-space-margin: calc(var(--page-hero__overlay-padding) + var(--page-hero__width-padding-top) + var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-reduction-amount) - var(--page-hero__container-padding-vertical) * 2);
                margin-top: var(--page-hero__video-space-margin);
                padding-top: 0;

                .page-hero__width-container {
                    padding-top: var(--page-hero__container-padding-vertical);
                    padding-bottom: 0;

                    .page-hero--followed-by-service-tiles & {
                        padding-bottom: calc(var(--page-hero__width-padding-bottom) * var(--page-hero__container-padding-remaining-amount) + var(--page-hero__container-padding-vertical));
                    }
                }
            }
        }

        @include media('<tablet-portrait') {
            display: none;
        }
    }


    &__image {
        flex-grow: 1;
        background-position: 50% 0;
        background-size: cover;
        min-height: var(--page-hero__image-min-height);

        @mixin page-hero__body-offset {
            margin-top: calc(var(--page-hero__overlay-padding) * -1);

            &:before {
                opacity: 1;
                mix-blend-mode: multiply;
            }
        }

        + .page-hero__body {
            @include page-hero__body-offset();
        }

        &--mobile {
            flex-grow: 1;
            background-position: center center;
            background-size: cover;
            min-height: var(--page-hero__image-min-height);

            .page-hero--theme-home & {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                min-height: 0;
            }

            @include media('<tablet-portrait') {
                + .page-hero__body {
                    @include page-hero__body-offset();
                }
            }

            @include media('>=tablet-portrait') {
                display: none;
            }

            @media print {
                display: none;
            }
        }

        &--desktop {
            display: none;

            &-entry {
                transition: opacity 2s;
            }

            &-exit {
                transition: opacity 1s;
            }

            &-hide {
                opacity: 0;

                @include media('<tablet-portrait') {
                    display: block;
                }
            }

            @include media('>=tablet-portrait') {
                display: block;
            }

            @media print {
                display: none;
            }
        }

        @media print {
            display: none;
        }
    }

    &__body {
        width: 100%;
        position: relative;
        padding-top: var(--page-hero__overlay-padding);
        margin-top: calc(var(--page-hero__overlay-padding)*-1);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--page-hero__body-background-color);
            opacity: 1;
            mix-blend-mode: multiply;
        }
    }

    &__videobody {
        width: 100%;
        position: relative;
        padding-top: var(--page-hero__overlay-padding);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--page-hero__body-background-color);
            opacity: 0.5;
        }
    }

    &__imagevideobody {
        width: 100%;
        position: relative;
        padding-top: var(--page-hero__overlay-padding);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--page-hero__body-background-color);
            opacity: 0.5;

            @include media('<tablet-portrait') {
                opacity: 0.5;
                mix-blend-mode: multiply;
            }
        }
    }


    &__content {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--page-hero__content-background-color);
            mix-blend-mode: var(--page-hero__content-blend-mode, normal);
        }
    }

    &__width-container {
        padding-top: var(--page-hero__width-padding-top);
        padding-bottom: var(--page-hero__width-padding-bottom);
    }

    &__title {
        @include fonts__serif-regular;
        color: var(--page-hero__color);
        font-size: var(--page-hero__title-size);
        text-align: center;
        line-height: 1.13;
        position: relative;
    }

    &__text {
        @include fonts__sans-serif-light;
        color: var(--page-hero__color);
        font-size: var(--page-hero__text-size);
        text-align: center;
        line-height: 1.18;
        max-width: 920px;
        margin: var(--page-hero__text-margin-top) auto 0;
        position: relative;
    }

    &__brand {
        @include fonts__sans-serif-bold;
        color: var(--page-hero__color);
        font-size: var(--page-hero__text-size);
        text-align: center;
        line-height: 1.18;
        max-width: 920px;
        margin: 0 auto var(--page-hero__text-margin-top);
        position: relative;
    }

    &__buttons {
        text-align: center;
        max-width: 920px;
        margin: var(--page-hero__text-margin-top) auto 0;
        position: relative;
    }

    @include media('>=tablet-portrait') {
        --page-hero__title-size: 42px;
        --page-hero__text-size: 22px;
        --page-hero__width-padding-top: 32px;
        --page-hero__width-padding-bottom: 32px;
        --page-hero__image-min-height: 300px;

        &--followed-by-service-tiles {
            --page-hero__width-padding-bottom: 64px;

            + .service-tiles {
                margin-top: -36px;
            }
        }

        &--followed-by-navigation-tiles {
            --page-hero__width-padding-bottom: 64px;

            + .navigation-tiles {
                margin-top: -36px;
            }
        }

        &--theme-home {
            --page-hero__image-min-height: #{480/1440*100}vw; // Fallback
            --page-hero__image-min-height: max(#{480/1440*100}vw, 380px);
            --page-hero__text-size: 24px;
            --page-hero__width-padding-bottom: 64px;
        }
    }

    @include media('>=tablet-landscape') {
        --page-hero__title-size: 52px;
        --page-hero__text-size: 24px;
        --page-hero__width-padding-top: 40px;
        --page-hero__width-padding-bottom: 40px;

        &--followed-by-service-tiles {
            --page-hero__width-padding-bottom: 88px;

            + .service-tiles {
                margin-top: -48px;
            }
        }

        &--followed-by-navigation-tiles {
            --page-hero__width-padding-bottom: 138px;

            + .navigation-tiles {
                margin-top: -98px;
            }
        }

        &--theme-home {
            --page-hero__text-size: 28px;
        }
    }
}
