﻿.event-landing {
    .upcoming, .recent {
        &.ng-hide {
            &.loading {
                display: block !important;
            }
        }

        margin-bottom: 2em;

        h2 {
            margin-bottom: 0.5em;
        }
    }

    .event-results {
        opacity: 1;
        transition: opacity .25s ease-in-out;

        &.loading {
            opacity: 0;
        }
    }

    .fa-check-circle {
        font-size: 1.25em;
        color: $color-blue-shakespear;
    }

    .twelve {
        margin-bottom: 0em;

        th {
            text-transform: none;
        }

        .tbody {
            margin-bottom: 0em;
        }
    }
}

.homepage-content {

    .event-detail {
        .content-well {
            width: 91.48938%;
            float: left;
            min-height: 340px;
        }
    }

    .content-main {
        .content-well {


            .twelve {
                margin-bottom: 0em;

                th {
                    text-transform: none;
                }

                .tbody {
                    margin-bottom: 0em;
                }
            }

            .upcoming, .recent {
                &.ng-hide {
                    &.loading {
                        display: block !important;
                    }
                }

                margin-bottom: 2em;

                h2 {
                    margin-bottom: 0.5em;
                }
            }

            .personalization-cta {
                background-color: $color-whitesand-grey;
                margin-bottom: 1em;

                .content {
                    margin: 0 1em;
                    display: inline-block;
                    width: auto;
                    vertical-align: text-bottom;
                }

                .tool-tip-icon {
                    font-size: 1.25em;
                    margin-top: .25em;
                }

                .fa-question-circle:before {
                    color: $color-blue-air-force-blue;
                }

                em.tool-tip-icon, #personalization-message {
                    display: none;
                }


                .personalization-cta-button {
                    display: inline-block;
                    width: 1.25em;
                    height: 1.25em;
                    border-radius: 1.875em;
                    background-color: $color-blue-air-force-blue;
                    z-index: 1;
                    margin: 5px 0;
                    vertical-align: bottom;
                    transition: background-color 0.3s;

                    &:before {
                        @include font-fontawesome($fa-var-question);
                        vertical-align: text-bottom;
                        top: -20%;
                        margin-left: 3px;
                        position: relative;
                        color: $color-white;
                        display: inline-block;
                        padding-top: 0.1em;
                        padding-left: 0.1em;
                        //font-size: 1.25em;
                    }

                    .popout {
                        &.normalpopout {
                            display: none;
                        }

                        &.showpopout {
                            display: inline-block;
                            position: relative;
                            left: 160%;
                            top: -175%;
                            z-index: 1;
                            background: white;
                            vertical-align: top;
                            width: 15em;
                            box-shadow: 2px 2px 10px #000;

                            .popout-content {
                                font-size: 90%;
                                padding: 10px;
                                display: inline-block;
                                width: 80%;
                            }

                            .cta-popout-close {
                                background-color: $color-white !important;
                                color: $color-brown-sand-dune !important;
                                float: right;
                                margin-top: 4px;
                                display: inline-block;
                                padding: 0.313em 0.75em;

                                &:before {
                                    @include font-ionicons($ionicon-var-close-round);
                                    float: right;
                                }
                            }

                            &:after {
                                width: 0px !important;
                                display: none !important;
                                background-color: transparent !important;
                                content: "" !important;
                            }
                        }
                    }

                    &:hover {
                        .normalpopout {
                            display: inline-block;
                            position: relative;
                            left: 160%;
                            top: -175%;
                            z-index: 1;
                            background: white;
                            vertical-align: top;
                            width: 15em;
                            box-shadow: 2px 2px 10px #000;

                            .popout-content {
                                font-size: 90%;
                                padding: 10px;
                                display: inline-block;
                                width: 80%
                            }

                            .cta-popout-close {
                                background-color: $color-white !important;
                                color: $color-brown-sand-dune !important;
                                float: right;
                                margin-top: 4px;
                                display: inline-block;
                                padding: 0.313em 0.75em;

                                &:before {
                                    @include font-ionicons($ionicon-var-close-round);
                                    float: right;
                                }
                            }

                            &:after {
                                width: 0px !important;
                                display: none !important;
                                background-color: transparent !important;
                                content: "" !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.event-role::after {
    content: ", ";
}

.event-role:last-of-type:after {
    content: "" !important;
}

@include bp-max($bp-mobile) {
    .personalization-cta, .personalization-cta-button {
        display: none !important;
    }
}
