.careers-benefits {
    // background-color: $color-green-olive;
    background-color: $color-green-celedon;
    color: $color-white;
    padding: 3em 0;
    @include clearfix;

    .section-intro {
        @include container;
    }

    .section-header {
        font-size: 1em;
        color: $color-white;
        line-height: 1;
        width: 100%;
        margin-bottom: 1em;

        @include bp-min($bp-ipad) {
            @include span(5.25);
        }
    }

    .header-style {
        @include font-primary-serif;
        color: $color-dark-blue-footer;
        font-weight: normal;

        &:first-of-type {
            font-size: 45px;
            margin-right: 0;
        }
        
        &:nth-of-type(2) {
            font-size: 3.25em;
        }
        
        @include bp-min($bp-ipad) {
            &:first-of-type {
                font-size: 3.25em;
                margin-right: 1em;
            }
        }

    }

    .section-copy {
        @include span(6.75 last);

        p {
            // color: $color-white;
            color: $color-dark-blue-footer;
            margin: 0;
            /*margin-top: px-to-em(35px);*/
            ~ p {
                margin-top: .5em;
            }
        }
    }

    .benefits {
        @include container;
    }

    .benefits-group {
        list-style-type: disc;
        margin-top: 3em;
        @include span(3);

        &:last-of-type {
            @include span(3 last);
        }

        .benefits-item {
            // color: $color-white;
            color: $color-dark-blue-footer;
        }
    }

    .button,
    .call-to-action,
    .call-action--outline,
    .call-action--outline-white {
        float: right;
        margin-top: 2em;
    }

    @include bp-max($bp-ipad) {
        .section-copy {
            @include span(100%);
        }

        .benefits-group {
            margin: 1em;
            @include span(100%);

            &:last-of-type {
                @include span(100%);
            }
        }
    }
}
