.resource-header {
    position: relative;

    @include media('>=tablet-portrait') {
        padding: 40px 0;
        display: flex;
        align-items: center;
        min-height: 370px;
    }

    &__background {
        background-size: cover;
        background-position: center 16%;
        background-repeat: no-repeat;
        position: relative;
        height: 200px;
        display: block;

        &--mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        @include media('>=tablet-portrait') {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &--mobile {
                display: none;
            }
        }
    }

    &__content {
        background-color: $colors__blue;
        width: 100%;
        padding: 20px 35px;
        position: relative;
        z-index: 1;
        color: $colors__white;
        // padding-left: calc((100vw - 1100px)/2);
        &--theme-teal {
            background-color: $colors__teal;
            color: $colors__blue;
        }

        &--theme-green {
            background-color: $colors__green;
            color: $colors__blue;
        }

        &--theme-celadon {
            background-color: $colors__celadon;
            color: $colors__blue;
        }

        &--theme-navy {
            background-color: $colors__navy;
            color: $colors__white;
        }

        &--theme-riptide {
            background-color: $colors__riptide;
            color: $colors__blue;
        }

        &--theme-saffron {
            background-color: $colors__saffron;
            color: $colors__blue;
        }

        &--theme-orange {
            background-color: $colors__saffron;
            color: $colors__blue;
        }

        &--theme-white {
            background-color: $colors__white;
            color: $colors__blue;
        }

        &--theme-cloud {
            background-color: $colors__cloud;
            color: $colors__blue;
        }

        &--theme-riptidetint {
            background-color: $colors__riptidetint;
            color: $colors__blue;
        }

        &--theme-celadontint {
            background-color: $colors__celadontint;
            color: $colors__blue;
        }

        &--theme-saffrontint {
            background-color: $colors__saffrontint;
            color: $colors__blue;
        }

        @include media('>=tablet-portrait') {
            display: flex;
            align-items: center;
            width: 50%;
            min-width: 545px;
            min-height: 225px;
            padding: 40px 40px;
            padding-left: calc((100vw - 1100px)/2);
        }

        &--theme-navy {
            .call-to-action {
                --call-to-action__color: #{$colors__white};
                --call-to-action__border-color: #{$colors__white};
                --call-to-action__hover-bg-color: #{$colors__white};
                --call-to-action__hover-color: #{$colors__blue};
            }
        }
    }

    &__content-inner {
        text-align: center;
        padding-left: 0;

        @include media('>=tablet-portrait') {
            padding-left: 1em;
            text-align: left;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-size: 32px;
        color: inherit;

        @include media('>=tablet-portrait') {
            font-size: 42px;
        }

        @include media('>=tablet-landscape') {
            font-size: 52px;
        }
    }

    &__description {
        @include fonts__sans-serif-light;
        font-size: 18px;
        line-height: 1.2;
        color: inherit;

        p {
            @include fonts__sans-serif-light;
            font-size: 24px;
            line-height: 1.2;
            color: inherit;
        }

        @include media('>=tablet-portrait') {
            font-size: 24px;
        }
    }
}
