.insight-card {
    --insight-card__link-font-size: 24px;

    &__img {
        padding-bottom: 57%;
        background-position: 50% 50%;
        background-size: cover;
        margin-bottom: 16px;
        background-color: $colors__gray--c5;
    }

    &__info-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;

        &--main {
            display: flex;
            flex-wrap: wrap;

            &:not(:last-child) {
                width: 90%
            }
        }
    }

    &__info {
        @include fonts__sans-serif-light;
        font-size: 16px;
        line-height: 1.1875;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        color: $colors__gray--33;
        display: block;

        &:not(:last-child) {
            padding-right: 1em;

            &:after {
                content: '|';
                position: absolute;
                margin-left: 0.25em;
                display: inline-block;
            }
        }
    }

    &__bookmarked {
        margin-right: 16px;
        margin-left: auto;

        &:after {
            content: "\f02e";
            font-family: FontAwesome;
            font-size: 1.03em;
            color: $colors__teal--essence;
        }
    }

    &__link {
        @include fonts__serif-regular;
        font-size: var(--insight-card__link-font-size);
        line-height: 1.143;
        color: $colors__blue;
        display: block;
    }

    @include media('>=tablet-portrait') {
        --insight-card__link-font-size: 28px;
    }

    a {
        position: relative;
        display: block;
    }

    a:hover {
        .insight-card__flag {
            text-decoration: none;
        }
    }

    &__flag {
        padding: 10px 15px 10px;
        background-color: $colors__teal;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        height: 38px;

        span {
            display: block;
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            @include fonts__sans-serif-bold;
            color: $colors__blue;
            margin-left: 6px;
            letter-spacing: .11em;
        }
    }

    &__flag-icon,
    &__flag-icon-inline {
        max-height: 90%;
        vertical-align: middle;
    }

    &__flag--right {
        padding: 7px;
        background-color: $colors__teal;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 36px;
        height: 43px;

        img {
            display: block;
        }
    }

    &__flag--bottom-left {
        background-color: $colors__teal;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 64px;
        height: 37px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .insight-card__flag-icon {
            max-height: 70%;
            max-width: 55%;
            display: block;
        }
    }

    &__flat-icon-cpe {
        display: block;
    }
}
