.two-column-layout {
    --two-column-layout__container-padding-top: 16px;
    background-color: $colors__gray--f7;
    padding-bottom: 48px;

    &__container {
        padding-top: var(--two-column-layout__container-padding-top);
        flex-wrap: wrap;
    }

    &__aside {
        .highlight {
            img {
                height: auto !important; // override legacy component styles
            }
        }
    }

    &__header {
        width: 100%;
    }

    &__main {
        .rte {
            .rte__blue-box {
                padding: rem(20px);
                background-color: $colors__blue--light;

                ul {
                    padding-left: 2em;

                    li {
                        @include fonts__sans-serif-medium;
                    }
                }
            }

            ul {
                padding-left: 2em;

                li {
                    @include fonts__sans-serif-light;
                }
            }
        }
    }

    @include media('<tablet-portrait') {
        &__aside {
            padding-bottom: 16px;
            margin-bottom: 32px;
            border-bottom: 1px solid $colors__gray--d8;

            &.right {
                border-bottom: 0;
                margin-bottom: 0;
                margin-top: 32px;
                padding-top: 16px;
            }
        }
    }

    @include media('>=tablet-portrait') {
        --two-column-layout__container-padding-top: 32px;

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__aside {
            width: 35%;
        }

        &__main {
            width: 62%;
        }
    }


    @include media('>=tablet-landscape') {
        --two-column-layout__container-padding-top: 40px;
        padding-bottom: 64px;

        &__aside {
            width: 25%;
        }

        &__main {
            width: 73%;
        }
    }
}
