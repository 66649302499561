.section-headline {
    --section-headline__font-size: 32px;

    @include fonts__serif-regular;
    line-height: 1.1;
    font-size: var(--section-headline__font-size);
    color: $colors__blue;

    font-weight: initial; //override legacy css
    letter-spacing: 0; //override legacy css
    margin-bottom: 0; //override legacy css

    &--spaced {
        margin-bottom: 24px;   
    }

    @include media('>=tablet-landscape') {
        --section-headline__font-size: 36px;

        &--spaced {
            margin-bottom: 24px;   
        }
    }
}

.section-headline-2 {
    --section-headline2__font-size: 18px;

    @include fonts__sans-serif-bold;
    line-height: 1.1;
    font-size: var(--section-headline2__font-size);
    color: $colors__blue;
    text-transform: uppercase;
    letter-spacing: 0; //override legacy css
    margin-bottom: 0; //override legacy css

    @include media('>=tablet-landscape') {
        --section-headline2__font-size:22px;
    }
}