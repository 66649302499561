/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    .modal-content.sm {
        width: 45%;
    }

    .modal-content.med {
        width: 65%;
    }

    .modal-content.lrg {
        width: 85%;
    }

    .modal-content {
        position: relative;
        background-color: $color-white;
        margin: 15% auto;
        padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        animation-name: animatetop;
        animation-duration: 0.4s;

        .close {
            color: $color-nobel-grey;
            float: right;
            font-size: 28px;
            font-weight: bold;
            margin-top: -1em;
            margin-right: -10px;

            &:hover {
                color: $color-blue-prusian-blue;
                text-decoration: none;
                cursor: pointer;
            }

            &:focus {
                color: $color-blue-prusian-blue;
                text-decoration: none;
                cursor: pointer;
            }
        }
        /* Add Animation */
        @-webkit-keyframes animatetop {
            from {
                top: -300px;
                opacity: 0
            }

            to {
                top: 0;
                opacity: 1
            }
        }

        @keyframes animatetop {
            from {
                top: -300px;
                opacity: 0
            }

            to {
                top: 0;
                opacity: 1
            }
        }
    }
}

/*NEW MODAL*/

.modalDialog {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    z-index: 99999;
    opacity: 0;
    transition: opacity 400ms ease-in;
    pointer-events: none;
}

.modalDialog:target {
    opacity: 1;
    pointer-events: auto;
}

.modalDialog > div {
    position: relative;
    margin: 15% auto;
    padding: 20px;
    border-radius: 5px;
    background: $color-white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
}

.modalDialog.sm > div {
    width: 45%;
}

.modalDialog.med > div {
    width: 65%;
}

.modalDialog.lrg > div {
    width: 85%;
}

.close {
    font-size: 1.25em;
    color: $color-nobel-grey;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: $color-blue-prusian-blue;
    }
}

.activemodal {
    display: block;
}

@include bp-max(550px) {
    .modal-content.sm, .modal-content.med, .modal-content.lrg {
        width: 90%;
    }

    .modalDialog.sm > div, .modalDialog.med > div, .modalDialog.lrg > div {
        width: 90%;
    }
}
