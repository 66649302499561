.event-landing {
    .event-switch {
        display: flex;
        align-items: center;
        
        &__container {
            position: relative;
            display: inline-block;
            width: 35px;
            height: 14px;
        }

        &__input {
            opacity: 0;
            width: 0;
            height: 0;

            &:focus {
                + .event-switch__btn:before {
                    outline: thin dashed;        
                }
            }

            &:checked + .event-switch__btn {
                background-color: $colors__blue;;
            }

            &:checked + .event-switch__btn:before {
                transform: translateX(12px);
            }
        }

        &__btn {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $colors__gray--ac;
            transition: .4s;
            border-radius: 34px;
            
            &::before {
                position: absolute;
                content: "";
                height: 24px;
                width: 24px;
                left: 0px;
                top: calc(50% - 12px);
                border: 1px solid $colors__blue;
                background-color: $colors__white;
                transition: .4s;
                border-radius: 50%;
            }
        }

        &__label {
            @include fonts__sans-serif-medium;
            margin-left: .5em;
        }
    }
}