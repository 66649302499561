.personalization-bar {
    $spacing: 1em;
    padding: 1em;
    margin-bottom: 1em;
    color: $color-brown-spice;
    background-color: $color-gray-white;
    display: block;

    .filter-label {
        font-family: $font-family-primary-medium;
        font-size: 1em;
        line-height: 1;
    }

    label:after {
        content: "";
    }

    .filter-container {
        @include container;

        .input {
            @include span(1);
        }

        .label {
            @include span(11 last);
            margin-top: -1px;
        }
    }

    .filters {
        .filter-label,
        .subfilter-label {
            cursor: pointer;
            padding-left: 4px;

            &:after {
                font-size: .8em;
                @include font-ionicons($ionicon-var-plus-round);
                float: right;
                margin-right: 6px;
            }
        }

        .subfilter-label {
            &:after {
                font-size: .6em;
                margin-right: 2px;
            }
        }

        select {
            font-family: $font-family-primary-medium;
            border: none;
            background: $color-gray-white;
            /*font-weight:bold;*/
            option {
                @include font-primary-light;
            }
        }
    }

    .keyword-field {
        margin-top: $spacing;
        display: table;
    }

    .keyword-input-container {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
    }

    .keyword-input {
        font-size: px-to-em(14px);
        padding: 1em .5em;
        width: 100%;
        height: 45px;
    }

    .btn-container {
        display: table-cell;
        vertical-align: middle;
        color: $color-white;
        transform: rotateY(-180deg);

        .btn-go {
            padding: .75em !important;
        }
    }

    .filters {
        margin-top: $spacing;
        margin-bottom: $spacing;

        &.show-filters {
            .filter-label {
                &:after {
                    @include font-ionicons($ionicon-var-minus-round);
                }
            }
        }
    }

    .filters.select {
        margin-top: -.25em;
        margin-bottom: -2px;
    }
    /*USE IF THE SELECT BOX IS FIRST TO APPEAR IN THE PERSONALIZATION MENU*/
    .subfilter {
        margin-top: .5em;

        .subfilter-selects {
            display: none;

            @include bp-max($bp-desktop) {
                padding: .5em;
                font-size: 14px;
            }
        }

        &.show-subfilters {
            .subfilter-label {
                &:after {
                    @include font-ionicons($ionicon-var-minus-round);
                }
            }
        }
    }

    .filter-selects {
        margin-top: $spacing/2;
        display: none;
        font-size: .9em;
        padding-left: .25em;

        li {
            list-style: none;

            @include bp-max($bp-desktop) {
                padding: .5em;
                padding-left: 0em;
                margin-left: 10px;
            }
        }
    }

    .sidebar-preferences-link {
        display: block;
        width: 100%;
        border-top: 1px solid $color-gray-light-grey;
        padding-top: .25em;
        margin-top: 1em;
        font-size: .9em;
    }

    .sidebar-preferences-clear {
        font-size: .9em;
    }

    .personalization-cta {
        background: $color-white;


        .content {
            display: table;
            padding: 8px;

            .copy {
                display: table-cell;
                max-width: 100%;
                min-width: 75%;
                vertical-align: top;
            }

            .icon {
                display: table-cell;
                width: 20%;
                vertical-align: top;
                color: $color-blue-air-force-blue;
                font-size: 1.75em;
                padding-left: 5px;
            }

            div.fa {
            }

            p {
                font-size: 12px;
                margin: 0px;
            }

            a {
                text-decoration: none;
            }

            i.tool-tip-icon {
                display: none !important;
            }

            em.tool-tip-icon {
                display: none !important;
            }
        }

        #personalization-message {
            display: none;
        }
        /*HIDES EXTRA MESSAGE NOT IN POPOUT*/
        .aws-tooltip p {
            font-size: 12px !important;
        }

        .personalization-cta-button {
            display: inline-block;
            width: 1.875em;
            height: 1.875em;
            border-radius: 1.875em;
            background-color: $color-blue-air-force-blue;
            position: absolute;
            z-index: 1;
            top: 50%;
            z-index: 1;
            transform: translate(0,-50%);
            transition: background-color 0.3s;
            left: 80%;

            &:before {
                @include font-fontawesome($fa-var-question);
                top: 0%;
                left: 28%;
                position: absolute;
                color: $color-white;
                font-size: 1.25em;
            }

            .popout {
                &.normalpopout {
                    display: none;
                }

                &.showpopout {
                    display: inline-block;
                    position: relative;
                    left: 130%;
                    z-index: 1;
                    background: white;
                    vertical-align: top;
                    width: 15em;
                    box-shadow: 2px 2px 10px #000;

                    .popout-content {
                        font-size: 12px;
                        padding: 10px;
                        display: inline-block;
                        width: 80%;
                    }

                    .cta-popout-close {
                        background-color: $color-white !important;
                        color: $color-brown-sand-dune !important;
                        float: right;
                        margin-top: 4px;
                        display: inline-block;
                        padding: 0.313em 0.75em;

                        &:before {
                            @include font-ionicons($ionicon-var-close-round);
                            float: right;
                        }

                        &:after {
                            width: 0px !important;
                            display: none !important;
                        }
                    }
                }
            }

            &:hover {
                .normalpopout {
                    display: inline-block;
                    position: relative;
                    left: 130%;
                    z-index: 1;
                    background: white;
                    vertical-align: top;
                    width: 15em;
                    box-shadow: 2px 2px 10px #000;

                    .popout-content {
                        font-size: 12px;
                        padding: 10px;
                        display: inline-block;
                        width: 80%
                    }

                    .cta-popout-close {
                        background-color: $color-white !important;
                        color: $color-brown-sand-dune !important;
                        float: right;
                        margin-top: 4px;
                        display: inline-block;
                        padding: 0.313em 0.75em;

                        &:before {
                            @include font-ionicons($ionicon-var-close-round);
                            float: right;
                        }

                        &:after {
                            /*content: " ";
                        position: absolute;
                        right: 100%;
                        border-width: 15px;
                        border-style: solid;
                        border-color:  transparent rgba(255, 255, 255, 1) transparent transparent ;*/
                            width: 0px;
                            display: none !important;
                        }
                    }

                    &:after {
                        /*content: " ";
	                    position: absolute;
	                    right: 100%;
	                    border-width: 15px;
	                    border-style: solid;
	                    border-color:  transparent rgba(255, 255, 255, 1) transparent transparent ;*/
                        width: 0px;
                        display: none !important;
                    }
                }
            }
        }
    }

    @include bp-max($bp-tablet) {
        display: none;

        .active {
            display: block;
        }
    }

    @include bp-min($bp-desktop) {
        display: block !important;
    }
}

@include bp-max(990px) {
    .personalization-bar {
        width: 100%;
        margin-left: 0px !important;
    }
}

@include bp-max($bp-mobile) {
    .personalization-cta-button {
        display: none;
    }
}
