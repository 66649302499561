.landing-page-media-block {

  .width-container {
    display: flex;

    &.vertical-align-top {
      align-items: flex-start;
    }

    &.vertical-align-center {
      align-items: center;
    }

    &.vertical-align-bottom {
      align-items: flex-end;
    }

    &.text-align-center {

      .featured-media__content {
        text-align: center;
      }
    }

    &.text-align-left {

      .featured-media__content {
        text-align: left;
      }
    }

    &.text-align-right {

      .featured-media__content {
        text-align: right;
      }
    }

    &.text-align-justify {

      .featured-media__content {
        text-align: justify;
      }
    }
  }

  .section-headline {
    color: $colors__blue;
    font-size: 52px;
  }
}

@include media('<=tablet-portrait') {

  .landing-page-media-block {
    padding: 14px;
    margin-bottom: 62px;

    .width-container {
      flex-direction: column;
    }

    .featured-media__img {
      min-width: calc(100vw - 28px); // 14px of padding on each side
    }

    .featured-media__content {
      padding-top: 28px;

      .section-body {
        margin-top: 14px;
        font-size: 18px;
      }
    }

    .section-headline {
      font-size: 42px;
    }
  }
}
