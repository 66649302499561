.small-social-bar {
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
    margin-left: 0px !important;
    border-top: 1px solid $color-gray-light-grey;
    border-bottom: 1px solid $color-gray-light-grey;
    padding-top: 1px;

    li {
        display: table-cell;
        vertical-align: middle;
        width: 20%;
        font-size: 1.50em;
        /*float:left;*/
        list-style: none;
        margin-bottom: 1px;

        a {
            display: block;
        }
    }

    .linkedin, .facebook, .twitter, .email, .print {
        text-align: center;
    }

    .linkedin {
        background-color: $color-white;

        &:hover {
            background-color: $color-blue-cerulean;

            a {
                color: $color-blue-cerulean;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    .facebook {
        background-color: $color-white;

        &:hover {
            background-color: $color-blue-blue;

            a {
                color: $color-blue-blue;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    .twitter {
        background-color: $color-white;

        &:hover {
            background-color: $color-blue-shakespear;

            a {
                color: $color-blue-shakespear;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    .email {
        background-color: $color-white;

        &:hover {
            background-color: $color-green-olive;

            a {
                color: $color-green-olive;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    .print {
        background-color: $color-white;

        &:hover {
            background-color: $color-orange-rich-gold;

            a {
                color: $color-orange-rich-gold;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }
}

@include bp-max(99999999px) {
    .small-social-bar {
        display: none;
    }
}

@include bp-max($bp-desktop) {
    .small-social-bar {
        display: table;
    }
}
