.careers-nav {
    $nav-link-font-size: 10px;
    background-color: $color-white;
    box-shadow: 0 3px 15px 0 rgba(0,0,0,.2);
    position: relative;
    z-index: 1; //position this over careers hero    

    .btn-mobile-menu {
        // color: $color-white;
        color: $color-blue-darkest;
        text-transform: uppercase;
        @include font-primary-bold(15px);
        display: none;
        /*padding: 0.85em 1em;*/
        background-color: $color-white;
        border: none;
        box-shadow: none;
        padding: 1.2em;
        @include bp-min($bp-desktop) {
            padding: 0;
        }

        &:after {
            // background-color: $color-blue-darkest;
            @include font-ionicons($ionicon-var-navicon, 35px);
            line-height: 0;
            position: relative;
            top: 6px;
            margin-left: .5em;
        }
    }

    .nav-links {
        @include clearfix;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: $color-lighter-grey;

        @include bp-min($bp-desktop) {
            flex-wrap: nowrap;
            background-color: $color-white;
        }
    }

    .nav-link-container {
        float: left;
        @include font-primary(16px);
        // border-left: 1px solid $color-blue-darkest;
        // border-right: 1px solid $color-blue-darkest;
        transition: border-bottom $transition-timing-primary;
        list-style: none;
        margin-bottom: 0px !important;
        border-bottom: 4px solid $color-white;
        flex: 1 1 100%;

        @include bp-min($bp-desktop) {
            flex: 1 0 auto;
            @include font-primary-bold($nav-link-font-size);
        }

        &:hover, &.active {
            // background-color: $color-blue-darkest;
            border-bottom: 1px solid $color-grey-light-grey-hq;
      
            @include bp-min($bp-desktop) {
                border-bottom: 4px solid $color-blue-riptide;
            }
        }

        &:last-of-type {
            position: relative;

        //     &:after {
        //         content: '';
        //         position: absolute;
        //         top: 0;
        //         left: calc(100% + 1px);
        //         width: 1px;
        //         height: 100%;
        //         background-color: $color-blue-darkest;
        //     }
        }

        &:first-of-type {
            position: relative;

            // &:after {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     right: calc(100% + 1px);
            //     width: 1px;
            //     height: 100%;
            //     background-color: $color-blue-darkest;
            // }
        }
    }

    .nav-link {
        color: $color-blue-darkest;
        display: block;
        padding: 10px 1em;
        
        @include bp-min($bp-desktop) {            
            padding: 10px 1em;
        }

        &:hover {
            text-decoration: none;
        }
    }

    @include bp-max($bp-desktop) {

        .page-wrapper {
            padding: 0;
        }

        .btn-mobile-menu {
            display: block;
            margin-bottom: 0px;
            text-align: left;
        }

        .nav-links {
            display: none;
        }

        &.show-nav-links {
            .nav-links {
                display: block;
            }

            .btn-mobile-menu {
                &:after {
                    @include font-ionicons($ionicon-var-close-round, 20px);
                    top: 0;
                    left: 10px;
                }
            }
        }

        .nav-link-container {
            float: none;
            border: none;
            // border-top: 1px solid $color-grey-light-grey-hq;
            border-bottom: 1px solid $color-grey-light-grey-hq;
            margin-bottom: 0px !important;

            &:first-of-type {
                &:after {
                    width: 100%;
                    height: 1px;
                    top: -2px;
                    left: 0;
                }
            }

            &:last-of-type {
                &:after {
                    content: none;
                }
            }
        }
    }
}

@media print {
    .nav-links {
        display: none !important;
        width: 0px !important;
        height: 0px !important;
    }
}
