.preferences {
    .search-input-container {
        /*border: 1px solid $color-brown-tide;*/
        height: 34px;
        width: 100%;
        margin-bottom: 1em;
        background-color: $color-white;
        float: left;

        &:not(.width-50) {
            @include bp-min($bp-tablet) {
            }
        }

        &.width-50 {
            width: 49%;

            &.odd {
                margin-right: 1%;
            }

            &.even {
                margin-left: 1%;
            }

            @include bp-max($bp-tablet) {
                width: 100%;

                &.odd {
                    margin-right: 0;
                }

                &.even {
                    margin-left: 0;
                }
            }
        }
    }

    input[type=checkbox] {
        margin-right: 5px;
        margin-left: 2px;
        width: auto;
    }

    .checkbox-label {
        &::after {
            content: " " !important;
        }
    }

    .has-error .list-group-item-danger {
        /*margin-left: 0;*/
        color: $color-red-fire-engine-red;
    }
}
